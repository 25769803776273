import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../components/container";
import Breadcrumb from "../../components/BreadCrumb";
import LazyImage from "../../components/LazyImage";
import Button from "../../components/EmptyButton";
import { useHistory } from "react-router-dom";
import { getApiKey, slugToText } from "../../helpers";

import Illustration from "../../assets/images/Illustration.webp";
import { createErrorAlert, getAllQuiz } from "../../redux/actions";
import { quizList, quizLoading } from "../../redux/selectors";
import { Helmet } from "react-helmet";

const QuizList = ({ match, quizData, loading, getAllQuiz }) => {
  const slug = match.params.slug1;
  const history = useHistory();
  const dispatch = useDispatch()
  const [list, setList] = useState([]);
  const breadCrumbItem = [
    { title: "Get study modules", link: "/" },
    {
      title: slugToText(slug),
      link: `/study-module/${slug}`,
    },
  ];
  const loggedinUser = JSON.parse(sessionStorage.getItem("app_user"));
  useEffect(() => {
    getAllQuiz(slug);
  }, []);
  useEffect(() => {
    if (quizData) {
      setList(quizData);
    }
  }, [quizData]);
  return (
    <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat" id="quizList">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Simple Studying | Quiz | {slugToText(slug)}</title>
        <meta name="description" content="Get High Grades with the support of top graduates who took the same courses as you" />
        <link rel="canonical" href="http://localhost:3000/profile" />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_gb" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Simple Studying | Profile" />
        <meta property="og:description" content="Get High Grades with the support of top graduates who took the same courses as you" />
        <meta property="og:url" content="http://localhost:3000/profile" />
        <meta property="og:site_name" content="Simple Studying" />
      </Helmet>
      <Container>
        {getApiKey() ? (
          <div className="py-4 md:py-8 lg:py-14">
            <Breadcrumb items={breadCrumbItem} current={"Quiz"} />
          </div>
        ) : null}
        <p className="text-36 font-bold quizHeadText">Quiz</p>
        <div className="flex flex-col md:flex-row pt-4 md:pt-10">
          <div className="w-full md:w-1/2">
            {list?.length ? (
              list?.map((elem, index) => {
                return (
                  <div key={index} className="flex items-center pt-4 md:pt-7">
                    <p className="mr-2.5 text-body-large">
                      {index + 1 < 10 ? `0${index + 1}` : index + 1}.
                    </p>
                    <p
                      className="text-primary text-body-large underline cursor-pointer"
                      onClick={() => {
                        if((loggedinUser.subscription_name.includes('STARTER') || loggedinUser.subscription_name === "Basic Subscription") && loggedinUser.free_trial === false){
                          dispatch(
                            createErrorAlert(
                              800,
                              "You discovered a Premium feature!",
                              "Upgrading to StudyCrowd.AI Premium will unlock it. With Premium, you can maximise your chances of getting a First Class law degree.",
                              800
                            )
                          );
                        } else{
                          history.push({
                            pathname: `/quiz/${elem.slug}`,
                            state: { state: elem.slug, value: slug },
                          });
                        }
                      }}
                    >
                      {elem.title}
                    </p>
                  </div>
                );
              })
            ) : (
              <h2 style={{ textAlign: "center" }}>Result Not Found</h2>
            )}
          </div>
          <div className="quizIndexImgSec pt-10 md:pt-0 w-full md:w-1/2 flex justify-center">
            <div>
              <LazyImage src={Illustration} />
            </div>
          </div>
        </div>
        {!getApiKey() ||
        loggedinUser?.subscription_name === "Basic Subscription" && loggedinUser?.user_type !== 'TEACHER' ? (
          <div className="pt-12 md:pt-18 lg:pt-24 pb-12 md:pb-18  lg:pb-24">
            <div className="flex flex-col items-center md:flex-row custom:justify-center md:px-20">
              {/* <div className="w-10/12 custom:w-1/3 xl:w-96">
                {/* <Button
                  filled
                  onClick={() => {
                    history.push("/subscription");
                  }}
                >
                  <p className="text-center text-14 xl:text-body-large">
                    Get All {slug}
                    <br /> Materials FOR ONLY £20
                  </p>
                </Button> */}
              {/* </div> */}
              {/* <div>
                <p className="my-8 font-bold text-17 md:mx-5 xl:mx-7">OR</p>
              </div> */}

              <div className="w-10/12 custom:w-1/3 xl:w-96">
                <Button
                  filled
                  onClick={() => {
                    history.push("/subscription");
                  }}
                >
                  <p className="text-center text-14 xl:text-body-large">
                    Activate your Free Trial now
                  </p>
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </Container>
    </div>
  );
};
const mapStateToProps = (state) => ({
  quizData: quizList(state),
  loading: quizLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAllQuiz: (slug, page) => dispatch(getAllQuiz(slug, page)),
});
export default connect(mapStateToProps, mapDispatchToProps)(QuizList);
