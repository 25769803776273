import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux';
import Input from '../Input/InputForm'
import Button from '../EmptyButton'
import {tutorSessionPost} from "../../redux/actions";

const StatusBadge = ({status}) => {


    return (
        <div
            className={`py-1 px-2.5 text-white font-normal text-small-text md:text-14 ${status === 'false' ? 'bg-my' : 'bg-offGreen'}`}
            style={{borderRadius: '43px'}}>
            {status === 'false' ? 'Canceled' : 'Open'}
        </div>
    )
}

const SessionScheduleContainer = ({
                                      status,
                                      open,
                                      openSchedule,
                                      closeSchedule,
                                      numOfPersons,
                                      enlargePersons,
                                      reducePersons,
                                      book,
                                      date,
                                      getTutorSession,
                                      data, notifyTutor

                                  }) => {
    let dateMonth = new Date(data.date)
    const month = dateMonth.toLocaleString('default', {month: 'short'});
    let day = dateMonth.getDate()

    let user = JSON.parse(sessionStorage.getItem('app_user'))

    const [firstName, setFirstName] = useState(user?.first_name)
    const [lastName, setLastName] = useState(user?.last_name)
    const [email, setEmail] = useState(user?.email)
    const [phone, setPhone] = useState(user?.contact_number)
    return (
        <div>
            <div className='p-4 md:p-10 bg-white'
                 style={{borderRadius: '14px', boxShadow: ' 4px 8px 20px rgba(2, 23, 54, 0.03)'}}>
                <div className='flex justify-between flex-col md:flex-row'>
                    <div className='flex'>
                        <div className='text-center mr-5 md:mr-14 flex flex-col justify-center'>
                            <p className='text-primary text-14 md:text-body-large font-normal'>{month}</p>
                            <p className='font-bold text-darkest text-body-large md:text-h3'>{day}</p>
                        </div>
                        <div>
                            <p className='text-13 md:text-h5 font-bold text-darkest cursor-pointer'
                               onClick={openSchedule}>{data?.title}</p>
                            <p className='text-11 md:text-14 text-darkest mt-3.5'>Capacity: <span
                                className='text-darkGrey'>{data?.min_capacity}</span> / {data?.max_capacity}</p>
                            <p className='text-11 md:text-14 text-darkGrey mt-2'>
                                {(new Date("1970-01-01 " + data?.start_time).toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }))} - 
                                {(new Date("1970-01-01 " + data?.end_time).toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }))}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>
                                <p className='text-13 md:text-16 text-right text-primary font-bold mb-9'>FREE</p>
                            </div>
                            <div className='md:w-56 w-56 m-auto'>

                                    <Button filled onClick={() => notifyTutor(data.slug, firstName, lastName, email, phone)}>
                                        <p className='text-15 mac:text-body-large '>SIGN UP FOR THE CLASS</p>
                                    </Button>
                                </div>
                            {/* <StatusBadge status={status}/> */}
                        </div>
                    </div>
                </div>
                {/* {open && (
                    <div className='mt-10'> */}
                        {/* <div>
                            <form className="md:grid grid-cols-2 gap-x-6 gap-y-9">
                                <div className="">
                                    <label className='mb-8 md:text-body-large'>
                                        First name
                                    </label>
                                    <Input placeholder="" value={firstName} setValue={setFirstName}/>
                                </div>
                                <div className=''>
                                    <label className='mb-8 md:text-body-large'>
                                        Last name
                                    </label>
                                    <Input placeholder="" value={lastName} setValue={setLastName}/>
                                </div>
                                <div className="">
                                    <label className='mb-8 md:text-body-large'>
                                        Email
                                    </label>
                                    <Input placeholder="" value={email} setValue={setEmail}/>
                                </div>
                                <div className=''>
                                    <label className='mb-8 md:text-body-large'>
                                        Phone
                                    </label>
                                    <Input placeholder="" value={phone} setValue={setPhone}/>
                                </div>
                            </form>
                        </div> */}
                        {/*<div className='mt-9 flex flex-col md:flex-row items-center md:space-x-7'>*/}
                        {/*    <p className='md:text-body-large text-darkest'>Total number of persons:</p>*/}
                        {/*    <div className='flex items-center space-x-6 mt-3 md:mt-0'>*/}
                        {/*        <button className='cursor-pointer text-body-large text-primary font-bold'*/}
                        {/*                onClick={reducePersons}>-*/}
                        {/*        </button>*/}
                        {/*        <p className='cursor-pointer text-body-large text-darkest'>{numOfPersons}</p>*/}
                        {/*        <button className='cursor-pointer text-body-large text-primary font-bold'*/}
                        {/*                onClick={enlargePersons}>+*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/* <div className='mt-6 flex flex-col md:flex-row items-center'> */}
                            {/* <div className='py-6 md:pl-16' onClick={closeSchedule}>
                                    <span
                                        className='font-bold text-body-large text-primary cursor-pointer'>Cancel</span>
                            </div> */}
                            {/* <div
                                className='w-full flex flex-col space-y-5 md:flex-row md:items-center md:space-y-0 md:space-x-5 md:justify-end'> */}
                                {/* <div>
                                    <Button onClick={book}>
                                        <p className='text-bold text-darkest text-15 px-2 mac:text-body-large mac:px-6'>Book
                                            one to one tutorials</p>
                                    </Button>
                                </div> */}
                                
                            {/* </div>
                        </div>
                    </div>)} */}
            </div>
        </div>
    )
}

export default SessionScheduleContainer
