import React, {useEffect, useState, useRef} from "react";
import LoadingSpinner from "../Loader/LoadingSpinner";
import PropTypes from "prop-types";
import { isNull } from "lodash";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const EmptyButton = ({
  text,
  filled,
  children,
  onClick,
  className,
  disabled,
  simple,
  loading,
  fontSize,
}) => {
  const [percentage, setPercentage] = useState(0)
  const [start, setStart] = useState(true);
  const timerIdRef = useRef(null);
  let values = [0,20,40,60,80,100]
  const style = {
    buttonStyle: {
      border: !filled
        ? "1px solid #8E8E8E"
        : disabled
        ? "1px solid #FFC39D"
        : simple
        ? "1px solid #8E8E8E"
        : "1px solid #cc6828",
      borderRadius: "14px",
      textAlign:"center",
      color: text === "Book now" && disabled && "#fff",
      background: text === "Book now" && disabled && "rgb(255, 195, 157)",
      fontSize: text === "Claim free tutoring" ? fontSize : text==="Trial Expired, Upgrade"? "15px":text==="Free trial in progress"?"15px":null,
    },
  };

  useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem('app_user'))
    let date = new Date()
    if(start){
      timerIdRef.current = setTimeout(() => {
        setPercentage(percentage + 1);
      }, 1000);
    }
    if(percentage === 200){
      clearTimeout(timerIdRef.current)
      setPercentage(0)
    }
  }, [percentage, start])


  return (
    <div
      id={text ? text.replace(" ", "").toLowerCase() : "button"}
      onClick={!disabled ? onClick : undefined}
      className={`
                ${filled && !disabled && "buttonClass"}                
                w-full
                h-full 

                p-2 
                flex 
                items-center 
                ${!disabled && "cursor-pointer"}
                justify-center 
                text-body-large 
                font-bold 
                prevNextBtnBox
                font-s
                text-${filled ? "white" : simple ? "darkest" : "primary"} 
                subscription_btn-${filled ? "disable" : "primary"}
                hover:bg-${
                  filled
                    ? disabled || loading
                      ? "disabled"
                      : "white"
                    : "primary"
                }
                hover:text-${
                  filled ? (disabled || loading ? "white" : "primary") : "white"
                }  
                hover:border 
                hover:border-primary  
                ${!disabled && "focus:bg-primary"}    
                ${!disabled && "focus:text-white"}
                ${className}
                `}
      style={style.buttonStyle}
    >
      {text || children}
      {loading && <LoadingSpinner color="#CC6828" className="h-8 w-8" />}
      {/* {text === "Free trial in progress" ? (
        <span className="cirBarSpanMain" style={{ height: "30px", marginLeft: "20px", display: "flex" }}>
          <CircularProgressbar
            value={percentage}
            maxValue={200}
            text={`${percentage}%`}
            styles={buildStyles({
              pathColor: "#c8631e",
              textColor: "#c8631e",
              trailColor: "white",
              backgroundColor: "#3e98c7",
              pathTransitionDuration: 0.15
            })}
          />
        </span>
      ) : null} */}
    </div>
  );
};

EmptyButton.propTypes = {
  text: PropTypes.string,
};

export default EmptyButton;
