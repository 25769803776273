import React, { useEffect } from "react";
import { connect } from "react-redux";
import GoogleLogin from "react-google-login";
import { loginWithGoogle } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import { gapi } from "gapi-script";

const GOOGLE_CLIENT_ID =
  "985660993000-tbqq47rtmqt17002bsl6j2801if8s69j.apps.googleusercontent.com";

const GoogleAuthentication = (props) => {

  useEffect(() => {
    const initClient = () => {
          gapi?.client?.init({
          clientId: GOOGLE_CLIENT_ID,
          scope: ''
        });
     };
     gapi.load('client:auth2', initClient);
 });

  const { loginWithGoogle } = props;
  const history = useHistory();
  const responseGoogleSuccess = (response) => {
    loginWithGoogle(response.accessToken, history);
  };
  const responseGoogleFailed = (response) => {
    console.log("error", response);
  };

  // useEffect(() => {
  //   const initClient = () => {
  //     gapi.auth2.init({
  //       clientId: GOOGLE_CLIENT_ID,
  //       scope: "",
  //     });
  //   };
  //   gapi.load("client:auth2", initClient);
  // });

  return (
    <div className="pt-6 md:pt-12 flex justify-center">
      <div className="w-full h-51 googleAuthBox">
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          buttonText="Continue with Google"
          onSuccess={responseGoogleSuccess}
          onFailure={responseGoogleFailed}
          autoLoad={false}
          cookiePolicy={"single_host_origin"}
          className="socialBtn"
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  loginWithGoogle: (body, history) => dispatch(loginWithGoogle(body, history)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleAuthentication);
