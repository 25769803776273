import * as t from "../types";
import {
  GetUser,
  Login,
  SignUp,
  FacebookLogin,
  GoogleLogin,
} from "../../services";
import { getApiKey, removeKey, setKey, setUser } from "../../helpers";
import { createErrorAlert } from "./errorAlertAction";
import { getUserInfo } from "./userAction";

export const userSignUp = (body, history) => async (dispatch) => {
  dispatch({
    type: t.SIGNUP_REQUEST,
  });
  removeKey();
  return await SignUp(body)
    .then((data) => {
      dispatch({
        type: t.SIGNUP_SUCCESS,
        payload: data,
      });
      setKey(data.data.access_token);
      GetUser().then((res) => {
        if (getApiKey()) {
          if(window.location.pathname === '/signup'){
            window.location.replace("/signup/step-zero");          
          } else {
            window.location.reload()
          }
        }
      });
    })
    .catch((err) => {
      dispatch({
        type: t.SIGNUP_FAILED,
        payload: err.response,
      });
      let errorResponse
      for (const key in err.response.data) {
        if (err.response.data.hasOwnProperty(key) && Array.isArray(err.response.data[key]) && err.response.data[key].length > 0) {
          errorResponse = err.response.data[key][0]
          return errorResponse
        }
      }
      dispatch(
        createErrorAlert(
          400,
          "Error",
          errorResponse,          
          400
        )
      );
    });
};
export const userLogin = (body, history) => async (dispatch) => {
  dispatch({
    type: t.LOGIN_REQUEST,
  });
  removeKey();
  return await Login(body)
    .then((data) => {
      dispatch({
        type: t.LOGIN_SUCCESS,
        payload: data,
      });
      setKey(data.data.access_token);
      dispatch(getUserInfo());
      setUser(JSON.stringify(data.data.user));
      if (getApiKey()) {
        if(window.location.pathname === '/login'){
          if (
            (data.data.user?.degrees.length === 0 && data.data.user?.universities.length === 0) ||
            (data.data.user?.degrees === null && data.data.user?.universities === null) && !window.location.pathname === "/signup/step-zero"
          ) {
            history.push('/signup/step-two');
          } else if ((data.data.user?.degrees.length === 0 || data.data.user?.degrees === null) && data.data.user?.universities.length !== 0) {
            history.push('/signup/step-three');
          } else{
            history.push("/profile");
            window.location.replace("/profile");
          }
        } else {
          if(window.location.pathname.includes('study-module')){
            return 'module'
          } else{
            window.location.reload()
          }
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: t.LOGIN_FAILED,
        payload: err.response,
      });
      if (err.response.data.non_field_errors) {
        dispatch(
          createErrorAlert(
            400,
            "Error",
            `${err.response.data.non_field_errors}`,
            400
          )
        );
      } else{
        let errorResponse
        for (const key in err.response.data) {
          if (err.response.data.hasOwnProperty(key) && Array.isArray(err.response.data[key]) && err.response.data[key].length > 0) {
            errorResponse = err.response.data[key][0]
            return errorResponse
          }
        }
      }
    });
};
export const loginWithGoogle = (body, history) => async (dispatch) => {
  dispatch({
    type: t.LOGIN_GOOGLE_REQUEST,
  });
  removeKey();
  return await GoogleLogin(body)
    .then((data) => {
      dispatch({
        type: t.LOGIN_GOOGLE_SUCCESS,
        payload: data,
      });
      setKey(data.access_token);
      dispatch(getUserInfo());
      if (getApiKey()) {
        if(window.location.pathname === '/login' || window.location.pathname === '/signup'){
          if (
            (data.user?.degrees.length === 0 && data.user?.universities.length === 0)
          ) {
            history.push('/signup/step-zero');
          } else if ((data.user?.degrees.length === 0 || data.user?.degrees === null) && data.user?.universities.length !== 0) {
            history.push('/signup/step-three');
          } else{
            history.push("/profile");
            window.location.replace("/profile");
          }
        } else {
          if(window.location.pathname.includes('study-module')){
            return 'module'
          } else{
            window.location.reload()
          }
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: t.LOGIN_GOOGLE_FAILED,
        payload: err.response,
      });
      dispatch(createErrorAlert(400, "Error", "Invalid credentials", 400));
    });
};

export const loginWithFacebook = (body, history) => async (dispatch) => {
  dispatch({
    type: t.LOGIN_FACEBOOK_REQUEST,
  });
  removeKey();
  return await FacebookLogin(body)
    .then((data) => {
      dispatch({
        type: t.LOGIN_FACEBOOK_SUCCESS,
        payload: data,
      });
      setKey(data.access_token);
      dispatch(getUserInfo());
      if (getApiKey()) {
        history.push("/profile");
        window.location.replace("/profile");
      }
    })
    .catch((err) => {
      dispatch({
        type: t.LOGIN_FACEBOOK_FAILED,
        payload: err.response,
      });
      dispatch(createErrorAlert(400, "Error", "Invalid credentials", 400));
    });
};