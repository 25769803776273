import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import "./modalError.css";
import Button from "../EmptyButton";
import information from "../../assets/icons/information.svg";
import { apiRequest, getApiKey, getUser } from "../../helpers";
import { useHistory } from "react-router-dom";
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import Select from "../../components/Select";
import { createErrorAlert } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { FaBell, FaCalendar, FaCreditCard } from "react-icons/fa";
import Context from "../../services/Context";

const ErrorHandler = ({
  isOpen,
  closeModal,
  simpleClose,
  title,
  content,
  status,
}) => {
  const dispatch = useDispatch()
  const [showDrop, setShowDrop] = useState(false);
  const [trialModal, setTrialModal] = useState(false)
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null)
  const [subscriptionTypes, setSubscriptionTypes] = useState([
    {
      label: "Monthly",
      price: "US $12",
      id: "monthly",
      name:""
    },
    {
      label: "Quarterly",
      price: "US $13",
      id: "quarterly",
      name: ""
    },
    {
      label: "Yearly",
      price: "US $14",
      id: "yearly",
      name:""
    },
  ]);



  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false)
  const user = JSON.parse(getUser())

  const today = new Date();

  today.setDate(today.getDate() + 3);

  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = today.toLocaleDateString("en-US", options);

  const { value, updateValue } = useContext(Context);
  const userCurrency = value?.userCurrency || user?.user_currency;
  const userIsLaw = value?.is_law || user?.is_law;
  const [calendars, setCalendars] = useState([
    {
      value: "google",
      label: "Google Calendar",
    },
    {
      value: "iCal",
      label: "iCalendar",
    },
    {
      value: "yahoo",
      label: "Yahoo",
    },
    {
      value: "outlook",
      label: "Outlook",
    },
  ]);
  const histories = useHistory();

  const customStyle = {
    modal: {
      borderRadius: '25px',
      width: '50%'
    },
  }

  const handleLogin = (e) => {
    // e.preventDefault();
    histories.push("/signup");
    closeModal();
  };

  const loginRoute = () => {
    histories.push("/login");
    closeModal();
  };

  const handleRadioChange = (price, item) => {
    setSelectedPrice(price);
    setSelectedSubscription(item)
  };

  const handleFreeTrialContinue = async () => {
    localStorage.setItem('freeTrialPopuped', true)
    await apiRequest({
      method: 'post',
      url: 'subscriptions/free_trail/',
      data: {
        name: selectedSubscription.name,
        success_url: window.location.origin + '/',
        cancel_url: window.location.origin + '/', 
      }
    }).then((res) => {
      window.location.href = res.data.sessionId
    }).catch((err) => {
      dispatch(createErrorAlert(400, '', 'Something went wrong', 400))
    })
  }

  const handleSubs = (e) => {
    e.preventDefault();
    histories.push("/subscription");
  };

  const handleCalendar = () => {
    setShowDrop(true);
  };

  useEffect(() => {
    getSubscriptionPrices()
  },[])

  const getSubscriptionPrices = async () => {
    try {
      const res = await apiRequest({
        url: "subscriptions/subscriptions/",
        method: "get",
      });

      const subscriptions = res.data;

      const quickBoostSubscription = subscriptions.find(
        (sub) => sub.slug === "quick-boost-of-your-grades"
      );
      const getCoveredSubscription = subscriptions.find(
        (sub) => sub.slug === "get-covered-for-the-exam-period"
      );
      const idealIfSubscription = subscriptions.find(
        (sub) => sub.slug === "ideal-if-you-aim-for-a-first-class"
      );

      setSubscriptionTypes((prevState) => {
        return prevState.map((plan) => {
          if (plan.id === "monthly" && quickBoostSubscription) {
            return {
              ...plan,
              price: userCurrency === 'US' ? `US $${quickBoostSubscription.membership_price}` : `UK £${quickBoostSubscription.membership_price}`,
              name: quickBoostSubscription.name
            };
          }
          if (plan.id === "quarterly" && getCoveredSubscription) {
            return {
              ...plan,
              price: userCurrency === 'US' ? `US $${getCoveredSubscription.membership_price}` : `UK £${getCoveredSubscription.membership_price}`,
              name: getCoveredSubscription.name
            };
          }
          if (plan.id === "yearly" && idealIfSubscription) {
            return {
              ...plan,
              price: userCurrency === 'US' ? `US $${idealIfSubscription.membership_price}` : `UK £${idealIfSubscription.membership_price}`,
              name: idealIfSubscription.name
            };
          }
          return plan;
        });
      });
    } catch (error) {
      console.error("Error fetching subscription prices", error);
    }
  };

  const handleSelectedModule = (item) => {
    let event = JSON.parse(localStorage.getItem("events"));

    for (let j = 0; j < event.length; j++) {
      if (item.value === "google") {
        let url = google(event[j]);
        window.open(url, "_blank");
      }
      if (item.value === "yahoo") {
        let url = yahoo(event[j]);
        window.open(url, "_blank");
      }
      if (item.value === "outlook") {
        let url = outlook(event[j]);
        window.open(url, "_blank");
      }
      if (item.value === "iCal") {
        let url = ics(event[j]);
        const link = document.createElement("a");
        link.href = url;
        link.download = title;
        link.click();
      }
    }
  };

  const handleMyTutorSessions = () => {
    if (window.location.href.includes("appointments_create")) {
      histories.push({ pathname: "/user-sessions", state: "handler" });
    } else {
      histories.push("/user-sessions");
    }
  };

  console.log(status, "sttausss")

  const addToBasket = async (item) => {
    let obj = {
      study_module: item
    }
    await apiRequest({
      method: 'post',
      url: "content/basket/",
      data: obj,
    }).then((res) => {
      if(res.status === 201){
        histories.push('/shopping-basket')
      }
    }).catch((err) => {
      dispatch(createErrorAlert(400, "", err.response.data[0], 400))
    })
  }

  const handleStatus800 = (value) => {
    if(value === 'unlock'){
      histories.push("/signup");
    } else if(value === 'basket'){
      addToBasket(localStorage.getItem('study-module_id'))
    } 
    else if(value === "not"){
      closeModal()
    } else if(value === "start"){
      setTrialModal(true)
    }
  }

  return (<div className="parent-modal-div">

 
    <Modal open={isOpen} onClose={closeModal} focusTrapped={false} center styles={customStyle}
    
    classNames={{
      overlay: 'custom-overlay-class',
     modal: 'react-modal-class2 errorModalMain', 
    }}>
      <div className="flex flex-row w-full items-center justify-center my-8 absolute -top-10 left-0 ">
        {status !== 204 ? (
          <img src={information} alt="" className="w-14 h-auto" />
        ) : null}
      </div>
      <p className="text-darkest text-large text-center text-h5 md:text-h4 custom2:text-h3 font-bold mb-5">
        {title !== "Error" ? title : null}
      </p>

      <div className="flex flex-row md:text-h5 flex-wrap w-full items-center justify-center">
        <p className="text-center">
          {status === 403 || status === 401 ? (
            <div>
              <p>Log in to proceed</p>
              <br />
              <Button
                filled
                onClick={() => {
                  loginRoute();
                }}
              >
                <p
                  className="text-center text-14 xl:text-body-large"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  OK
                </p>
              </Button>
            </div>
          ) : 
            status === 209 ? <div className="text-14" dangerouslySetInnerHTML={{__html: content}}></div> : <div className="text-14" dangerouslySetInnerHTML={{__html: content}}></div>
          }
        </p>
      </div>
      <div className="flex flex-row md:text-h5 flex-wrap w-full items-center justify-center">
        <p className="text-center mt-16">
          {status === 201 ? (
            <Button filled onClick={status === 201 ? closeModal : simpleClose}>
              <p
                className="text-center text-14 xl:text-body-large"
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
              >
                OK
              </p>
            </Button>
          ) : null}
        </p>
      </div>
      <div className="flex flex-col justify-center items-center w-full mt-4 md:mt-12">
        <div
          className="w-full mt-5 md:mt-0"
          style={status === 211 ? { width: "400px" } : null}
        >
          {status === 403 && status === 401 ? (
            <Button
              filled
              onClick={
                status === 403 || status === 401 ? closeModal : simpleClose
              }
            >
              <p className="text-center text-14 xl:text-body-large">OK</p>
            </Button>
          ) : null}
          {status === 200 && !getApiKey() ? (
            <Button
              filled
              onClick={(e) => (status === 200 ? handleLogin(e) : simpleClose)}
            >
              <p className="text-center text-14 xl:text-body-large">
                Sign Up Now
              </p>
            </Button>
          ) : status === 208 ? <div className="w-full flex justify-center items-center">
            <Button
              filled
              onClick={simpleClose}
            >
              <p className="text-center text-14 xl:text-body-large">
                OK
              </p>
            </Button>
          </div> : null}
          {status === 203 ? (
            <div className=" max-w-max m-auto">
            <Button
              filled
              onClick={(e) => (status === 203 ? handleSubs(e) : simpleClose)}
            >
              {/* {window.location.pathname.includes("/study-module/") ? (
                <p className="text-center text-14 xl:text-body-large">
                  Upgrade to the Premium Now
                </p>
              ) : ( */}
                <p className="text-center text-14 xl:text-body-large">
                  Start Your Free Trial Now
                </p>
              {/* )} */}
            </Button>
            </div>
          ) : null}
          {status === 400 && !getApiKey() ? (
            <Button filled onClick={(e) => handleLogin(e)}>
              <p className="text-center t\.ext-14 xl:text-body-large">
                Sign Up Now
              </p>
            </Button>
          ) : null}
          {status === 800 ? (
            <div className="flex space-x-10">
              <Button filled onClick={() => handleStatus800(getApiKey() ? 'basket' : !getApiKey() ? 'unlock' : 'start')}>
                <p className="text-center text-14 xl:text-body-large">
                  {getApiKey() ? user?.user_currency === 'UK' ? 'Add to Basket' : 'Add to Cart' : 'Start your free trial now'}
                </p>
              </Button>
              <Button onClick={() => handleStatus800('not')}>
                <p className="text-center text-14 xl:text-body-large">
                  Not Now
                </p>
              </Button>
            </div>
          ) : status === 801 ? (
            <div className="flex space-x-10">
              <Button filled onClick={() => handleStatus800(getApiKey() ? 'basket' : !getApiKey() ? 'unlock' : 'start')}>
                <p className="text-center text-14 xl:text-body-large">
                  {getApiKey() ? user?.user_currency === 'UK' ? 'Add to Basket' : 'Add to Cart' : 'Start your free trial now'}
                </p>
              </Button>
              <Button onClick={() => handleStatus800('not')}>
                <p className="text-center text-14 xl:text-body-large">
                  Not Now
                </p>
              </Button>
            </div>
          ) : status === 802 ? (
            <div className="flex space-x-10">
              <Button filled onClick={() => handleStatus800(getApiKey() ? 'basket' : !getApiKey() ? 'unlock' : 'start')}>
                <p className="text-center text-14 xl:text-body-large">
                  {getApiKey() ? user?.user_currency === 'UK' ? 'Add to Basket' : 'Add to Cart' : 'Start your free trial now'}
                </p>
              </Button>
              <Button onClick={() => handleStatus800('not')}>
                <p className="text-center text-14 xl:text-body-large">
                  Not Now
                </p>
              </Button>
            </div>
          ) : status === 803 ? (
              <div className="flex space-x-10">
                <Button filled onClick={() => handleStatus800(getApiKey() && 'start')}>
                  <p className="text-center text-14 xl:text-body-large">
                    Start your free trial now
                  </p>
                </Button>
                <Button onClick={() => handleStatus800('not')}>
                  <p className="text-center text-14 xl:text-body-large">
                    Not Now
                  </p>
                </Button>
              </div>
          ) : ""}
          {status === 700 ? (
            <Button filled onClick={handleMyTutorSessions}>
              <p className="text-center t\.ext-14 xl:text-body-large">
                See your Classes here
              </p>
            </Button>
          ) : null}
          {status === 100 &&
          window.location.href.includes("appointments_create") ? (
            <Button filled onClick={(e) => handleCalendar(e)}>
              <p className="text-center text-14 xl:text-body-large">
                Add to your calendar
              </p>
            </Button>
          ) : null}
          {status === 211 && !getApiKey() ? (
            <div className="flex flex-col justify-center items-center md:justify-between md:flex-row md:space-x-5 w-full mt-4 md:mt-10">
              <div className="w-56">
                <Button
                  filled
                  onClick={() => {
                    handleLogin();
                  }}
                >
                  <p>Sign Up Now</p>
                </Button>
              </div>
              <div className="w-56 mt-5 md:mt-0">
                <Button
                  onClick={() => {
                    loginRoute();
                  }}
                >
                  <p>Log In</p>
                </Button>
              </div>
            </div>
          ) : null}
        </div>
        {showDrop && (
          <div className="mt-10">
            <Select
              placeholder="Calendar"
              values={calendars}
              handleSelected={handleSelectedModule}
            />
          </div>
        )}
      </div>
    </Modal>
    <Modal
          open={trialModal}
          onClose={() => {
            setTrialModal(false)
            localStorage.setItem('freeTrialPopuped', false)
          }}
          focusTrapped={false}
          center
          classNames={{
            overlay: "custom-overlay-class",
            modal: "react-modal-class1",
          }}
        >
          <div className="w-full flex space-x-20">
            <div className="w-[50%]" style={{ width: "50%" }}>
              <div className="w-full">
                <h1 className="text-24 font-bold">
                  Try StudyCrowd.AI for Free
                </h1>
                <p>
                  Get everything you need to achieve top grades without reading
                  thousands of pages from overcomplicated textbooks <br />
                  The subcription includes simple and condensed study notes,
                  tutorial videos, quizzes, flashcards, example questions and
                  answers, and case summaries.
                </p>
                <p>Cancel anytime</p>
              </div>
              <div className="w-full">
                {subscriptionTypes.map((item, index) => {
                  return (
                    <div
                      className="checkbox flex items-center mt-2 md:mt-4"
                      key={index}
                    >
                      <div className="flex justify-between w-full items-center">
                        <div>
                          <input
                            id={item.id}
                            type="radio"
                            name="subscription"
                            className="input-assumpte"
                            onChange={() => handleRadioChange(item.price, item)}
                          />
                          <label
                            htmlFor={item.id}
                            className="text-16 custom2:w-10/12 md:text-body-large flex items-center"
                          >
                            {item.label}
                          </label>
                        </div>
                        <span className="ml-4">{item.price}</span>{" "}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex space-x-28" style={{ marginTop: "50px" }}>
                <p>Due {formattedDate}</p>
                <p>{selectedPrice || '0.00'}</p>
              </div>
              <div className="flex space-x-48" style={{ marginTop: "10px" }}>
                <p>
                  <b>Due Today</b>
                </p>
                <p>
                  <b>{userCurrency === "UK" ? "UK £0" : userCurrency === "US" ? 'US $0' : null}</b>
                </p>
              </div>
              <div
                className="flex justify-center items-center w-48 ml-24"
                style={{ marginTop: "50px" }}
              >
                <Button filled text={"Continue"} onClick={handleFreeTrialContinue} className="w-24" />
              </div>
            </div>
            <div
              className="w-[50%] bg-lightgray"
              style={{ marginTop: "8%", width: "50%" }}
            >
              <ol className="relative border-l-2 border-red-500 dark:border-red-500 border-dashed space-y-16">
                <li className="mb-10 ms-6 relative">
                  <span className="absolute flex items-center justify-center w-12 h-12 bg-white border border-primary  rounded-full -left-6 ring-24 ring-white dark:ring-white dark:bg-white">
                    <FaCalendar className="text-primary" />
                  </span>
                  <h3 className="flex items-center mb-1 font-bold text-lg font-semibold ml-12 text-primary dark:text-primary">
                    Today
                  </h3>
                  <p className="block mb-2 ml-12 text-xs font-normal leading-none text-red dark:text-gray-500">
                    Your 3-day free trial starts now
                  </p>
                </li>
                <li className="mb-10 ms-6 relative">
                  <span className="absolute flex items-center justify-center w-12 h-12 bg-white border border-primary rounded-full -left-6 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                    <FaBell className="text-primary" />
                  </span>
                  <h3 className="mb-1 text-lg text-primary font-bold ml-12 font-semibold text-gray-900 dark:text-white">
                    {formattedDate}
                  </h3>
                  <p className="block mb-2 ml-12 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                    Your subscription starts, unless you've cancelled during the
                    trial
                  </p>
                </li>
                <li className="ms-6 relative">
                  <span className="absolute flex items-center justify-center w-12 h-12 bg-white border border-primary rounded-full -left-6 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                    <FaCreditCard className="text-primary" />
                  </span>
                  <h3 className="mb-1 text-lg text-white font-bold ml-12 font-semibold text-gray-900 dark:text-white">
                    October 3rd, 2024
                  </h3>
                  <p className="block mb-2 ml-12 text-sm font-normal leading-none text-white dark:text-white">
                    Your subscription starts, unless you've cancelled during the
                    trial
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </Modal>
    </div>
  );
};

export default ErrorHandler;
