import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../components/container";
import Breadcrumb from "../../components/BreadCrumb";
import Button from "../../components/EmptyButton";
import Shuffle from "../../assets/images/shuffle.webp";
import ArrowLeft from "../../assets/images/arrowLeft.webp";
import ArrowRight from "../../assets/images/arrowRight.webp";
import ReactCardFlip from "react-card-flip";
import { createErrorAlert, getflashCards } from "../../redux/actions";
import {
  flashCardsData,
  flashCardsError,
  flashCardsLoading,
} from "../../redux/selectors";
import FullLoader from "../../components/Loader/FullLoader";
import { getApiKey, getUser, slugToText } from "../../helpers";
import { Helmet } from "react-helmet";
import ReportModal from '../../components/Modal/ReportModal';

import { useHistory, useLocation, useParams } from "react-router-dom";

let id = [];
let count = 0;

const Dot = ({ color }) => (
  <div
    className="mr-8"
    style={{
      backgroundColor: color,
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      display: "inline-block",
    }}
  ></div>
);

const FlashCards = ({ data, loading, getflashCards }) => {
  const param = useParams();
  const dispatch = useDispatch();
  const user = JSON.parse(getUser());
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false)


  const [state, setState] = useState({
    showFlashcards: [],
    toGoState: 0,
    currentIndex: 0,
    isFlipped: false,
    breadCrumbItem: [
      { title: "Get study modules", link: "/" },
      {
        title: slugToText(param.slug),
        link: `/study-module/${param.slug}`,
      },
    ],
  });

  useEffect(() => {
    if (!getApiKey()) {
      dispatch(
        createErrorAlert(
          211,
          "Info",
          "To view Flash Cards you must be logged in. No SimpleStudy account?",
          211
        )
      );
      setTimeout(() => {
        history.push("/");
      }, 9000);
    } else if (user?.user_type === "STUDENT") {
      if (
        user?.subscription_name === "Basic Subscription" &&
        user?.free_trial === false &&
        !location.state.paidModules.includes(param.slug)
      ) {
        dispatch(
          createErrorAlert(
            203,
            "",
            "To view Flash Cards you must have a Premium subscription.",
            203
          )
        );
        setTimeout(() => {
          history.push("/");
        }, 9000);
      } else {
        getflashCards(null, param.slug);
      }
    } else {
      getflashCards(null, param.slug);
    }
    count = 0;
    id = [];
  }, []);
  useEffect(() => {
    if (data?.length) {
      setState((prev) => ({
        ...prev,
        toGoState: data.length - 1,
        showFlashcards: data,
      }));
    }
  }, [data]);

  const flipCardCount = (e) => {
    if (!id.includes(e.id)) {
      id.push(e.id);
      count += 1;
    }
  };

  const cardsToGoRight = () => {
    if (data.length !== state.toGoState) {
      if (state.toGoState !== 0) {
        setState((prev) => ({
          ...prev,
          toGoState: state.toGoState - 1,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          toGoState: 0,
        }));
      }
    }
  };

  const cardsToGoLeft = () => {
    if (data.length - 1 !== state.toGoState) {
      setState((prev) => ({
        ...prev,
        toGoState: state.toGoState + 1,
      }));
    }
  };

  return (
    <>
      {loading ? <FullLoader></FullLoader> : null}
      <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Simple Studying | {slugToText(param.slug)} | FlashCards{" "}
          </title>
          <meta name="description" content="Get High Grades with the support of top graduates who took the same courses as you" />
          <link id={"linkTag"} rel="canonical" />
          <meta name="robots" content="index, follow" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:locale:alternate" content="en_gb" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Simple Studying | FlashCards" />
          <meta property="og:description" content="Get High Grades with the support of top graduates who took the same courses as you" />
          <meta property="og:url" id={"org_url"} />
          <meta property="og:site_name" content="Simple Studying" />
        </Helmet>
        <Container>
          {getApiKey() ? (
            <div className="py-4 md:py-8 lg:py-14 flex space-x-4">
              <Breadcrumb items={state.breadCrumbItem} current={"Flashcards"} />
              {/* <div className="w-48 flex mr-0">
                        <Button text={'Report'} className={'mr-0'} onClick={() => {
                            setOpen(!open)
                        }}></Button>
                    </div> */}
            </div>
          ) : null}

          <p className="tutorialVidText text-36 font-bold">
            {slugToText(param.slug)} flashcards
          </p>
          {data.length > 0 ? (
            <div>
              <div className="flex flex-col md:flex-row items-center justify-center md:space-x-28 mt-14 md:mt-24">
                <div className="flex flex-row items-center">
                  <Dot color="rgb(255,196,68)" />
                  <p className="text-darkGrey text-body-large mr-3">
                    Total cards
                  </p>
                  <p className="text-body-large">{data.length}</p>
                </div>
                <div className="flex flex-row items-center">
                  <Dot color="#216857" />
                  <p className="text-darkGrey text-body-large mr-3">Reviewed</p>
                  <p className="text-body-large" id={"review"}>
                    {count}
                  </p>
                </div>
                <div className="flex flex-row items-center">
                  <Dot color="#87326B" />
                  <p className="text-darkGrey text-body-large mr-3">
                    Cards to go
                  </p>
                  <p className="text-body-large">{state.toGoState}</p>
                </div>
              </div>
              <div
                id="cardContainer"
                className="flex items-center justify-center mt-5"
              >
                <ReactCardFlip
                  flipSpeedBackToFront={0.9}
                  flipSpeedFrontToBack={0.9}
                  isFlipped={state.isFlipped}
                  flipDirection="vertical"
                >
                  <div
                    className="flashCardBox w-320 md:w-725 h-420 flex items-center justify-center bg-main"
                    style={{
                      boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.03)",
                      borderRadius: "25px",
                    }}
                  >
                    <div
                      style={{ maxHeight: 300, userSelect: "none" }}
                      className="forH3Text text-h3"
                      dangerouslySetInnerHTML={{
                        __html:
                          state.showFlashcards[state.currentIndex]
                            ?.front_card_content,
                      }}
                    >
                      {/* {cards[currentIndex]?.question} */}

                      {/* {item.front_card_content} */}
                    </div>
                  </div>

                  <div
                    className="flashCardBox w-320 md:w-725 h-420 flex items-center justify-center "
                    style={{
                      boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.03)",
                      borderRadius: "25px",
                      userSelect: "none",
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          state.showFlashcards[state.currentIndex]
                            ?.back_card_content,
                      }}
                      style={{ maxHeight: 300 }}
                      className="text-h3"
                    >
                      {/* {item.back_card_content} */}
                      {/* {cards[currentIndex]?.answer} */}
                    </div>
                  </div>
                </ReactCardFlip>
              </div>
              <div className="flex flex-row items-center justify-center mt-5">
                <div className="flex w-320 md:w-725">
                  <div className="flex flex-row items-center justify-end w-11/12 md:w-4/6 mr-14">
                    <img
                      onClick={() => {
                        cardsToGoLeft();
                        if (state.currentIndex === 0) return;
                        else {
                          if (state.isFlipped) {
                            setState((prev) => ({
                              ...prev,
                              isFlipped: false,
                            }));
                            setTimeout(() => {
                              setState((prev) => ({
                                ...prev,
                                currentIndex: state.currentIndex - 1,
                              }));
                            }, 250);
                          } else {
                            setState((prev) => ({
                              ...prev,
                              currentIndex: state.currentIndex - 1,
                            }));
                          }
                        }
                      }}
                      className="cursor-pointer"
                      src={ArrowLeft}
                      alt="left"
                    />
                    <p className="mx-11">
                      {state.currentIndex + 1}/{data.length}
                    </p>
                    <img
                      onClick={() => {
                        cardsToGoRight();
                        if (state.currentIndex === data.length - 1) return;
                        else {
                          if (state.isFlipped) {
                            setState((prev) => ({
                              ...prev,
                              isFlipped: false,
                            }));
                            setTimeout(() => {
                              setState((prev) => ({
                                ...prev,
                                currentIndex: state.currentIndex + 1,
                              }));
                            }, 250);
                          } else {
                            setState((prev) => ({
                              ...prev,
                              currentIndex: state.currentIndex + 1,
                            }));
                          }
                        }
                      }}
                      className="cursor-pointer"
                      src={ArrowRight}
                      alt="right"
                    />
                  </div>
                  <div className="flex flex-row items-center justify-end w-1/12 md:w-2/6">
                    <img
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          showFlashcards: data
                            .map((value) => ({ value, sort: Math.random() }))
                            .sort((a, b) => a.sort - b.sort)
                            .map(({ value }) => value),
                        }))
                      }
                      className="cursor-pointer"
                      src={Shuffle}
                      alt="shuffle"
                    />
                  </div>
                </div>
              </div>
              <div className="flashCardBtnSec flex flex-col md:flex-row items-center justify-center  md:space-x-5 mt-12">
                <div className="w-60 md:w-48 mb-8">
                  <Button
                    filled
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        isFlipped: !state.isFlipped,
                      }));
                      flipCardCount(state.showFlashcards[state.currentIndex]);
                    }}
                  >
                    <p className="text-center text-14 xl:text-body-large">
                      Check answer
                    </p>
                  </Button>
                </div>
                <div className="w-60 mb-8">
                  <Button filled disabled>
                    <p className="text-center text-14 xl:text-body-large">
                      Need more practice
                    </p>
                  </Button>
                </div>
                <div className="w-60 md:w-32 mb-8">
                  <Button
                    filled
                    onClick={() => {
                      cardsToGoRight();
                      if (state.currentIndex === data.length - 1) return;
                      else {
                        if (state.isFlipped) {
                          setState((prev) => ({
                            ...prev,
                            isFlipped: false,
                          }));
                          setTimeout(() => {
                            setState((prev) => ({
                              ...prev,
                              currentIndex: state.currentIndex + 1,
                            }));
                          }, 250);
                        } else {
                          setState((prev) => ({
                            ...prev,
                            currentIndex: state.currentIndex + 1,
                          }));
                        }
                      }
                    }}
                  >
                    <p className="text-center text-14 xl:text-body-large">
                      Got it
                    </p>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <p style={{ textAlign: "center", marginTop: "50px" }}>
              No Cards to Show
            </p>
          )}

          <div className="pt-12 md:pt-18 lg:pt-24 pb-12 md:pb-18  lg:pb-24">
            <div className="flex flex-col items-center md:flex-row custom:justify-center md:px-20"></div>
          </div>
        </Container>
        <ReportModal open={open} setOpen={setOpen} slug={param.slug} content_type={'flashcard'}/>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: flashCardsLoading(state),
  error: flashCardsError(state),
  data: flashCardsData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getflashCards: (page, slug) => dispatch(getflashCards(page, slug)),
});
export default connect(mapStateToProps, mapDispatchToProps)(FlashCards);
