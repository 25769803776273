import React from 'react'

import BoxArrow from '../../assets/icons/box-arrow.svg'

const InfoBox = ({ text, align }) => {
    return (
        <div className='sub-plan'>
        <div className=' infoSubBox realtive w-52 h-24 p-5 bg-white text-small-text text-darkGrey rounded-xl' style={{ boxShadow: "0px 7px 25px rgba(0, 0, 0, 0.12)" }}>
            <div className='inInfoSubBox absolute -top-2 left-24 h-10 w-10'>
                <img src={BoxArrow} alt='box' />
            </div>
            <div dangerouslySetInnerHTML={{__html: text}} style={{textAlign: align}}></div>
        </div>
        </div>
    )
}

export default InfoBox
