import React from 'react'
import StripeCheckout from "react-stripe-checkout";

const StripeCheckoutPage = (props) => {

    const user = JSON.parse(sessionStorage.getItem('app_user'))

    const price = 200

    function handleToken(token, adresses) {
        console.log({token, adresses})
    }

    return (
        <div>
            <p>Checkout Page</p>

            <StripeCheckout
                token={handleToken}
                description="Your Product Info"
                name={user?.username}
                amount={price}
                // amount={dataToProps?.dataToOne2One?.selectedPackages?.price}
                currency="USD"
                stripeKey="pk_test_51L8IrbDB8N8wsMC5RD3nX7W28H9KzyFseK2ItmlAZIrf9G9yghFLLaE9VF1KIiZNYZm1xeBhsPvVvadjvVsxqEtW00QREXjwdN"
            />
        </div>
    )
}

export default StripeCheckoutPage