import React, { useEffect, useState } from 'react'
import {Helmet} from "react-helmet";
import { connect } from "react-redux";
import { getContentTermsAction } from '../../redux/actions';
import { contentTermsData, contentTermsError, contentTermsLoading } from '../../redux/selectors';

const TermsConditionsContent = (props) => {
    const { getTermsContent, data } = props;

    const [state, setState] = useState({
        lastUpdate: '',
        loading: true
    })

    useEffect(() => {
        getTermsContent()
    },[])

    useEffect(() => {
        if(data) {
            setState((prevState) => ({
                ...prevState,
                loading: false
            }))
            let updateArray = []
            for(let i=0; i<data.length; i++) {
                let date = new Date(data[i].updated_at).toISOString().split('T')[0];
                updateArray.push(date)
            }
            const currentDate = new Date();
            const differences = updateArray.map(date => {
            const pastDate = new Date(date);
            return Math.abs(currentDate - pastDate);
            });

            const minDifferenceIndex = differences.indexOf(Math.min(...differences));
            const nearestPastDate = updateArray[minDifferenceIndex];

            setState((prevState) => ({
                ...prevState,
                lastUpdate: nearestPastDate
            }))

        }
    },[data])

    return (
        <>
        <div className="mt-10 bg-white" style={{scrollBehavior: 'smooth'}}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Simple Studying | Terms and Conditions</title>
                <meta name="description" content="Get High Grades with the support of top graduates who took the same courses as you"/>
                <link rel="canonical" href="https://simplestudying.com/terms-conditions"/>
                <meta name="robots" content="index, follow"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:locale:alternate" content="en_gb"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Simple Studying | Terms and Conditions"/>
                <meta property="og:description" content="Get High Grades with the support of top graduates who took the same courses as you"/>
                <meta property="og:url" content="https://simplestudying.com/terms-conditions"/>
                <meta property="og:site_name" content="Simple Studying"/>
            </Helmet>
            <div className=" px-4 md:px-6 lg:px-8 bg-offWhite custom2:px-56 custom:px-56 ">
                <div className="termCondText text-large-small font-bold text-darkest md:text-large-medium">
                    <h2>Terms and Conditions Content Creator</h2>
                </div>
                <div className='cookiesPolicy'>
                    <p className='policyPara'>Last Updated {new Date(state.lastUpdate).getDate()} {new Date(state.lastUpdate).toLocaleString('en-US', { month: 'short' })}, {new Date(state.lastUpdate).getFullYear()}</p>
                </div>
                <div className='privacyTableContent'>
                    <p>Table of Contents</p>
                </div>
                <div className='termCondOlSec'>
                    <ol style={{cursor: 'pointer', fontSize: '20px', marginTop: '10px'}}>
                        {data?.length > 0 ? data?.map((item) => {
                            return(
                                <li><a href={`#${item.slug}`}>{item.title}</a></li>
                            )
                        }) : null}
                    </ol>
                </div>
                {data.length > 0 ? data?.map((item) => {
                    return(
                        <section id={item.slug}>
                    <p className='introText' style={{fontSize: '24px', fontWeight: 'bold', marginTop: '40px'}}>{item?.title}</p>
                    <p className='policyPara' dangerouslySetInnerHTML={{__html: item.content}}></p>
                </section>
                    )
                }): null}
            </div>
        </div>
       
        </>
    );
}

const mapStateToProps = (state) => ({
    loading: contentTermsLoading(state),
    error: contentTermsError(state),
    data: contentTermsData(state),
  });
  const mapDispatchToProps = (dispatch) => ({
    getTermsContent : () => dispatch(getContentTermsAction()),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(TermsConditionsContent);