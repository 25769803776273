import React, { useState } from 'react';

import Container from '../../components/container';
import Breadcrumb from '../../components/BreadCrumb';
import FreeUsers from './FreeUsers';

import {slugToText} from '../../helpers';
import PaidUsersEssay from './PaidUsersEssay';
import Button from "../../components/EmptyButton";
import ReportModal from '../../components/Modal/ReportModal';

const PreviewEssay = ({match, location}) => {
    const slug1 = match.params.slug1;
    const slug2 = match.params.slug2;
    const breadCrumbItem = [{title: 'Get study modules', link: '/'}, {
        title: slugToText(slug1),
        link: `/study-module/${slug1}`,
    }, {title: 'Model Essays', link: `/${slug1}/model-essays`}];
    
    const [open, setOpen] = useState(false)


    return (
        <div className="bg-white">
            <Container>
                <div>
                    {slug2 && !location?.state?.selected?.label ? (
                        <div className="hidden md:block md:pt-12 flex space-x-4 bd-show">
                            <Breadcrumb items={breadCrumbItem} current={slugToText(slug2)} />
                        </div>
                    ) : null}

                    {/* Button aligned to the right */}
                    <div className="flex w-48 justify-end mt-16" style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button className="mr-0" onClick={() => setOpen(!open)}>
                            <p>Report</p>
                        </Button>
                    </div>
                </div>
            </Container>


            {match.params.type === 'free' ? (
                <FreeUsers data={location?.state?.selected} slug={slug1}/>
            ) : (
                <PaidUsersEssay data={location?.state?.selected} type={'essay'} slug={slug2} slugModule={slug1}/>
            )}
                    <ReportModal open={open} setOpen={setOpen} slug={slug1} content_type={'essay'}/>

        </div>
    );
};

export default PreviewEssay;
