import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { teamData } from "../../redux/selectors";
import { getTeam } from "../../redux/actions/teamAction";
import { connect } from "react-redux";
import linkedIn from '../../assets/images/linkedblack.png'

const AboutUs = ({ getTeam, teamData }) => {

    useEffect(() => {
        timeLine()
        getTeam()
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        if (teamData.length) {
        }
    }, [teamData])

    const timeLine = () => {
        "use strict";

        var items = document.querySelectorAll(".timeline li");

        function isElementInViewport(el) {
            var rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <=
                (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        function callbackFunc() {
            for (var i = 0; i < items.length; i++) {
                if (isElementInViewport(items[i])) {
                    items[i].classList.add("in-view");
                }
            }
        }

        window.addEventListener("load", callbackFunc);
        window.addEventListener("resize", callbackFunc);
        window.addEventListener("scroll", callbackFunc);
    };


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Simple Studying | About Us</title>
                <meta name="description" content="Get High Grades with the support of top graduates who took the same courses as you" />
                <link rel="canonical" href="https://simplestudying.com/about-us" />
                <meta name="robots" content="index, follow" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:locale:alternate" content="en_gb" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Simple Studying | About Us" />
                <meta property="og:description" content="Get High Grades with the support of top graduates who took the same courses as you" />
                <meta property="og:url" content="https://simplestudying.com/about-us" />
                <meta property="og:site_name" content="Simple Studying" />
            </Helmet>
            <div className="my-4 sm:my-8 md:my-10 bg-white">
                <div className=" px-4 md:px-6 lg:px-8 bg-offWhite custom2:px-56 custom:px-56 ">
                    <div className="ourStorytext tipsHeadText text-large-small font-bold text-darkest md:text-large-medium">
                        Our Story
                    </div>
                    <div
                        className=" bg-subscription-upper bg-white bg-contain bg-no-repeat md:bg-offWhite pt-20 px-4 md:px-6 lg:px-8 custom2:px-56 custom:px-56 xl:px-60">
                        <p className="aboutUsCont" style={{ fontSize: "20px", marginTop: "-30px" }}>
                        In the US and UK, 40-45% of undergraduate university students drop out, losing hundreds of thousands of dollars in tuition fees and associated costs.<br />
                            <br />
                            Additionally, more than half of US college graduates are stuck in jobs that do not require a college degree. This clearly shows that a vast majority of college students either drop out or fail to get any return on investment from their college degrees.<br />
                            <br />
                            We address this problem with AI, aiming to reduce the high dropout rate and help college graduates build successful, high-income careers.<br/>
                            <br/>
                            Our story began at City University of London in the UK, where our founder studied. After witnessing the majority of her classmates drop out of university and lose hundreds of thousands of dollars, she became determined to fix the broken system.<br/>
                            <br/>
                            <a className="text-primary text-underline" href="https://www.linkedin.com/in/nini-sarishvili-8837a6114/">Follow our founder on Linkedin</a>
                        </p>
                    </div>
                    <br />
                </div>
            </div>
            {/* our portfolio sec  */}
            <div className="my-4 sm:my-8 md:my-10 bg-white border-t-2 border-gray-500 border-b-2 pb-24">
                <section class=" px-4 md:px-6 lg:px-8 custom2:px-56 custom:px-56">
                    <div class=" bg-white bg-contain bg-no-repeat pt-20 px-4 md:px-6 lg:px-8 custom2:px-56 custom:px-56 xl:px-60">
                        {/* <div class="flex flex-wrap -m-12"> */}
                        <p className="pt-16 valueText text-large-small font-bold text-darkest md:text-large-medium mb-2">Here is how we help college students achieve high grades and build high-income careers:</p>
                            <ul style={{listStyle: "bullet", fontSize: '20px'}}>
                                <li>At StudyCrowdAI, we collaborate with top graduates from leading US and UK universities to create simplified study resources and deliver personalized tutoring sessions that empower students to achieve top grades.</li>
                                <li>We also offer expert career guidance from graduates who have successfully built high-income careers. These mentors assist students with crafting standout CVs, planning their career paths, and applying for internships and graduate roles.</li>
                                <li>We're developing an AI tutor designed to revolutionize learning. This AI tutor will provide personalized study support, helping students excel academically.</li>
                                <li>Our vision includes partnering with top companies to offer high-quality internships and job opportunities to our students. We're creating smart technology to tailor each student's experience by recommending the most relevant study materials, tutors, career advisors, internships, and job openings.</li>
                                <li>For employers, we're streamlining the recruitment process, making it faster and easier to find the right interns and entry-level candidates.</li>
                            </ul>
                        {/* </div> */}
                    </div>
                </section>
            </div>
            {/* portfolio end */}


            {/* how to create material  */}

            {/* meet our team  */}

{/* 
            <div className="mt-10 bg-white">
                <div className=" px-4 md:px-6 lg:px-8 custom2:px-56 custom:px-56 bg-white pb-6 md:pb-14 lg:pb-20 ">
                    <div className="cMatrialText text-large-small text-center py-4 md:py-10 lg:py-16 font-bold text-darkest md:text-large-medium">
                        How We Create Materials

                    </div>

                    <section class="timeline">
                        <ul>
                            <li class="timeBoxOne">
                                <div>
                                    Our team of content developers combine all of their study materials, do additional
                                    reading and produce condensed, simple materials within established format of
                                    StudyCrowd.AI;
                                </div>
                            </li>
                            <li class="timeBoxTwo">
                                <div>
                                    We collaborate with current exceptionally talented law students with high 2:1 and
                                    1st class grades who further assist us to improve our materials and keep the content
                                    up to date

                                </div>
                            </li>
                            <li class="timeBoxThree">
                                <div>
                                    We compare our materials with the most recent lecture outlines, research any law
                                    reforms and update the content accordingly;


                                </div>
                            </li>
                            <li class="timeBoxFour">
                                <div>
                                    Result: simple, updated, high quality materials which includes all the necessary
                                    information to succeed in your exams. Generally, the content gets updated every
                                    couple of years. If there is an important change in curriculum, we update as soon as possible.

                                </div>
                            </li>
                        </ul>
                    </section>



                </div>
            </div> */}


            {/* how to create material end  */}


            {/* Our values Sec  */}


            {/* our values section start  */}
            <div className="mt-8 md:mt-16 lg:mt-20 bg-white">
                <div className=" px-4 md:px-6 lg:px-8 bg-white custom2:px-56 custom:px-56 ">
                    <div className="pt-16 valueText text-large-small font-bold text-darkest md:text-large-medium text-center">
                        Our Values
                    </div>
                    <div
                        className=" overValMain bg-white bg-contain bg-no-repeat md:bg-white pt-6 md:pt-8 lg:pt-10 px-0 md:px-0 lg:px-0  xl:px-60">
                        <section
                            class="w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-6 md:mt-8 lg:mt-10 mb-5">
                            {/* <!--   ✅ box 1  - Starts Here 👇 --> */}
                            <div class="w-72">
                                <div class="px-4 py-3 w-72 text-center valBox bg-offWhite">
                                    <h1 class="text-gray-400 font-bold text-xl mr-3 uppercase text-xs mb-4 text-darkest">
                                        Creativity
                                    </h1>
                                    <p class="valueP text-lg  block capitalize">
                                        Our team is constantly encouraged to
                                        to come up with creative ideas to
                                        improve our product every day.
                                    </p>
                                </div>
                            </div>
                            {/* <!--   ✅ box 2  - Starts Here 👇 --> */}
                            <div class="w-72 ">
                                <div class="px-4 py-3 w-72 text-center valBox bg-offWhite">
                                    <h1 class="text-gray-400 font-bold text-xl mr-3 uppercase text-xs mb-4 text-darkest">
                                        Passion
                                    </h1>
                                    <p className="valueP text-lg block capitalize">
                                        We are passionate about our mission - 
                                        bringing digital revolution to higher
                                        education and careers space.
                                    </p>
                                </div>
                            </div>
                            {/* <!--   ✅ box 3  - Starts Here 👇 --> */}
                            <div class="w-72  ">
                                <div class="px-4 py-3 w-72 text-center valBox bg-offWhite">
                                    <h1 class="text-gray-400 font-bold text-xl mr-3 p-[33px] uppercase text-xs mb-4 text-darkest">
                                        Fast is better <br/>than slow
                                    </h1>
                                </div>
                            </div>
                        </section>
                        {/* our value first row end */}

                        {/* our value second row start  */}

                        <section
                            class="w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 justify-items-center justify-center gap-y-20 gap-x-16 mt-6 md:mt-8 lg:mt-10 mb-5">
                            {/* <!--   ✅ box 1  - Starts Here 👇 --> */}
                            {/* <div class="w-64  ">
                                <div class="px-4 py-2 w-full text-center valBox">
                                    <h1 class="text-gray-400 font-bold text-xl mr-3 uppercase text-xs mb-4 text-darkest">
                                        Fast is better than slow
                                    </h1>
                                    <p class="text-lg   block capitalize">
                                        Although we focus on quality, we always
                                        try to deliver our development projects
                                        as fast as possible while guaranteeing a
                                        high quality.
                                    </p>
                                </div>
                            </div> */}
                            {/* <!--   ✅ box 2  - Starts Here 👇 --> */}
                            <div class="w-64  ">
                                <div class="px-4 py-2 w-full text-center valBox bg-offWhite">
                                    <h1 class="text-gray-400 font-bold text-xl mr-3 uppercase text-xs mb-4 text-darkest">
                                        Quality
                                    </h1>
                                    <p class="text-lg  block capitalize">
                                        We aim to create the best quality
                                        product to transform the higher education
                                        and careers for good.
                                    </p>
                                </div>
                            </div>
                            {/* <!--   ✅ box 3  - Starts Here 👇 --> */}
                            <div class="w-64  ">
                                <div class="px-4 py-2 w-full text-center valBox bg-offWhite">
                                    <h1 class="text-gray-400 font-bold text-xl mr-3 uppercase text-xs mb-4 text-darkest">
                                        Customer Satisfaction
                                    </h1>
                                    <p class="text-lg   block capitalize">
                                        We believe that high customer
                                        satisfaction is key to building a
                                        successful business.
                                    </p>
                                </div>
                            </div>
                            {/* <!--   ✅ box 4  - Starts Here 👇 --> */}
                            <div class="w-64  ">
                                <div class="px-2 py-3 w-full text-center valBox bg-offWhite">
                                    <h1 class="text-gray-400 font-bold text-xl mr-3 uppercase text-xs mb-4 text-darkest">
                                        Diversity and Inclusion
                                    </h1>
                                    <p class="text-lg   block capitalize">
                                        We bring the international minds 
                                        together today to build the business
                                        of tomorrow
                                    </p>
                                </div>
                            </div>
                        </section>

                        {/* our value second row end  */}
                    </div>
                    <br />
                </div>
            </div>
            {/* Our values Sec end  */}
            {/* Digital Platform sec */}
  

            {/* Digital Platform sec end*/}


            {/* meet our team  */}


            <div className="mt-2 md:mt-4 lg:mt-6 bg-white">
                <div className=" px-4 md:px-6 lg:px-8 bg-offWhite custom2:px-56 custom:px-56 ">
                    <div className="meetTeamtext text-large-small text-center py-2 md:py-6 lg:py-10 font-bold text-darkest md:text-large-medium">
                        Meet the Team
                    </div>
                    <div class="container my-4 md:my-8 lg:my-12 mx-auto px-0 md:px-2">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                        {teamData?.length > 0 && teamData?.map((item) => (
                            <div key={item.id} className="overflow-hidden rounded-lg shadow-lg bg-white">
                            <article>
                                <header className="leading-tight">
                                <div className="w-full">
                                    <img
                                    alt={item?.name || "Team member"}
                                    className="block w-full object-cover"
                                    style={{height: '500px'}}
                                    src={item?.image || "placeholder.jpg"}
                                    />
                                </div>
                                <div className="p-4 teamInfoSec flex">
                                    <div>
                                        <a href={item?.member_detail} target="_blank" className="cursor-pointer">
                                            <img src={linkedIn} width={40} height={40} className="rounded-lg mr-4 mt-2" />
                                        </a>
                                    </div>
                                    <div>
                                    <h3 className="employeName text-lg font-semibold">
                                    {item?.name}
                                    <span className="block text-sm text-gray-500">{item?.designation}</span>
                                    </h3>
                                    </div>
                                    
                                </div>
                                </header>
                            </article>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>


            {/* meet our team end */}
        </div>
    );
};
const mapStateToProps = (state) => ({
    teamData: teamData(state),
});

const mapDispatchToProps = (dispatch) => ({
    getTeam: () =>
        dispatch(getTeam()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
