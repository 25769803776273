import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Container from "../../components/container";
import Button from "../../components/EmptyButton";
import Input from "../../components/Input/InputForm";
import ShoppingCard from "../../components/ShoppingCard";
import { FaBell, FaCalendar, FaCreditCard } from "react-icons/fa";

import { getCatalogue } from "../../redux/actions";
import {
  catalogueData,
  catalogueError,
  catalogueLoading,
} from "../../redux/selectors";
import FullLoader from "../../components/Loader/FullLoader";
import { apiRequest, getUser } from "../../helpers";

const ShoppingBasket = (props) => {
  const { data, loading, getCatalogue } = props;

  const history = useHistory();
  const today = new Date();

  today.setDate(today.getDate() + 3);

  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = today.toLocaleDateString("en-US", options);

  const [state, setState] = useState({
    discountCode: '',
    showCatalogue: [],
    price: 0,
    currency: '',
    priceId: [],
    user: JSON.parse(getUser())
  })

  useEffect(() => {
    getCatalogue(null);
  }, []);
  useEffect(() => {
    if (data?.data?.length) {
      setState((prev) => ({
        ...prev,
        showCatalogue: data?.data
      }))
    } else {
      setState((prev) => ({
        ...prev,
        showCatalogue: data?.data,
        price: 0
      }))
    }
    for (let i = 0; i < data?.data?.length; i++) {
      if (
        !state.priceId.includes(data.data[i].study_module.price_id) &&
        data.data[i].is_paid === false
      ) {
        state.priceId.push(data.data[i].study_module.price_id);
      }
      let newTotalPrice = 0;
      let moduleCurrency = ''

      for (let i = 0; i < data?.data.length; i++) {
        if (data?.data[i].is_paid === false) {
          const priceModule = data?.data[i]?.study_module.price >= 1000 ? data?.data[i]?.study_module?.price / 100 : data?.data[i]?.study_module?.price;
          newTotalPrice += parseInt(priceModule);
          moduleCurrency = data?.data[i]?.study_module?.currency;
        }
      }

      setState((prev) => ({
        ...prev,
        price: newTotalPrice,
        currency: moduleCurrency
      }))
    }
  }, [data]);

  console.log(state, "currrency")

  const handleStartFreeTrial = async () => {
    let unPaidModules = []
    state.showCatalogue.map((item) => {
      if(item.is_paid === false){
        unPaidModules.push(item.study_module)
      }
    })
    await apiRequest({
      method: 'post',
      url: 'subscriptions/study_module_payment/',
      data: {
        modules: unPaidModules,
        success_url: window.location.origin + '/',
        cancel_url: window.location.origin + '/', 
      }
    }).then((res) => {
      debugger
    }).catch((err) => {
      debugger
    })
  }

  const deleteItem = async (id) => {
    const cards = [...state.showCatalogue];

    for (let index = 0; index < cards.length; index++) {
      const elem = cards[index];

      if (elem.id === id) {
        let findElement = state.priceId.find(
          (item) => item === elem.study_module.price_id
        );

        if (findElement !== undefined) {
          const indexToDelete = state.priceId.indexOf(findElement);

          if (indexToDelete !== -1) {
            state.priceId.splice(indexToDelete, 1);
            setState((prev) => ({
              ...prev,
              priceId: [...state.priceId]
            }))
          }
        }

        const res = await getCatalogue(id);

        if (res.status === 200) {
          getCatalogue(null);
        }

        break;
      }
    }
  };

  const checkout = async () => {
    if (state.priceId.length > 0) {
      await apiRequest({
        method: "post",
        url: "content/module-payment/",
        data: { price_id: state.priceId },
      }).then((res) => {
        window.location.href = res.data.checkout_url;
      });
    }
  };

  return (
    <>
      {loading ? <FullLoader></FullLoader> : null}
      <div className="custom2:pb-24 bg-basket-bg bg-white">
        <div className="">
          <Container>
            <div className="pt-10 lg:pt-20">
              <p className="tutorialVidText text-h3 md:text-36 font-bold text-darkest">
                Shopping {state.user.user_currency === "UK" ? "Basket" : "Cart"}
              </p>
              {state.showCatalogue?.length > 0 && (
                <p className="md:text-body-large text-darkest mt-5 custom:mt-12">
                  You selected the following content:
                </p>
              )}
            </div>
            <div className="lg:flex mt-9 space-y-10 lg:space-y-0 lg:space-x-7">
              <div className="flex-1 space-y-5">
                {state.showCatalogue?.length > 0
                  ? state.showCatalogue?.map((elem, index) => {
                      if (elem.is_paid === false) {
                        return (
                          <ShoppingCard
                            key={index}
                            image={elem.study_module.image_url}
                            title={elem.study_module.title}
                            university={elem.study_module.university}
                            classNumber={elem.grade ? elem.grade : "1st Class"}
                            price={
                              elem.study_module.price !== null && elem.study_module.price && elem.study_module.price >= 1000
                                ? elem.study_module.price / 100
                                : elem.study_module.price
                            }
                            currency={elem.study_module.currency}
                            onDelete={() => deleteItem(elem.id)}
                          />
                        );
                      }
                    })
                  : "No Data to Display"}
              </div>
              <div className="w-full lg:w-300 mac:w-472">
                <div
                  className="p-8 mac:p-12  mb-10 bg-white"
                  style={{
                    borderRadius: "14px",
                    boxShadow: " 4px 8px 20px rgba(2, 23, 54, 0.03)",
                  }}
                >
                  <div>
                    <p className="text-h5 mac:text-h3 text-darkest  font-bold ">
                      Summary
                    </p>
                  </div>
                  <div className="lg:mt-7 flex flex-col space-y-3 mac:space-y-0 mac:flex-row mac:items-end mac:space-x-5">
                    <div>
                      <label className="mb-8 text-13  mac:text-body-large">
                        Do you have any discount code?
                      </label>
                      <Input
                        placeholder=""
                        value={state.discountCode}
                        setValue={(value) => {
                          setState((prevState) => ({
                            ...prevState,
                            discountCode: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="flex-1">
                      <Button filled disabled={isNaN(state.price) ? true : false}>
                        <p className="text-center text-small-text xl:text-body-large">
                          Apply
                        </p>
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between items-center text-darkGrey text-13 mac:text-15 mb-4 mac:mb-6 mt-7 mac:mt-12">
                    <div className="flex justify-start w-2/4 ">
                      <p>Subtotal:</p>
                    </div>
                    <div className="flex justify-end w-2/4">
                      <p>{state.currency === 'usd' ? '$' : '£'} {isNaN(state.price) ? "0" : state.price}</p>
                    </div>
                  </div>

                  <div className="flex flex-row justify-between items-center text-darkest text-15 mac:text-body-large font-bold mb-11">
                    <div className="flex justify-start w-2/4 ">
                      <p>Total price:</p>
                    </div>
                    <div>
                      <p>{state.currency === 'usd' ? '$' : '£'}{isNaN(state.price) ? "0" : state.price}</p>
                    </div>
                  </div>
                  {/* {state.user?.subscription_name === "Basic Subscription" && state.user?.free_trial === true ? 
                  <div
                  className="w-[50%] bg-lightgray"
                  style={{ marginTop: "8%", width: "100%" }}
                >
                  <ol className="relative border-l-2 border-red-500 dark:border-red-500 border-dashed space-y-16">
                    <li className="mb-10 ms-6 relative">
                      <span className="absolute flex items-center justify-center w-12 h-12 bg-white border border-primary  rounded-full -left-6 ring-24 ring-white dark:ring-white dark:bg-white">
                        <FaCalendar className="text-primary" />
                      </span>
                      <h3 className="flex items-center mb-1 font-bold text-lg font-semibold ml-12 text-primary dark:text-primary">
                        Due Today {state?.user?.user_currency === "UK" ? '£' : '$'}0
                      </h3>
                      <p className="block mb-2 ml-12 text-xs font-normal leading-none text-red dark:text-gray-500">
                        Your 3-day free trial starts now
                      </p>
                    </li>
                    <li className="mb-10 ms-6 relative">
                      <span className="absolute flex items-center justify-center w-12 h-12 bg-white border border-primary rounded-full -left-6 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <FaBell className="text-primary" />
                      </span>
                      <h3 className="mb-1 text-lg text-primary font-bold ml-12 font-semibold text-gray-900 dark:text-white">
                        {formattedDate}
                      </h3>
                      <p className="block mb-2 ml-12 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                        Your subscription starts, unless you've cancelled during the
                        trial
                      </p>
                    </li>
                    <li className="ms-6 relative mt-[-30px]">
                      <span className="relative flex items-center justify-center w-12 h-12 bg-white border border-gray rounded-full -left-6 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <FaCreditCard className="text-gray" />
                      </span>
                      <h3 className="mb-1 text-lg text-white font-bold ml-12 font-semibold text-gray-900 dark:text-white">
                      </h3>
                      <p className="block mb-2 ml-12 text-sm font-normal leading-none text-white dark:text-white">
                       
                      </p>
                    </li>
                  </ol>
                  <Button text={'Start your free trial'} onClick={() => {
                    handleStartFreeTrial()
                  }} filled className={'w-full mt-20'}></Button>
                </div> :    */}
                  <div className="flex flex-col">
                    <div className="mb-7 w-full">
                      <Button
                        filled
                        disabled={state.priceId.length === 0 ? true : false}
                        onClick={checkout}
                      >
                        <p className="text-center text-small-text xl:text-body-large">
                          Make payment
                        </p>
                      </Button>
                    </div>
                  </div>
                {/* } */}
                  <p
                    onClick={() => history.push("/")}
                    className="text-primary text-15 cursor-pointer mac:text-body-large text-14 font-bold text-center mt-36"
                  >
                    Add more study materials
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: catalogueLoading(state),
  error: catalogueError(state),
  data: catalogueData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCatalogue: (id) => dispatch(getCatalogue(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ShoppingBasket);
