import React, {useEffect, useState} from 'react';
import './index.css';

import Container from '../../components/container';
import SessionScheduleContainer from '../../components/SessionScheduleContainer';
import Modal from '../../components/BookTutorialsModal';
import {
    getGroupSessionData,
    groupAppointmentData,
    groupAppointmentError,
    groupAppointmentLoading,
    tutorGroupSessionError,
    tutorGroupSessionLoading,
} from '../../redux/selectors';
import {createErrorAlert, getGroupSession, groupAppointment} from '../../redux/actions';

import {connect, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Helmet} from "react-helmet";

const BookTutorialsGroup = (props) => {
    const history = useHistory()
    const {getGroupSession, groupAppointment, groupSession, location} = props;
    let slug = location?.state?.selectedPackages?.slug
    const [modal, setModal] = useState(false)
    const [dataSession, setDataSession] = useState([groupSession])
    const dispatch = useDispatch()


    useEffect(() => {
        getGroupSession();
    }, []);

    const openSchedule = (id) => {
        const schedulesCopy = [...groupSession];
        schedulesCopy.forEach(elem => {
            if (elem.slug === id) {
                elem.open = true;
            } else {
                elem.open = false;
            }
        });
        setDataSession(schedulesCopy);
    };

    const closeSchedule = (id) => {
        const schedulesCopy = [...groupSession];
        schedulesCopy.forEach(elem => {
            if (elem.slug === id) {
                elem.open = false;
            }
        });
        setDataSession(schedulesCopy);
    };

    const togglePersons = (id, type) => {
        const schedulesCopy = [...groupSession];
        const schedule = schedulesCopy.find(value => value.slug === id);

        if (type === 'enlarge') {
            schedule.persons = schedule.persons + 1;
        } else {
            if (!(schedule.persons === 0)) {
                schedule.persons = schedule.persons - 1;
            }
        }

        setDataSession(schedulesCopy);
    };
    const editedSubmit = (slug, first_name, last_name, email, phone) => {
            let user = JSON.parse(sessionStorage.getItem('app_user'));
            let obj = {
                participant: user?.username,
                appointment: slug,
            };
            groupAppointment(obj);
            setModal(false);
    };

    let sortedAppointments
    if(groupSession.length > 0) {
        sortedAppointments = groupSession?.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB - dateA;
          });
    }

    return (
        <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat custom2:pb-20">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Simple Studying | Group Tutoring Date</title>
                <meta name="description" content="Get High Grades with the support of top graduates who took the same courses as you"/>
                <link rel="canonical" href="https://simplestudying.com/book-tutorials/group"/>
                <meta name="robots" content="index, follow"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:locale:alternate" content="en_gb"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Simple Studying | Group Tutoring"/>
                <meta property="og:description" content="Get High Grades with the support of top graduates who took the same courses as you"/>
                <meta property="og:url" content="https://simplestudying.com/book-tutorials/group"/>
                <meta property="og:site_name" content="Simple Studying"/>
            </Helmet>
            <Modal
                isOpen={modal}
                buttonOneText="Add to calendar"
                buttonTwoText="Finish"
                onClickFirstButton={() => setModal(false)}
                onClickSecondButton={() => setModal(false)}
                closeModal={() => setModal(false)}
                title="Congratulations!"/>
            <div className="xl:px-56">
                <Container>
                    <div className="pt-10 lg:pt-14 flex mb-10">
                        <div className="text-darkest">
                            <p className="text-h5 md:text-h4 lg:text-h3 custom2:text-36 font-bold mb-4 lg:mb-10 custom2:mb-12 ">Schedule A Session</p>
                            <p className=" text-body-small lg:text-body-large">Please scroll down and book the session you are interested
                                in.</p>
                        </div>
                    </div>
                    <div className="space-y-3.5 max-h-925 overflow-y-auto px-3.5" id="session-container">
                        {groupSession?.length ? sortedAppointments?.map(elem => (
                            <SessionScheduleContainer
                                status={elem.is_open}
                                open={elem.open}
                                numOfPersons={elem.persons}
                                data={elem}
                                notifyTutor={editedSubmit}
                                // date={props?.location?.state?.calendarDate}
                                openSchedule={() => openSchedule(elem.slug)}
                                closeSchedule={() => closeSchedule(elem.slug)}
                                enlargePersons={() => togglePersons(elem.slug, 'enlarge')}
                                reducePersons={() => togglePersons(elem.slug, 'reduce')}
                                book={() => history.push('/boost-your-grades')}/>
                        )) : null}
                    </div>
                </Container>

            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    loading: tutorGroupSessionLoading(state),
    error: tutorGroupSessionError(state),
    groupSession: getGroupSessionData(state),
    result: groupAppointmentData(state),
    sessionLoading: groupAppointmentLoading(state),
    sessionError: groupAppointmentError(state),
});
const mapDispatchToProps = dispatch => ({
    getGroupSession: () => dispatch(getGroupSession()),
    groupAppointment: (body) => dispatch(groupAppointment(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookTutorialsGroup);
