import React, { useContext, useEffect, useState } from "react";
import Container from "../../components/container";
import Header from "../../components/PlanYourStudyModule/Header";
import { useHistory } from "react-router-dom";
import Button from "../../components/EmptyButton";
import AddIcon from "../../assets/images/addIcon.webp";
import StudyCard from "../../components/StudyCard/DashboardCard";
import { apiRequest, getUser } from "../../helpers";
import FullLoader from "../../components/Loader/FullLoader";
import Context from "../../services/Context";
import StudyMaterials from "../../components/StudyModules/StudyMaterials";
import Slider from "../../components/MainAdminSlider";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ContentCreatorDashboard = () => {
  const history = useHistory();

  const [state, setState] = useState({
    user: JSON.parse(getUser()),
    modules: [],
    loading: false,
    key: ["degree-selected", "content-module", "studyModuleId"],
  });

  const { value, updateValue } = useContext(Context);
  const userCurrency = value?.userCurrency || state.user?.user_currency;

  useEffect(() => {
    console.log(value, "value");
    handleGetModules();
    state.key.forEach(function (key) {
      localStorage.removeItem(key);
    });
  }, []);

  const param = useParams()

  console.log(param, "param")

  const handleGetModules = async () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
      await apiRequest({
        method: "get",
        url: `studies/user/studymodules/?username=${param.slug}`,
      }).then((res) => {
        if (res.status === 200) {
          let array = [];
          res.data.map((item) => {
            if (
              item.slug !== "macroeconomics" &&
              item.slug !== "microeconomics" &&
              item.slug !== "business" &&
              item.slug !== "marketing" &&
              item.slug !== "our-company" &&
              item.slug !== "useful-articles" &&
              item.slug !== "uncategorized" &&
              item.slug !== "test" &&
              item.slug !== "partners"
            ) {
              array.push(item);
            }
          });
          setState((prev) => ({
            ...prev,
            modules: array,
            loading: false,
          }));
        }
      });
  };

  const slugName = (selected) => {
    if (selected.value) {
      localStorage.setItem("study-module", selected.value.slug);
      localStorage.setItem("study-module_id", selected.value.id);
    } else {
      localStorage.setItem("study-module", selected.slug);
      localStorage.setItem("study-module_id", selected.id);
    }
    history.push({
      pathname: `/study-module/${selected.slug}`,
      state: { selected },
    });
  };

  const handleWhatsapp = () => {
    window.open("https://chat.whatsapp.com/IJ6MNDyfXx85omZc1JEAdC", "_blank");
  };

  return (
    <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat">
      <div>
        {window.location.pathname.includes('shared') && <Container>
          <div className="pt-10 lg:pt-14 flex flex-row items-center">
            <p className="w-full text-center text-h5 md:text-h4 custom2:text-36 font-bold ">
              Your content creator dashboard
            </p>
            {/* <div className="w-48">
              <Button text={'Share'} onClick={() => history.push(`/content-creator/${state.user.username}/`)} filled></Button>
            </div> */}
          </div>
          <div>
            <div className="w-full">
              <div className="pb-4 md:pb-14 xl:pb-20 mt-20 w-full cursor-pointer">
                {state.modules?.length === 0 ? (
                  <div>
                    <p>You don't have any published modules</p>
                  </div>
                ) : (
                    
                    <div className="grid grid-cols-3 gap-4">
                    {state?.modules.map((item, index) => (
                      <div className="space-x-4">
                        <StudyMaterials
                          key={index}
                          image={item?.image}
                          cardTitle={item?.title}
                          slug={item}
                          text={item?.short_description}
                          price={""}
                          handleClick={() => slugName(item)}
                        />
                      </div>
                    ))}
                    </div>
                )}
              </div>

              {/* <div
                className="px-4 custom2:px-12 flex flex-row pb-0 pt-16 md:py-16 items-center cursor-pointer justify-center "
                onClick={() => {
                  history.push("/content-creator/step-one");
                }}
              >
                <img className="w-3.5 h-auto" src={AddIcon} alt="add" />
                <span className="font-bold text-16 md:text-body-large text-primary ml-16">
                  Add more content
                </span>
              </div> */}
              {/* <div className="py-20">
                <div className="flex justify-center items-center md:flex-row">
                  <div className="w-200 h-12 mb-10 md:mb-0">
                    <Button
                      filled
                      onClick={() =>
                        history.push({ pathname: "/content-receipts" })
                      }
                    >
                      <p className="text-center text-14 xl:text-body-large">
                        Check your earnings
                      </p>
                    </Button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </Container>}
      </div>
    </div>
  );
};

export default ContentCreatorDashboard;
