import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Input from "../Input/InputForm";
import Button from "../EmptyButton";
import { connect, useDispatch } from "react-redux";
import editIcon from "../../assets/images/editIcon.webp";
import zoom from "../../assets/images/zoom.webp";
import apiRequest from "../../helpers/apiRequest";
import timeCheck from "../../helpers/timecheck";
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import SingleCheckbox from "../../components/CheckBoxQuestion/SingleCheckBox";
import {
  groupParticipantsData,
  tutorOne2OneUpdateData,
  tutorSessionUpdateData,
} from "../../redux/selectors";
import {
  createErrorAlert,
  getGroupParticipants,
  getTutorAvailability,
  tutorSessionOneToOneUpdate,
  tutorSessionUpdate,
} from "../../redux/actions";
import { GetPackage, GetTimeSlots } from "../../services";
import { Modal } from "react-responsive-modal";
import Select from "../Select";

const StatusBadge = ({ status }) => {
  return (
    <div
      className={`cursor-pointer py-1 px-2.5 text-white font-normal text-small-text md:text-14 ${
        status === "open" || status === "completed"
          ? "bg-offGreen"
          : status === "canceled" && "bg-my"
      }`}
      style={{ borderRadius: "43px" }}
    >
      {status}
    </div>
  );
};

const TutorSessionStudents = ({
  status,
  open,
  type,
  openOne,
  openSchedule,
  openScheduleOnetoOne,
  closeSchedule,
  closeScheduleOnetoOne,
  loading,
  oneToOne,
  getGroupParticipants,
  getTutorSession,
  data,
  participants,
  update,
  updateTutorOne2One,
  updateSession,
  getTutorAvailability
}) => {
  const history = useHistory();
  let dates, month, dataDay, dataMonths, one2oneDay, one2oneMonths, time;

  const dispatch = useDispatch();
  const [sessionStatuses, setSessionStatuses] = useState([
    { status: "CANCEL", correct: false },
    {
      status: "RESCHEDULE",
      correct: false,
    },
    { status: "NOTIFY", correct: false },
  ]);
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState(data?.title);
  const [titleOne2One, setTitleOne2One] = useState(oneToOne?.title);
  const [priceOne2One, setPriceOne2One] = useState(oneToOne?.package_price);
  const [dateNew, setDateNew] = useState(oneToOne?.date);
  const [capacity, setCapacity] = useState(data?.max_capacity);
  const [editTitleModal, setEditTitleModal] = useState(false);
  const [addCalendarModal, setAddCalendarModal] = useState(false)
  const [packageList, setPackageList] = useState([]);
  const [timeError, setTimeError] = useState(false)
  const [statusValue, setStatusValue] = useState("");
  const [times, setTime] = useState("");
  const [dateModal, setDateModal] = useState(false);
  const [res, setRes] = useState([]);
  const [freeDate, setFreeDate] = useState(false);
  const [timeArray, setTimeArray] = useState([]);
  const [dateSelected, setDateSelected] = useState("");
  const [timeSlotes, setTimeSlots] = useState([]);
  const [tutorAvail, setTutorAvail] = useState("");
  const [calendars, setCalendars] = useState([
    {
      value: "google",
      label: "Google Calendar",
    },
    {
      value: "iCal",
      label: "iCalendar",
    },
    {
      value: "yahoo",
      label: "Yahoo",
    },
    {
      value: "outlook",
      label: "Outlook",
    },
  ]);
  const [sessionStatus, setSessionStatus] = useState([
    { status: "OPEN", correct: false },
    {
      status: "COMPLETE",
      correct: false,
    },
    { status: "CANCEL", correct: false },
  ]);
  const user = JSON.parse(sessionStorage.getItem("app_user"));

  useEffect(() => {}, [participants]);
  if (data?.appointment !== undefined) {
    let localDate = new Date(data?.appointment?.date);
    dataMonths = localDate.toLocaleString("default", { month: "short" });
    dataDay = localDate.getDate();
  } else if (data !== undefined) {
    let localDate = new Date(data?.date);
    dataMonths = localDate.toLocaleString("default", { month: "short" });
    dataDay = localDate.getDate();
  }
  if (oneToOne !== undefined) {
    let oneDate = new Date(oneToOne?.date);
    one2oneMonths = oneDate.toLocaleString("default", { month: "short" });
    one2oneDay = oneDate.getDate();
  }

  const sessionCheckOneToOne = (value, type) => {
    if (value === "RESCHEDULE") {
      setDateModal(true);
    }
    let data = [...sessionStatuses];
    data.map((item) => {
      if (item.status === value) {
        item.correct = true;
      } else {
        item.correct = false;
      }
    });
    setStatusValue(value);
    setSessionStatuses(data);
  };

  const sessionCheck = (value, checked) => {
    let data = [...sessionStatus];
    data.map((item) => {
      if (item.status === value) {
        item.correct = true;
      } else {
        item.correct = false;
      }
    });
    setSessionStatus(data);
  };

  const getMonthFromString = (monthString) => {
    return new Date(Date.parse(monthString + " 1, 2000")).getMonth();
  };

  const pastDateCheck = () => {
    const now = new Date();
    const providedDate = new Date(
      now.getFullYear(),
      getMonthFromString(one2oneMonths),
      parseInt(one2oneDay)
    );

    if (now.getDate() > providedDate.getDate()) {
      return "Past";
    } else {
      return "Up Coming";
    }
  };

  const pastDateCheckGroup = () => {
    const now = new Date();
    const providedDate = new Date(
      now.getFullYear(),
      getMonthFromString(dataMonths),
      parseInt(dataDay)
    );

    if (now.getDate() > providedDate.getDate()) {
      return "Past";
    } else {
      return "Up Coming";
    }
  };

  useEffect(() => {
    GetPackage("one-2-one").then((res) => {
      let pack_obj = [];
      if (res?.data?.length !== 0) {
        res.data.map((item) => {
          pack_obj.push({ title: item?.description, slug: item?.slug });
        });
      }
      setPackageList(pack_obj);
    });
    GetTimeSlots().then((response) => {
      let obj = [];
      setRes(response?.data);
      if (response?.data?.sunday?.length !== 0) {
        response?.data?.sunday?.map((item) => {
          obj.push(item?.slot?.slot);
        });
      }
      setTimeArray(obj);
    });
  }, []);
  const updateSessions = (slug) => {
    const correctStatus = sessionStatus
      .filter((item) => item.correct)
      .map((item) => item.status);

    let obj;
    if (times !== "" && dateNew !== undefined) {
      obj = {
        title: title,
        max_capacity: capacity,
        date: dateNew,
        start_time: times,
        status: correctStatus[0],
      };
    } else if (times !== "" && dateNew === undefined) {
      obj = {
        title: title,
        max_capacity: capacity,
        date: slug?.date,
        start_time: times,
        status: correctStatus[0],
      };
    } else if (times === "" && dateNew === undefined) {
      obj = {
        title: title,
        max_capacity: capacity,
        date: slug?.date,
        start_time: timeCheck(slug?.start_time),
        status: correctStatus[0],
      };
    } else if (times === "" && dateNew !== undefined) {
      obj = {
        title: title,
        max_capacity: capacity,
        date: dateNew,
        start_time: timeCheck(slug?.start_time),
        status: correctStatus[0],
      };
    }

    updateSession(slug.slug, obj);
    if (correctStatus[0] === "CANCEL") {
      dispatch(
        createErrorAlert(
          201,
          "Congratulations",
          "Group Session is cancelled successfully",
          201
        )
      );
    } else {
      dispatch(
        createErrorAlert(
          201,
          "Congratulations",
          "Group Session is updated successfully",
          201
        )
      );
    }
  };

  const handleSelectedModule = (item) => {
    let event = []
    let startEvent
    if(type === 'oneToOne'){
      startEvent = `${oneToOne.date} ${oneToOne.created_at}`
    } else if(type === 'group'){
      if(data?.appointment){
        startEvent = `${data.appointment.date} ${data.appointment.start_time}`
      } else {
        startEvent = `${data.date} ${data.start_time}`
      }
    }
    event.push({
        start: startEvent,
        duration: [1, "hour"],
        title: type === 'oneToOne' ? oneToOne.title : data.appointment ? data.appointment.title : data.title,
        description: type === 'oneToOne' ? "One to One Appointment with Tutor" : 'Group Appointment with Tutor',
        busy: true,
        
    });
    for(let i=0; i<event.length; i++) {
      if (item.value === "google") {
        let url = google(event[i]);
        window.open(url, "_blank");
      }
      if (item.value === "yahoo") {
        let url = yahoo(event[i]);
        window.open(url, "_blank");
      }
      if (item.value === "outlook") {
        let url = outlook(event[i]);
        window.open(url, "_blank");
      }
      if (item.value === "iCal") {
        let url = ics(event[i]);
        const link = document.createElement("a");
        link.href = url;
        link.download = event[i].title;
        link.click();
      }
    }
    setAddCalendarModal(false)
  };

  const holidayCheck = async (one) => {
    return await apiRequest({
      method: "get",
      url: `accounts/tutor/${one?.availability[0]?.tutor}/${dateSelected}`,
    })
      .then((res) => {
        console.log("passed", res);
        return false;
      })
      .catch((err) => {
        if (
          err.response.data[0] ===
            `Tutor has marked Holiday for this date: ${dateSelected}` &&
          user?.user_type === "STUDENT"
        ) {
          dispatch(
            createErrorAlert(
              400,
              "Error",
              "Tutor is not available on your selected date",
              400
            )
          );
        } else if (
          err.response.data[0] ===
            `Tutor has marked Holiday for this date: ${dateSelected}` &&
          user?.user_type === "TEACHER"
        ) {
          dispatch(
            createErrorAlert(
              400,
              "Error",
              "You have marked this selected date as holiday",
              400
            )
          );
        }
        return true;
      });
  };

  const updateSessionOne2One = async (one, bool) => {
    let status, obj;
    sessionStatus.forEach((item) => {
      if (item.correct === true) {
        status = item.status;
      }
    });

    if (dateSelected && times) {
      obj = {
        title: titleOne2One,
        date: dateSelected,
        start_time: times,
        status: statusValue,
        package: oneToOne.package_slug,
      };
    } else {
      if(times === '' && bool === undefined){
        setTimeError(true)
        return
      } else{
        setTimeError(false)
      }
      obj = {
        title: titleOne2One,
        date: dateNew,
        start_time: one.start_time,
        end_time: one.end_time,
        status: status,
        package: oneToOne.package_slug,
      };
    }

    updateTutorOne2One(one.slug, obj);
    dispatch(
      createErrorAlert(
        201,
        "Congratulations",
        "One to One Session is updated successfully",
        201
      )
    );
    window.location.reload();
  };

  const detailSession = (oneToOne, data, type) => {
    if (type === "group") {
      history.push({
        pathname: `/session-detail`,
        state: { data, type },
      });
    } else {
      history.push({
        pathname: `/session-detail`,
        state: { oneToOne, type },
      });
    }
  };

  if (type === "group") {
    dates = new Date(data?.date);
    month = dates?.toLocaleString("default", { month: "short" });
  }

  const updateOneToOneModal = () => {
    let obj = {
      title: titleOne2One,
      date: dateNew,
      start_time: oneToOne.start_time,
      end_time: oneToOne.end_time,
      status: statusValue,
      package: oneToOne.package_slug,
    };
    updateTutorOne2One(oneToOne.slug, obj);
    window.location.reload();
  };

  const tutorSet = async (data) => {
    if (user?.user_type === "STUDENT") {
      GetTimeSlots(data?.availability[0]?.tutor_id).then((res) => {
        let obj = [];
        setRes(res?.data);
        if (res?.data?.sunday?.length !== 0) {
          res?.data?.sunday?.map((item) => {
            obj.push(item?.slot?.slot);
          });
        }
        setTimeSlots(obj);
      });
    } else if (user?.user_type === "TEACHER") {
      GetTimeSlots().then((res) => {
        setTimeSlots(res.data);
      });
    }
  };

  const editTitleUpdate = () => {
    setEditTitleModal(true);
  };

  const handleDateSelect = async (date) => {
    setDateSelected(date);
    getTutorAvailability(oneToOne?.availability[0]?.tutor, date)
      .then((res) => {
        let obj = [];
        for (let i = 0; i < res.data.length; i++) {
          for (let j = 0; j < res?.data[i].available_slot.length; j++) {
            if (res?.data[i].available_slot.length > 0) {
              if (!obj.includes(res?.data[0].available_slot[j].slot.slot)) {
                obj.push(res?.data[0].available_slot[j].slot.slot);
              }
            }
          }
        }
        setTimeArray(obj);
        setTimeSlots(obj);
        setTutorAvail("");
      })
      .catch((err) => {
        if (
          err.response.data[0] ===
          `Tutor has marked Holiday for this date: ${date}`
        ) {
          setTutorAvail(`Tutor has marked Holiday for this date: ${date}`);
          dispatch(
            createErrorAlert(
              400,
              "Error",
              "Tutor is not available on your selected date",
              400
            )
          );
        }
      });
  };

  return (
    <div>
      <Modal
        open={dateModal}
        onClose={() => setDateModal(false)}
        focusTrapped={false}
        center
        classNames={{
         modal: 'react-modal-class2', 
        }}
      >
        <div class="radioRow flex flex-col justify-around my-6 lg:my-12">
          <div className="w-6/6 mb-6">
            <label className="mb-8 md:text-body-large">Calendar</label>
            <Input
              type="date"
              value={dateSelected}
              reschedule={true}
              freeDate={freeDate}
              setValue={handleDateSelect}
            />
          </div>
          {(timeArray.length || timeSlotes.length > 0) &&
          tutorAvail.length === 0 ? (
            <div className="w-6/6">
              <label className="mb-8 md:text-body-large">Time</label>
              <div className="flex justify-center">
                <select
                  className="form-select text-input focus:bg-offWhite focus:border-none text-input-container w-fullpx-3 py-1.5 text-base font-normal text-gray-700 transitionease-in-out m-0
     "
                  aria-label="Default select example"
                  onChange={(e) => setTime(timeCheck(e.target.value))}
                >
                  <option value="select">--- Select any Time Slot ---</option>
                  {timeArray.length > 0
                    ? timeArray?.map((item) => {
                        return <option value={item}>{item}</option>;
                      })
                    : timeSlotes?.length > 0
                    ? timeSlotes?.map((item) => {
                        return <option value={item}>{item}</option>;
                      })
                    : null}
                </select>
              </div>
              {timeError && <p style={{color: 'red'}}>*Time field is required</p>}
            </div>
          ) : null}
        </div>
        <div className="mt-6 flex flex-col md:flex-row items-center">
          <div className="w-full flex flex-col space-y-5 md:flex-row md:items-center md:space-y-0 md:space-x-5 md:justify-end">
            <div className="md:w-44">
              <Button filled onClick={() => updateSessionOne2One(oneToOne)}>
                <p className="text-15 mac:text-body-large">Confirm</p>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={addCalendarModal}
        onClose={() => setAddCalendarModal(false)}
        focusTrapped={false}
        center
        classNames={{
          overlay: 'custom-overlay-class',
         modal: 'react-modal-class2', 
        }}
      >
        <div class="radioRow flex flex-col justify-around my-6 lg:my-12">
        <Select
              placeholder="Calendar"
              values={calendars}
              handleSelected={handleSelectedModule}
            />
        </div>
      </Modal>
      <Modal
        open={modal}
        onClose={() => setModal(false)}
        focusTrapped={false}
        center
        classNames={{
          modal: 'react-modal-class2', 
         }}
      >
        <div class="radioRow flex flex-wrap justify-around my-4 md:my-8 lg:my-12">
          {sessionStatuses.map((item) => (
            <SingleCheckbox
              id={item.status}
              text={item.status}
              correct={item.correct}
              handleChange={sessionCheckOneToOne}
            />
          ))}
        </div>

        <div className="mt-6 flex flex-col md:flex-row items-center">
          <div className="w-full flex flex-col space-y-5 md:flex-row md:items-center md:space-y-0 md:space-x-5 md:justify-end">
            <div className="md:w-44">
              <Button
                filled
                onClick={() => {
                  setModal(false);
                  updateOneToOneModal();
                }}
              >
                <p className="text-15 mac:text-body-large">Confirm</p>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {loading ? <p>...Loading</p> : null}
      {type === "group" && user?.user_type !== "STUDENT" && (
        <div
          className="p-4 md:p-10 bg-white"
          style={{
            borderRadius: "14px",
            boxShadow: " 4px 8px 20px rgba(2, 23, 54, 0.03)",
          }}
        >
          <div className="flex justify-between md:flex-row sm:flex-col">
            <div className="flex">
              <div className="text-center mr-5 md:mr-14 flex flex-col justify-center">
                <p className="text-primary text-14 md:text-body-large font-normal">
                  {dataMonths}
                </p>
                <p className="font-bold text-darkest text-body-large md:text-h3">
                  {dataDay}
                </p>
              </div>
              <div>
                <p className="text-13 md:text-h5 font-bold text-darkest cursor-pointer">
                  {data?.title}
                </p>
                <div className="flex gap-x-1.5 ">
                  <p className="text-13 md:text-16 text-right text-primary font-bold">
                    FREE -
                  </p>
                  <p className="text-13 md:text-16 text-right  font-bold greenText">
                    Group
                  </p>
                </div>

                <p className="text-11 md:text-14 text-darkest mt-3.5">
                  Capacity:{" "}
                  <span className="text-darkGrey">{`${data?.persons}/${data?.max_capacity}`}</span>
                </p>
                <p className="text-11 md:text-14 text-darkest mt-2">
                  Start Time:
                  <span className="text-darkGrey">{data?.start_time}</span>
                  <span className="text-11 md:text-14 text-darkest ml-4">
                    End Time:
                    <span className="text-darkGrey">{data?.end_time}</span>
                  </span>
                </p>
              </div>
            </div>
            <div className="sessRightSec">
              <div className="flex justify-end items-center gap-x-1.5 mb-4">
                {user?.user_type !== "STUDENT" && data?.persons !== 0 && (
                  <p
                    className="cursor-pointer py-1 px-3 text-white font-normal text-small-text md:text-12 bg-primary"
                    style={{ borderRadius: 43 }}
                    onClick={() => detailSession(oneToOne, data, type)}
                  >
                    {data?.persons} Participants
                  </p>
                )}
                {data?.zoom_link !== null && user?.user_type !== "STUDENT" && (
                  <p className="text-13 md:text-16 text-right text-primary font-bold">
                    <a
                      href={data?.zoom_link?.start_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="inline-block  cursor-pointer"
                        src={zoom}
                        alt=""
                        style={{ width: 25 }}
                      />
                    </a>
                  </p>
                )}
                {data?.zoom_link !== null && user?.user_type === "STUDENT" && (
                  <p className="text-13 md:text-16 text-right text-primary font-bold">
                    <a
                      href={data?.zoom_link?.join_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="inline-block  cursor-pointer"
                        src={zoom}
                        alt=""
                        style={{ width: 25 }}
                      />
                    </a>
                  </p>
                )}
                {user?.user_type !== "STUDENT" && (
                  <p
                    className="text-13 md:text-16 text-right text-primary font-bold"
                    onClick={openSchedule}
                  >
                    <img
                      className="inline-block  cursor-pointer"
                      src={editIcon}
                      alt=""
                    />
                  </p>
                )}
              </div>
              <div className="mt-4 w-max ">
                <Button text="Add to Calendar" onClick={() => setAddCalendarModal(true)}></Button>
              </div>
              <div className="text-center">
                <StatusBadge status={status} />
              </div>
            </div>
          </div>

          {open && (
            <div className="mt-10">
              <div>
                <form className="md:flex  flex-col gap-x-6 gap-y-9">
                  <div className="flex gap-x-1.5">
                    <div className="w-3/6">
                      <label className="mb-8 md:text-body-large">Title</label>
                      <Input
                        type="text"
                        placeholder=""
                        value={title}
                        setValue={setTitle}
                      />
                    </div>
                    <div className="w-3/6">
                      <label className="mb-8 md:text-body-large">Capacity</label>
                      <Input
                        type="number"
                        placeholder=""
                        value={capacity}
                        setValue={setCapacity}
                      />
                    </div>
                  </div>

                  <div className="flex gap-x-1.5">
                    <div className="w-3/6">
                      <label className="mb-8 md:text-body-large">
                        Calendar
                      </label>
                      <Input
                        placeholder=""
                        type="date"
                        value={dateNew}
                        setValue={setDateNew}
                      />
                    </div>
                    <div className="w-3/6">
                      <label className="mb-8 md:text-body-large">Time</label>
                      <div className="flex justify-center">
                        <select
                          className="form-select text-input focus:bg-offWhite focus:border-none text-input-container w-fullpx-3 py-1.5 text-base font-normal text-gray-700 transitionease-in-out m-0"
                          aria-label="Default select example"
                          onChange={(e) => setTime(timeCheck(e.target.value))}
                        >
                          {timeArray?.map((item) => {
                            let start, startTime;
                            if (item.includes("AM")) {
                              start = item.split(" AM")[0];
                              if (data.start_time.includes(start)) {
                                startTime = data.start_time;
                              }
                            } else if (item.includes("PM")) {
                              start = item.split(" PM")[0];
                              if (data.start_time.includes(start)) {
                                startTime = data.start_time;
                              }
                            }

                            return (
                              <option selected={startTime} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              {/* radio start  */}
              <div className="radioRow flex justify-around my-12">
                {/* {sessionStatus.map((item) => (
                  <SingleCheckbox
                    id={item.status}
                    text={item.status}
                    correct={item.correct}
                    handleChange={sessionCheck}
                  />
                ))} */}
              </div>
              {/* radio row end */}
              <div className="mt-6 flex flex-col md:flex-row items-center">
                <div className="py-6 md:pl-16" onClick={closeSchedule}>
                  <span className="font-bold text-body-large text-primary cursor-pointer">
                    Cancel
                  </span>
                </div>
                <div className="w-full flex flex-col space-y-5 md:flex-row md:items-center md:space-y-0 md:space-x-5 md:justify-end">
                  <div className="md:w-44">
                    <Button filled onClick={() => updateSessions(data)}>
                      <p className="text-15 mac:text-body-large">Confirm</p>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {type === "group" && user?.user_type === "STUDENT" && (
        <div
          className="p-4 md:p-10 bg-white"
          style={{
            borderRadius: "14px",
            boxShadow: " 4px 8px 20px rgba(2, 23, 54, 0.03)",
          }}
        >
          <div className="flex justify-between md:flex-row sm:flex-col">
            <div className="flex">
              <div className="dayMonthSec text-center mr-6  md:mr-14 flex flex-col justify-center">
                <p className="text-primary text-14 md:text-body-large font-normal">
                  {dataMonths}
                </p>
                <p className="font-bold text-darkest text-body-large md:text-h3">
                  {dataDay}
                </p>
              </div>
              <div className="appointSec">
                <p className="appointTitle text-13 md:text-h5 font-bold text-darkest cursor-pointer">
                  {data?.appointment?.title}
                </p>
                <div className="freeGroupSec flex gap-x-1.5 ">
                  <p className="text-13 md:text-16 text-right text-primary font-bold">
                    FREE -
                  </p>
                  <p className="text-13 md:text-16 text-right  font-bold greenText">
                    Group
                  </p>
                </div>

                <p className=" capcText text-11 md:text-14 text-darkest mt-3.5">
                  Capacity:{" "}
                  <span className="text-darkGrey">{`${data?.appointment?.persons}/${data?.appointment?.max_capacity}`}</span>
                </p>
                <p className="timeSec text-11 md:text-14 text-darkest mt-2">
                  Start Time:
                  <span className="text-darkGrey">
                    {data?.appointment?.start_time}
                  </span>
                  <span className="text-11 md:text-14 text-darkest ml-4">
                    End Time:
                    <span className="text-darkGrey">
                      {data?.appointment?.end_time}
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div>
              <div className="flex justify-end items-center gap-x-1.5 mb-4">
                {user?.user_type !== "STUDENT" && data?.persons !== 0 && (
                  <p
                    className="cursor-pointer py-1 px-3 text-white font-normal text-small-text md:text-14 bg-primary"
                    style={{ borderRadius: 43 }}
                    onClick={() => detailSession(oneToOne, data, type)}
                  >
                    {data?.persons} Participants
                  </p>
                )}
                {data?.zoom_link !== null && user?.user_type !== "STUDENT" && (
                  <p className="text-13 md:text-16 text-right text-primary font-bold">
                    <a
                      href={data?.appointment?.zoom_link?.start_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="inline-block  cursor-pointer"
                        src={zoom}
                        alt=""
                        style={{ width: 25 }}
                      />
                    </a>
                  </p>
                )}
                {data?.zoom_link !== null && user?.user_type === "STUDENT" && (
                  <p className="text-13 md:text-16 text-right text-primary font-bold">
                    <a
                      href={data?.appointment?.zoom_link?.join_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="inline-block  cursor-pointer"
                        src={zoom}
                        alt=""
                        style={{ width: 25 }}
                      />
                    </a>
                  </p>
                )}
                {user?.user_type !== "STUDENT" && (
                  <p
                    className="text-13 md:text-16 text-right text-primary font-bold"
                    onClick={openSchedule}
                  >
                    <img
                      className="inline-block cursor-pointer"
                      src={editIcon}
                      alt=""
                    />
                  </p>
                )}
              </div>
              <div className="mt-4">
                <Button text="Add to Calendar" onClick={() => setAddCalendarModal(true)}></Button>
              </div>
              <div className="text-center">
                <StatusBadge status={status} />
              </div>
            </div>
          </div>

          {open && (
            <div className="mt-10">
              <div>
                <form className="md:flex  flex-col gap-x-6 gap-y-9">
                  <div className="flex gap-x-1.5">
                    <div className="w-3/6">
                      <label className="mb-8 md:text-body-large">Title</label>
                      <Input
                        type="text"
                        placeholder=""
                        value={title}
                        setValue={setTitle}
                      />
                    </div>
                    <div className="w-3/6">
                      <label className="mb-8 md:text-body-large">
                        Capacity
                      </label>
                      <Input
                        type="number"
                        placeholder=""
                        value={capacity}
                        setValue={setCapacity}
                      />
                    </div>
                  </div>

                  <div className="flex gap-x-1.5">
                    <div className="w-3/6">
                      <label className="mb-8 md:text-body-large">
                        Calendar
                      </label>
                      <Input
                        placeholder=""
                        type="date"
                        value={dateNew}
                        setValue={setDateNew}
                      />
                    </div>
                    <div className="w-3/6">
                      <label className="mb-8 md:text-body-large">Time</label>
                      <div className="flex justify-center">
                        <select
                          className="form-select text-input focus:bg-offWhite focus:border-none text-input-container w-fullpx-3 py-1.5 text-base font-normal text-gray-700 transitionease-in-out m-0
     "
                          aria-label="Default select example"
                          onChange={(e) => setTime(timeCheck(e.target.value))}
                        >
                          {timeArray?.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              {/* radio start  */}
              <div className="radioRow flex justify-around my-12">
                {/* {sessionStatus.map((item) => (
                  <SingleCheckbox
                    id={item.status}
                    text={item.status}
                    correct={item.correct}
                    handleChange={sessionCheck}
                  />
                ))} */}
              </div>
              {/* radio row end */}
              <div className="mt-6 flex flex-col md:flex-row items-center">
                <div className="py-6 md:pl-16" onClick={closeSchedule}>
                  <span className="font-bold text-body-large text-primary cursor-pointer">
                    Cancel
                  </span>
                </div>
                <div className="w-full flex flex-col space-y-5 md:flex-row md:items-center md:space-y-0 md:space-x-5 md:justify-end">
                  <div className="md:w-44">
                    <Button filled onClick={() => updateSessions(data)}>
                      <p className="text-15 mac:text-body-large">Confirm</p>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {type === "oneToOne" && (
        <div
          className="p-4 md:p-10 bg-white"
          style={{
            borderRadius: "14px",
            boxShadow: " 4px 8px 20px rgba(2, 23, 54, 0.03)",
          }}
        >
          <div className="flex justify-between md:flex-col sm:flex-col lg:flex-row">
            <div className="flex w-8/12	">
              <div className="text-center mr-5 md:mr-14 flex flex-col justify-center">
                <p className="text-primary text-14 md:text-body-large font-normal">
                  {one2oneMonths}
                </p>
                <p className="font-bold text-darkest text-body-large md:text-h3">
                  {one2oneDay}
                </p>
              </div>
              <div>
                <Modal
                  open={editTitleModal}
                  onClose={() => setEditTitleModal(false)}
                  center
                  classNames={{
                    modal: 'react-modal-class2', 
                   }}
                >
                  <div>
                    <Input
                      type="text"
                      placeholder=""
                      value={titleOne2One}
                      setValue={setTitleOne2One}
                    />

                    <div className="flex flex-col justify-center items-center md:justify-between md:flex-row md:space-x-5 w-full mt-4 md:mt-10">
                      
                        <div className="md:w-44 mx-auto">
                          <Button
                            filled
                            onClick={() => updateSessionOne2One(oneToOne, true)}
                          >
                            <p className="text-15 mac:text-body-large">
                              Confirm
                            </p>
                          </Button>
                        </div>
                    </div>
                  </div>
                </Modal>
                <p className="text-13 md:text-h5 font-bold text-darkest cursor-pointer flex">
                  {oneToOne?.title}
                  <span>
                    
                      <p
                        className="text-13 md:text-16 text-right text-primary font-bold ml-2"
                        onClick={editTitleUpdate}
                      >
                        <img
                          className="inline-block  cursor-pointer tutorEditIcon"
                          src={editIcon}
                          alt=""
                        />
                      </p>
                  </span>
                </p>

                {user?.user_type === "STUDENT" ? (
                  <div>
                    <div className="flex gap-x-1.5 ">
                      <p className="text-13 md:text-16 text-right text-primary font-bold">
                        {oneToOne?.availability[0]?.tutor}
                      </p>
                    </div>

                    <div className="flex gap-x-1.5 ">
                      <p className="text-13 md:text-16 text-right  font-bold greenText">
                        {oneToOne?.availability[0]?.email}
                      </p>
                    </div>
                  </div>
                ) : user?.user_type === "TEACHER" ? (
                  <div>
                    <div className="flex gap-x-1.5 ">
                      <p className="text-13 md:text-16 text-right text-primary font-bold">
                        {oneToOne?.user}
                      </p>
                    </div>

                    <div className="flex gap-x-1.5 ">
                      <p className="text-13 md:text-16 text-right  font-bold greenText">
                        {oneToOne?.email}
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <p>Start Time: {oneToOne?.created_at}</p>
                </div>
                <div>
                  <p>Timezone: {oneToOne?.time_zone}</p>
                </div>
              </div>
            </div>
            <div className="text-end">
              <div className="flex justify-end items-end gap-x-1.5 mb-4">
                {oneToOne.deactivate === false && (
                  <div className={"h-7"}>
                    <Button
                      text="Reschedule"
                      onClick={() => {
                        setDateModal(true);
                        if (oneToOne.title.includes("Free Appointment")) {
                          setFreeDate(true);
                        }
                      }}
                    ></Button>
                  </div>
                )}
                <p
                  className="paidNotSec cursor-pointer py-1 px-3 text-white font-normal text-small-text md:text-14 bg-primary"
                  style={{ borderRadius: 43 }}
                  onClick={() => {
                    if (oneToOne?.title === "Free Appointment") {
                      // setModal(true)
                      tutorSet(oneToOne);
                    } else if (
                      oneToOne?.deactivate === true &&
                      oneToOne?.paid === false
                    ) {
                      setModal(false);
                    } else {
                      // setModal(true)
                      tutorSet(oneToOne);
                    }
                  }}
                >
                  {oneToOne?.paid === false &&
                  oneToOne.title !== "Free Appointment" &&
                  oneToOne?.deactivate === false
                    ? "Not Paid"
                    : oneToOne?.paid === true
                    ? "Paid"
                    : oneToOne?.paid === false &&
                      oneToOne?.title === "Free Appointment"
                    ? "Free Appointment"
                    : oneToOne?.paid === false &&
                      oneToOne.title !== "Free Appointment" &&
                      oneToOne?.deactivate === true
                    ? "Cancelled"
                    : null}
                </p>
                {oneToOne?.zoom_link !== null &&
                user?.user_type !== "STUDENT" ? (
                  <p className="text-13 md:text-16 text-right text-primary font-bold">
                    <a
                      href={oneToOne?.zoom_link?.start_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="inline-block cursor-pointer"
                        src={zoom}
                        alt=""
                        style={{ width: 28 }}
                      />
                    </a>
                  </p>
                ) : oneToOne?.zoom_link !== null &&
                  user?.user_type === "STUDENT" &&
                  oneToOne?.paid === true ? (
                  <p className="text-13 md:text-16 text-right text-primary font-bold">
                    <a
                      href={oneToOne?.zoom_link?.join_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="inline-block  cursor-pointer"
                        src={zoom}
                        alt=""
                        style={{ width: 25 }}
                      />
                    </a>
                  </p>
                ) : oneToOne?.zoom_link !== null &&
                  user?.user_type === "STUDENT" &&
                  oneToOne?.paid === false &&
                  oneToOne.title === "Free Appointment" ? (
                  <p className="text-13 md:text-16 text-right text-primary font-bold">
                    <a
                      href={oneToOne?.zoom_link?.join_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="inline-block 
                       cursor-pointer"
                        src={zoom}
                        alt=""
                        style={{ width: 28 }}
                      />
                    </a>
                  </p>
                ) : null}
              </div>
              <div className="mt-4">
                <Button text="Add to Calendar" onClick={() => setAddCalendarModal(true)}></Button>
              </div>
            </div>
          </div>

          {openOne && (
            <div className="mt-10">
              <div>
                <form className="md:flex  flex-col gap-x-6 gap-y-9">
                  <div className="flex gap-x-1.5">
                    <div className="w-full">
                      <label className="mb-8 md:text-body-large">Title</label>
                      <Input
                        type="text"
                        placeholder=""
                        value={titleOne2One}
                        setValue={setTitleOne2One}
                      />
                    </div>
                    {/* <div className="w-3/6">
                      <label className="mb-8 md:text-body-large">Package</label>
                      <select
                        className="form-select text-input focus:bg-offWhite focus:border-none text-input-container w-fullpx-3 py-1.5 text-base font-normal text-gray-700 transitionease-in-out m-0"
                        aria-label="Default select example"
                        onChange={(e) => setPackage(e.target.value)}
                      >
                        <option
                          value={oneToOne?.package_slug}
                          selected={oneToOne?.package_name}
                          disabled={true}
                        >
                          {slugToText(oneToOne?.package_slug)}
                        </option>
                        {packageList.map((item) => {
                          return (
                            <option value={item?.slug}>{slugToText(item?.slug)}</option>
                          );
                        })}
                      </select>
                    </div> */}
                  </div>

                  <div className="flex gap-x-1.5">
                    <div className="w-3/6">
                      <label className="mb-8 md:text-body-large">Price</label>
                      <Input
                        type="text"
                        placeholder=""
                        value={priceOne2One}
                        setValue={setPriceOne2One}
                        readonly={true}
                      />
                    </div>
                    <div className="w-3/6">
                      <label className="mb-8 md:text-body-large">Date</label>
                      <Input
                        placeholder=""
                        type="date"
                        value={dateNew}
                        setValue={setDateNew}
                      />
                    </div>
                  </div>
                </form>
              </div>
              {/* <div className='mt-9 flex flex-col md:flex-row items-center md:space-x-7'>
                                <p className='md:text-body-large text-darkest'>Total number of persons:</p>
                                <div className='flex items-center space-x-6 mt-3 md:mt-0'>
                                    <button className='cursor-pointer text-body-large text-primary font-bold'
                                            onClick={reducePersons}>-
                                    </button>
                                    <p className='cursor-pointer text-body-large text-darkest'>{numOfPersons}</p>
                                    <button className='cursor-pointer text-body-large text-primary font-bold'
                                            onClick={enlargePersons}>+
                                    </button>
                                </div>
                            </div> */}
              {/* radio start  */}
              <div className="radioRow flex  justify-around my-12">
                {/* {sessionStatus.map((item) => (
                  <SingleCheckbox
                    id={item.status}
                    text={item.status}
                    correct={item.correct}
                    handleChange={sessionCheck}
                  />
                ))} */}
              </div>
              {/* radio row end */}
              <div className="mt-6 flex flex-col md:flex-row items-center">
                <div className="py-6 md:pl-16" onClick={closeScheduleOnetoOne}>
                  <span className="font-bold text-body-large text-primary cursor-pointer">
                    Cancel
                  </span>
                </div>
                <div className="w-full flex flex-col space-y-5 md:flex-row md:items-center md:space-y-0 md:space-x-5 md:justify-end">
                  {/* <div>
                                        <Button onClick={book}>
                                            <p className='text-bold text-darkest text-15 px-2 mac:text-body-large mac:px-6'>Book
                                                one to one tutorials</p>
                                        </Button>
                                    </div> */}
                  <div className="md:w-44">
                    <Button
                      filled
                      onClick={() => updateSessionOne2One(oneToOne)}
                    >
                      <p className="text-15 mac:text-body-large">Confirm</p>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  update: tutorSessionUpdateData(state),
  one2oneUpdate: tutorOne2OneUpdateData(state),
  participants: groupParticipantsData(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateSession: (body, slug) => dispatch(tutorSessionUpdate(body, slug)),
  updateTutorOne2One: (body, slug) =>
    dispatch(tutorSessionOneToOneUpdate(body, slug)),
    
  getGroupParticipants: (slug, date) =>
    dispatch(getGroupParticipants(slug, date)),
    getTutorAvailability: (slug, date) => dispatch(getTutorAvailability(slug, date))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TutorSessionStudents);
