import React, { useEffect, useState } from "react";
import "./index.css";
import { GetAllTimeSlots } from "../../services";
import Container from "../../components/container";
import {
  createSlotsData,
  createSlotsLoading,
  deleteHolidaysData,
  getHolidaysData,
  postHolidaysData,
} from "../../redux/selectors";
import { connect, useDispatch } from "react-redux";
import { createSlots } from "../../redux/actions/createSlotsAction";
import {
  createErrorAlert,
  deleteHolidays,
  getHolidays,
  postHolidaysAction,
} from "../../redux/actions";
import FullLoader from "../../components/Loader/FullLoader";
import { Helmet } from "react-helmet";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays } from "date-fns";
import DateRangeSelect from "./DateRangeSelect";

let obj = {
  tutor: "",
  sunday: [],
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
};
const TimeSlots = (props) => {
  const [res, setRes] = useState([]);
  const [timeArray, setTimeArray] = useState([]);
  const dispatch = useDispatch()
  const [body, setBody] = useState({
    tutor: "",
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  });
  const {
    createSlots,
    getHolidays,
    postHoliday,
    deleteHolidays,
    getHolidaysData,
    createSlotsLoading,
  } = props;

  const [loading, setLoading] = useState(true);
  const [maxDate, setMaxDate] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  const [subStatus, setSubStatus] = useState("upcoming");
  const [dateValue, setDateValue] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11)
});
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const user = JSON.parse(sessionStorage.getItem("app_user"));
  useEffect(() => {
    GetAllTimeSlots().then((response) => {
      let obj = [];
      delete response.data["tutor"];
      setLoading(false);
      setRes([response.data]);
      if (response?.data?.sunday?.length !== 0) {
        // eslint-disable-next-line array-callback-return
        response?.data?.sunday?.map((item) => {
          obj.push(item?.slot?.slot);
        });
      }
      setTimeArray(obj);
    });
  }, []);
  useEffect(() => {
    getHolidays(user?.username);
    if (window.location.pathname === "/time-slots") {
      var dtToday = new Date();
      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();
      if (month < 10) month = "0" + month.toString();
      if (day < 10) day = "0" + day.toString();

      let dates = year + "-" + month + "-" + day;
      setMaxDate(dates);
    }
  }, []);

  const createSlot = () => {
    const updatedBody = { ...body, tutor: user?.username };
    createSlots(updatedBody)
    GetAllTimeSlots().then((response) => {
      setRes([])
      let obj = [];
      delete response.data["tutor"];
      setLoading(false);
      setRes([response.data]);
      if (response?.data?.sunday?.length !== 0) {
        // eslint-disable-next-line array-callback-return
        response?.data?.sunday?.map((item) => {
          obj.push(item?.slot?.slot);
        });
      }
      setTimeArray(obj);
    });
    window.location.reload()
  };

  const getCalendarDate = (param) => {
    let obj = {
      date: param,
    };
    postHoliday(obj);
    setTimeout(() => {
      getHolidays(user?.username);
    }, 1000);
  };

  const deleteHoliday = (id) => {
    deleteHolidays(id);
    getHolidays(user?.username);
  };

  function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  const callHoliday = (start, end) => {
      let obj = {
        date: [
          start.getFullYear() +
            "-" +
            (start.getMonth() + 1) +
            "-" +
            start.getDate(),
          end.getFullYear() +
            "-" +
            (end.getMonth() + 1) +
            "-" +
            end.getDate(),
        ],
      };
      dispatch(postHolidaysAction(obj)).then((res) => {
        if(res.status === 201){
          dispatch(createErrorAlert(201, '', 'Holiday have been created', 201))
          
        } else if(res === 400){
          dispatch(createErrorAlert(400, '', 'You have already added Holiday for this date', 400))
        }
        setTimeout(() => {
          getHolidays(user?.username);
          setShowCalendar(false);
        }, 3000);
      })
  };

  const handleSelect = (start) => {
    callHoliday(start.start._d, start.end._d);
  };

  const handleChange = (day, value, checked) => {
    const updatedBody = { ...body };
  
    if (!updatedBody[day].includes(value.id)) {
      updatedBody[day].push(value.id);
    } else {
      const index = updatedBody[day].indexOf(value.id);
      if (index !== -1) {
        updatedBody[day].splice(index, 1);
      }
    }
  
    updatedBody[day].forEach(subItem => {
      if (subItem.id === value.id) {
        subItem.slot.status = checked;
      }
    });
  
    setBody(updatedBody);
    createSlot()
  };
  
  const dateCheck = (item) => {
    let holidayDate = new Date(item.date);
    let now = new Date();
    if (holidayDate.getMonth() === now.getMonth() && holidayDate.getDate() === now.getDate()) {
      return 'upcoming';
    }
    if (holidayDate < now) {
        return 'past';
    } if (holidayDate > now) {
        return 'upcoming';
    }
  };

  return (
    <>
      {loading || createSlotsLoading ? (
        <FullLoader />
      ) : (
        <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat custom2:pb-20">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Simple Studying | Time Slots</title>
            <meta name="description" content="Get High Grades with the support of top graduates who took the same courses as you" />
            <link
              rel="canonical"
              href="https://simplestudying.com/time-slots"
            />
            <meta name="robots" content="index, follow" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:locale:alternate" content="en_gb" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Simple Studying | Study Tips" />
            <meta property="og:description" content="Get High Grades with the support of top graduates who took the same courses as you" />
            <meta
              property="og:url"
              content="https://simplestudying.com/time-slots"
            />
            <meta property="og:site_name" content="Simple Studying" />
          </Helmet>
          <div className="">
            <Container>
              <div className="timeSlotHead text-darkest flex-col md:flex-row justify-between w-full">
                <div className="tSlotHeadLeft">
                  <p className="text-h3  font-bold mb-10 custom2:mb-8 ">
                    Holidays
                  </p>
                  <p className="text-body-large">Please select Holidays</p>
                </div>

                <div
                  className="tsShowCalSec"
                  title="Click For Calendar"
                  onClick={() => {
                    if (showCalendar === false) {
                      setShowCalendar(true);
                    } else {
                      setShowCalendar(false);
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <p> Show Calendar</p>
                  <div className="tsCalSubSec top-0 left-24 pl-2 pointer-events-none">
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4 text-gray-500 dark:text-gray-400 calndrSvg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  {/* <input className="holidayCal" type="date" min={maxDate}
                                       onChange={(e) => getCalendarDate(e.target.value)}/> */}
                  
                </div>
                {showCalendar ? (
                    <DateRangeSelect onChange={handleSelect} maxDate={maxDate} value={dateValue} />
                   ) : null}
              </div>
              <br></br>
              {getHolidaysData?.length > 0 ? <div id="myTabContent" className="userContSec">
                <div
                  className="mb-4 border-b "
                  style={{ borderColor: "#fff", position: "relative" }}
                >
                  <ul
                    className="tagsRow flex flex-wrap -mb-px text-sm font-medium text-center"
                    id="myTab"
                    data-tabs-toggle="#myTabContent"
                    role="tablist"
                  >
                    <li className="mr-2" role="presentation">
                      <button
                        className={`rounded-none inline-block px-4 py-2 ${
                          subStatus === "upcoming"
                            ? " bg-primary text-white"
                            : " bg-gray text-black"
                        }`}
                        id="Past"
                        data-tabs-target="#Past"
                        type="button"
                        role="tab"
                        onClick={() => {
                          setSubStatus("upcoming");
                        }}
                        aria-controls="Past"
                        aria-selected="false"
                      >
                        Upcoming
                      </button>
                    </li>
                    <li className="mr-2" role="presentation">
                      <button
                        className={`rounded-none inline-block px-4 py-2 ${
                          subStatus === "past"
                            ? " bg-primary text-white"
                            : " bg-gray text-black"
                        }`}
                        id="dashboard-tab"
                        data-tabs-target="#dashboard"
                        type="button"
                        role="tab"
                        onClick={() => {
                          setSubStatus("past");
                        }}
                        aria-controls="dashboard"
                        aria-selected="false"
                      >
                        Past
                      </button>
                    </li>
                  </ul>
                </div>
                {subStatus === "upcoming" ? (
                  <div id="myTabContent" className="userContSec">
                    <div
                      className="py-4 bg-gray-50 rounded-lg dark:bg-gray-800"
                      id="Past"
                      role="tabpanel"
                      aria-labelledby="Past-tab"
                    >
                      <div
                        className="space-y-3.5 max-h-custom overflow-y-auto px-3.5 "
                        id="session-container"
                      >
                        <div
                          className={`${
                            subStatus === "past" && "hidden "
                          } py-4 bg-gray-50 rounded-lg dark:bg-gray-800`}
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          <div
                            className="space-y-3.5 max-h-custom px-3.5 "
                            id="session-container"
                          >
                            <ul className="holidayList">
                              {getHolidaysData.length > 0
                                ? getHolidaysData?.map((item) => {
                                    if (dateCheck(item) === "upcoming") {
                                      return (
                                        <li>
                                          <span>
                                            Holiday on <b>{item?.date}</b>
                                          </span>
                                          <button
                                            onClick={() =>
                                              deleteHoliday(item.id)
                                            }
                                          >
                                            x
                                          </button>
                                        </li>
                                      );
                                    } else{
                                        return(
                                            ""
                                        )
                                    }
                                  })
                                : ""}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : subStatus === "past" ? (
                  <div
                    className="py-4 bg-gray-50 rounded-lg dark:bg-gray-800"
                    id="upComing"
                    role="tabpanel"
                    aria-labelledby="upComing-tab"
                  >
                    <div
                      className="space-y-3.5 max-h-custom overflow-y-auto px-3.5 "
                      id="session-container"
                    >
                      <div
                        className={`${
                          subStatus === "upmcoming" && "hidden "
                        } py-4 bg-gray-50 rounded-lg dark:bg-gray-800`}
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <div
                          className="space-y-3.5 max-h-custom px-3.5 "
                          id="session-container"
                        >
                          <ul className="holidayList">
                            {getHolidaysData.length > 0
                              ? getHolidaysData?.map((item) => {
                                  if (dateCheck(item) === "past") {
                                    return (
                                      <li>
                                        <span>
                                          Holiday on <b>{item?.date}</b>
                                        </span>
                                        <button
                                          onClick={() => deleteHoliday(item.id)}
                                        >
                                          x
                                        </button>
                                      </li>
                                    );
                                  } else{
                                    return("")
                                  }
                                })
                              : ""}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div> : null}
              

              <div className="pt-4 md:pt-8 lg:pt-12 flex mb-10">
                <div className="text-darkest flex justify-between w-full">
                  <div>
                    <p className="bookTimeText text-h3  font-bold mb-10 custom2:mb-8 ">
                      Book Time Slots
                    </p>
                    <p className="availSlotText text-body-large">
                      Please select availability slots
                    </p>
                  </div>
                  <div className="text-center">
                    
                  </div>
                </div>
              </div>
              <>
                <div className="tsTableMain table-responsive tutorTable">
                  <table className="table table-curved">
                    <tbody>
                      {res.map((item) =>
                        Object.keys(item).map((key) => (
                          <tr>
                            <th>{capitalizeFirstLetter(key)}</th>
                            {item[key]?.map((subItem) => (
                              <td>
                                <label className={"slotCheck"}>
                                  <input
                                    type="checkbox"
                                    value={subItem.id}
                                    checked={subItem.slot.status}
                                    onChange={(e) =>
                                      handleChange(
                                        key,
                                        subItem,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <span>{key === "sunday"}</span>
                                </label>
                              </td>
                            ))}
                          </tr>
                        ))
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th></th>
                        {timeArray?.map((item) => (
                          <th>{item}</th>
                        ))}
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </>
            </Container>
          </div>
        </div>
      )}{" "}
    </>
  );
};
const mapStateToProps = (state) => ({
  data: createSlotsData(state),
  createSlotsLoading: createSlotsLoading(state),
  getHolidaysData: getHolidaysData(state),
  postHolidaysData: postHolidaysData(state),
  deleteHolidaysData: deleteHolidaysData(state),
});

const mapDispatchToProps = (dispatch) => ({
  createSlots: (body) => dispatch(createSlots(body)),
  getHolidays: (user) => dispatch(getHolidays(user)),
  postHoliday: (obj) => dispatch(postHolidaysAction(obj)),
  deleteHolidays: (id) => dispatch(deleteHolidays(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlots);
