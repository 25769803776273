/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import FreeUsers from './FreeUsers';
import Container from '../../components/container';
import Breadcrumb from '../../components/BreadCrumb';
import {getApiKey, slugToText} from "../../helpers";
import PaidUserNotes from './PaidUserNotes';
import Button from "../../components/EmptyButton";
import ReportModal from '../../components/Modal/ReportModal';


const PreviewNotes = ({match, location}) => {
    const slug1 = match.params.slug1;
    const slug2 = match.params.slug2;

    const breadCrumbItem = [{title: 'Get study modules', link: '/'}, {
        title: slugToText(slug1),
        link: `/study-module/${slug1}`,
    }, {title: 'Notes', link: `/${slug1}/notes`}];

    const [open, setOpen] = useState(false)

    return (
        <div className="bg-white  pl-[100px] pr-[100px]   " style={{paddingLeft: '100px', paddingRight: '100px'}}>
            <Container>
                {getApiKey() &&slug2 && !location?.state?.selected?.label ? <div className="hidden md:block md:pt-12 flex space-x-4 w-full bd-show">
                    <Breadcrumb items={breadCrumbItem} current={slugToText(slug2)}/>
                    <div className="w-48 flex mr-0">
                        <Button text={'Report'} className={'mr-0'} onClick={() => {
                            setOpen(!open)
                        }}></Button>
                    </div>
                </div> : null}
                

            </Container>
            {match.params.type === 'free' ? (
                <FreeUsers data={location?.state?.selected} slug={slug1}/>
            ) : (
                <PaidUserNotes data={location?.state?.selected} slug={slug2} studymodule={slug1}/>
            )}
            <ReportModal open={open} setOpen={setOpen} slug={slug2} content_type={'note'}/>
        </div>
    );
};

export default PreviewNotes;
