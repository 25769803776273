import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import "./modal.css";
import { apiRequest, slugToText } from "../../helpers";
import Button from "../../components/EmptyButton";
import { createErrorAlert } from "../../redux/actions";
import { useDispatch } from "react-redux";


const ReportModal = ({ open, setOpen, slug, content_type }) => {
  const [checkedValue, setCheckedValue] = useState("");
  const [textReport, setTextReport] = useState('')
  const [checkedText, setCheckedText] = useState("");

  const dispatch = useDispatch()


  const handleCheckboxChange = (isChecked, id, value) => {
    if (isChecked) {
      setCheckedText(value.parentElement.innerText)
      setCheckedValue(id);
    } else {
      setCheckedText(value.parentElement.innerText)
      setCheckedValue("");
    }
  };

  const reportContent = async () => {
    try{
        await apiRequest({
            url: 'accounts/report/',
            method: 'post',
            data: {
                reason: checkedText,
                detail: textReport,
                content: slug,
                object_id: 5,
                content_type: content_type
            }
        }).then((res) => {
            setOpen(!open)
            dispatch(createErrorAlert(200, '', 'Thank you for letting us know. Our team will carefully review your report and take necessary action', 200))
        })
    } catch(err) {
        console.log(err)
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        focusTrapped={false}
        center
        //    classNames={{modal: 'xl:w-650'}}

        classNames={{
          overlay: "custom-overlay-class",
          modal: "react-modal-class2",
        }}
      >
        <div>
          <p className="text-darkest text-center text-large md:text-h5 font-bold mb-5">
            Why you are reporting this content?
          </p>
          <div className="flex flex-row flex-wrap w-full space-y-4">
            <label className="condContainer" htmlFor="condBox1">
              This content violates intellectual property rights
              <input
                id="condBox1"
                name="reason"
                type="checkbox"
                checked={checkedValue === "condBox1"}
                onChange={(e) =>
                  handleCheckboxChange(e.target.checked, "condBox1", e.target)
                }
                className="default:ring-2 text-primary mr-3 checkBoxOne"
              />
              <span className="checkmark"></span>
            </label>

            <label className="condContainer" htmlFor="condBox2">
              This content contains obscene or otherwise illegal content
              <input
                id="condBox2"
                name="reason"
                type="checkbox"
                checked={checkedValue === "condBox2"}
                onChange={(e) =>
                  handleCheckboxChange(e.target.checked, "condBox2", e.target)
                }
                className="default:ring-2 text-primary mr-3 checkBoxOne"
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <textarea rows={8} onChange={(e) => {
            setTextReport(e.target.value)
          }} className="w-full mt-16 p-4 border-2 border-primary rounded-lg" placeholder="Enter your text here..."></textarea>
            <div className="flex items-center justify-center w-48" style={{marginLeft: '35%'}}>
                <Button text="Submit" className={'mt-4'} onClick={reportContent}></Button>
            </div>
        </div>
      </Modal>
    </>
  );
};

export default ReportModal;
