/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router-dom";
import Container from "../../components/container";
import Select from "../../components/Select";
import Slider from "../../components/MainAdminSlider";
import StudyMaterials from "../../components/StudyModules/StudyMaterials";
import InfoImg from "../../assets/icons/information.svg";
import {
  createErrorAlert,
  getCatalogue,
  getDegrees,
  getSpecificStudyModules,
} from "../../redux/actions";
import {
  catalogueData,
  degreesData,
  studyModulesData,
} from "../../redux/selectors";
import { Helmet } from "react-helmet";
import StudyCard from "../../components/StudyCard";
import _ from "lodash";
import InfoBox from "../../components/InfoBox";
import Button from "../../components/EmptyButton";
import { apiRequest, getUser } from "../../helpers";
import findImg from "../../assets/newImages/Group 1406.png";
import { FaArrowRight } from "react-icons/fa";
import bgIMG from "../../";
import Context from "../../services/Context";
const MainAdminPage = (props) => {
  const history = useHistory();
  const user = JSON.parse(getUser());
  const dispatch = useDispatch();

  const { studyModulesData, getStudyModules, dataCatalogue, getCatalogue } =
    props;
  const [showInfo, setShowInfo] = useState(false);
  const [module, setModule] = useState([]);
  const [recom, setRecom] = useState([]);
  const [userData, setUserData] = useState(JSON.parse(getUser()));
  const [paidModules, setPaidModules] = useState([]);

  const { value } = useContext(Context);
  const userCurrency = value?.userCurrency || userData?.user_currency;
  const userIsLaw = value?.is_law || userData?.is_law;


  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    if (sessionStorage.getItem("stripRedirect")) {
      sessionStorage.removeItem("stripRedirect");
    }
    if (
      localStorage.getItem("events") &&
      !window.location.href.includes("appointments_create")
    ) {
      localStorage.removeItem("events");
    }
    if (value.studyModulesData) {
      if (value.studyModulesData.length > 0) {
        let recom = [];
        value.studyModulesData.map((item) => {
          if (
            item.slug !== "macroeconomics" &&
            item.slug !== "microeconomics" &&
            item.slug !== "business" &&
            item.slug !== "marketing" &&
            item.slug !== "our-company" &&
            item.slug !== "useful-articles" &&
            item.slug !== "uncategorized" &&
            item.slug !== "test" &&
            item.slug !== "partners"
          ) {
            recom.push({
              value: item,
              label: item.title,
            });
          }
        });
        // eslint-disable-next-line no-undef
        let sortedd = _.sortBy(recom, "label");
        setModule(sortedd);
        let arr = [];
        let filter = recom.slice(0, 10);
        filter.forEach((e) => {
          if (
            e.value.slug !== "macroeconomics" &&
            e.value.slug !== "microeconomics" &&
            e.value.slug !== "business" &&
            e.value.slug !== "marketing" &&
            e.value.slug !== "our-company" &&
            e.value.slug !== "useful-articles" &&
            e.value.slug !== "uncategorized" &&
            e.value.slug !== "test" &&
            e.value.slug !== "partners"
          ) {
            arr.push(e);
            arr.sort();
          }
        });
        // eslint-disable-next-line no-undef
        let sortedTen = _.sortBy(arr, "label");
        for (let i = 0; i < sortedTen.length; i++) {
          sortedData.push(sortedTen[i]);
        }
        setRecom(filter);
      }
    }
  }, [value.studyModulesData]);

  useEffect(() => {
    new window.carouselInlineWidget("reviewsio-carousel-widget", {
      /*Your REVIEWS.io account ID:*/
      store: "www.simplestudying.com",
      sku: "",
      lang: "en",
      carousel_type: "default",
      styles_carousel: "CarouselWidget--sideHeader",

      /*Widget settings:*/
      options: {
        general: {
          /*What reviews should the widget display? Available options: company, product, third_party. You can choose one type or multiple separated by comma.*/
          review_type: "company, product",
          /*Minimum number of reviews required for widget to be displayed*/
          min_reviews: "1",
          /*Maximum number of reviews to include in the carousel widget.*/
          max_reviews: "20",
          address_format: "CITY, COUNTRY",
          /*Carousel auto-scrolling speed. 3000 = 3 seconds. If you want to disable auto-scroll, set this value to false.*/
          enable_auto_scroll: 10000,
        },
        header: {
          /*Show overall rating stars*/
          enable_overall_stars: true,
          rating_decimal_places: 2,
        },
        reviews: {
          /*Show customer name*/
          enable_customer_name: true,
          /*Show customer location*/
          enable_customer_location: true,
          /*Show "verified review" badge*/
          enable_verified_badge: true,
          /*Show "verified subscriber" badge*/
          enable_subscriber_badge: true,
          /*Show "I recommend this product" badge (Only for product reviews)*/
          enable_recommends_badge: true,
          /*Show photos attached to reviews*/
          enable_photos: true,
          /*Show videos attached to reviews*/
          enable_videos: true,
          /*Show when review was written*/
          enable_review_date: true,
          /*Hide reviews written by the same customer (This may occur when customer reviews multiple products)*/
          disable_same_customer: true,
          /*Minimum star rating*/
          min_review_percent: 4,
          /*Show 3rd party review source*/
          third_party_source: true,
          /*Hide reviews without comments (still shows if review has a photo)*/
          hide_empty_reviews: true,
          /*Show product name*/
          enable_product_name: true,
          /*Show only reviews which have specific tags (multiple semicolon separated tags allowed i.e tag1;tag2)*/
          tags: "",
          /*Show branch, only one input*/
          branch: "",
          enable_branch_name: false,
        },
        popups: {
          /*Make review items clickable (When they are clicked, a popup appears with more information about a customer and review)*/
          enable_review_popups: true,
          /*Show "was this review helpful" buttons*/
          enable_helpful_buttons: true,
          /*Show how many times review was upvoted as helpful*/
          enable_helpful_count: true,
          /*Show share buttons*/
          enable_share_buttons: true,
        },
      },
      translations: {
        verified_customer: "Verified Customer",
      },
      styles: {
        /*Base font size is a reference size for all text elements. When base value gets changed, all TextHeading and TexBody elements get proportionally adjusted.*/
        "--base-font-size": "16px",
        "--base-maxwidth": "100%",

        /*Logo styles:*/
        "--reviewsio-logo-style": "var(--logo-normal)",

        /*Star styles:*/
        "--common-star-color": " #0E1311",
        "--common-star-disabled-color": " rgba(0,0,0,0.25)",
        "--medium-star-size": " 22px",
        "--small-star-size": "19px" /*Modal*/,
        "--x-small-star-size": "16px",
        "--x-small-star-display": "inline-flex",

        /*Header styles:*/
        "--header-order": "1",
        "--header-width": "160px",
        "--header-bg-start-color": "transparent",
        "--header-bg-end-color": "transparent",
        "--header-gradient-direction": "135deg",
        "--header-padding": "0.5em",
        "--header-border-width": "0px",
        "--header-border-color": "rgba(0,0,0,0.1)",
        "--header-border-radius": "0px",
        "--header-shadow-size": "0px",
        "--header-shadow-color": "rgba(0, 0, 0, 0.1)",

        /*Header content styles:*/
        "--header-star-color": "inherit",
        "--header-disabled-star-color": "inherit",
        "--header-heading-text-color": "inherit",
        "--header-heading-font-size": "inherit",
        "--header-heading-font-weight": "inherit",
        "--header-heading-line-height": "inherit",
        "--header-heading-text-transform": "inherit",
        "--header-subheading-text-color": "inherit",
        "--header-subheading-font-size": "inherit",
        "--header-subheading-font-weight": "inherit",
        "--header-subheading-line-height": "inherit",
        "--header-subheading-text-transform": "inherit",

        /*Review item styles:*/
        "--item-maximum-columns": "5" /*Must be 3 or larger*/,
        "--item-background-start-color": "transparent",
        "--item-background-end-color": "transparent",
        "--item-gradient-direction": "135deg",
        "--item-padding": "0.5em",
        "--item-border-width": "0px",
        "--item-border-color": "rgba(0,0,0,0.1)",
        "--item-border-radius": "0px",
        "--item-shadow-size": "0px",
        "--item-shadow-color": "rgba(0,0,0,0.1)",

        /*Heading styles:*/
        "--heading-text-color": " #0E1311",
        "--heading-text-font-weight": " 600",
        "--heading-text-font-family": " inherit",
        "--heading-text-line-height": " 1.4",
        "--heading-text-letter-spacing": "0",
        "--heading-text-transform": "none",

        /*Body text styles:*/
        "--body-text-color": " #0E1311",
        "--body-text-font-weight": "400",
        "--body-text-font-family": " inherit",
        "--body-text-line-height": " 1.4",
        "--body-text-letter-spacing": "0",
        "--body-text-transform": "none",

        /*Scroll button styles:*/
        "--scroll-button-icon-color": "#0E1311",
        "--scroll-button-icon-size": "24px",
        "--scroll-button-bg-color": "transparent",

        "--scroll-button-border-width": "0px",
        "--scroll-button-border-color": "rgba(0,0,0,0.1)",

        "--scroll-button-border-radius": "60px",
        "--scroll-button-shadow-size": "0px",
        "--scroll-button-shadow-color": "rgba(0,0,0,0.1)",
        "--scroll-button-horizontal-position": "0px",
        "--scroll-button-vertical-position": "0px",

        /*Badge styles:*/
        "--badge-icon-color": "#0E1311",
        "--badge-icon-font-size": "15px",
        "--badge-text-color": "#0E1311",
        "--badge-text-font-size": "inherit",
        "--badge-text-letter-spacing": "inherit",
        "--badge-text-transform": "inherit",

        /*Author styles:*/
        "--author-font-size": "inherit",
        "--author-font-weight": "inherit",
        "--author-text-transform": "inherit",

        /*Product photo or review photo styles:*/
        "--photo-video-thumbnail-size": "60px",
        "--photo-video-thumbnail-border-radius": "0px",

        /*Popup styles:*/
        "--popup-backdrop-color": "rgba(0,0,0,0.75)",
        "--popup-color": "#ffffff",
        "--popup-star-color": "inherit",
        "--popup-disabled-star-color": "inherit",
        "--popup-heading-text-color": "inherit",
        "--popup-body-text-color": "inherit",
        "--popup-badge-icon-color": "inherit",
        "--popup-badge-icon-font-size": "19px",
        "--popup-badge-text-color": "inherit",
        "--popup-badge-text-font-size": "14px",
        "--popup-border-width": "0px",
        "--popup-border-color": "rgba(0,0,0,0.1)",
        "--popup-border-radius": "0px",
        "--popup-shadow-size": "0px",
        "--popup-shadow-color": "rgba(0,0,0,0.1)",
        "--popup-icon-color": "#0E1311",

        /*Tooltip styles:*/
        "--tooltip-bg-color": "#0E1311",
        "--tooltip-text-color": "#ffffff",
      },
    });
  }, []);

  useEffect(() => {
    for (let i = 0; i < value?.dataCatalogue?.length; i++) {
      if (value?.dataCatalogue[i]?.is_paid === true) {
        if (
          !paidModules.includes(value?.dataCatalogue[i]?.study_module?.slug)
        ) {
          paidModules.push(value?.dataCatalogue[i]?.study_module?.slug);
        }
      }
    }
  }, [value.dataCatalogue]);

  const slugName = (selected) => {
    if (selected.value) {
      localStorage.setItem("study-module", selected.value.slug);
      localStorage.setItem("study-module_id", selected.value.id);
    } else {
      localStorage.setItem("study-module", selected.slug);
      localStorage.setItem("study-module_id", selected.id);
    }
    history.push({
      pathname: `/study-module/${selected.value.slug}`,
      state: { selected },
    });
  };

  const handleSelectedModule = (selected) => {
    localStorage.setItem("study-module", selected.value.slug);
    localStorage.setItem("study-module_id", selected.value.id);
    history.push({
      pathname: `/study-module/${selected.value.slug}`,
      state: { selected },
    });
  };

  const addToBasket = async (item) => {
    let obj = {
      study_module: item.value.id,
    };
    await apiRequest({
      method: "post",
      url: "content/basket/",
      data: obj,
    })
      .then((res) => {
        if (res.status === 201) {
          history.push("/shopping-basket");
        }
      })
      .catch((err) => {
        if(err.response.data[0] === "Routed"){
          history.push("/shopping-basket");
        } else{
          dispatch(createErrorAlert(400, "", err.response.data[0], 400));
        }
      });
  };

  return (
    <div className="bg-white bg-main-admin-back bg-no-repeat">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Simple Studying | Study Modules</title>
        <meta name="description" content="Get High Grades with the support of top graduates who took the same courses as you" />
        <link rel="canonical" href="https://simplestudying.com/" />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_gb" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Simple Studying | Study Modules" />
        <meta property="og:description" content="Get High Grades with the support of top graduates who took the same courses as you" />
        <meta property="og:url" content="https://simplestudying.com/" />
        <meta property="og:site_name" content="Simple Studying" />
      </Helmet>
      <Container>
        <div className="flex flex-col md:flex-row mb-5">
          <div className="flex-1" style={{ flex: "40%", flexBasis: "40%" }}>
            <div className="py-8 flex items-center custom:pt-16 custom:pb-11">
              <div className=" flex items-center relative">
                <img
                  src={InfoImg}
                  alt="info-img"
                  className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                  onMouseEnter={() => setShowInfo(true)}
                  onMouseLeave={() => setShowInfo(false)}
                />
                {showInfo && (
                  <div className=" tooltipSSec absolute z-10 top-10 h-1080">
                    <InfoBox text={userCurrency === "UK" && userIsLaw === true ? "Please choose the study module from the list below or type the name of the study module in the search bar to search faster e.g. land law. Our team of successful law graduates from different universities of England recently were in your position and achieved high 2.1 or First Class in their respective law degrees. We summarised and simplified the overcomplicated information for you from our experience and focused on what you need to know to get a First Class. We created simple notes with exam tips, case summaries, sample essays, tutorial videos, quizzes and flashcards all specifically designed for you to get a First Class in the simplest way possible. " : "Please select a study module from the list below, or type the module's name in the search dropdown. <br/> <br/> Our content is created by top university students and graduates who achieved high grades in their exams. We carefully vet our content creators and study materials to provide you with high-quality content, maximizing your chances of achieving top grades."} />
                  </div>
                )}
                <p className="font-bold text-14 sm:text-14 md:text-h4 custom:text-h3 ">
                  Choose Study Module below:
                </p>
              </div>
              {/* {user?.subscription_name === 'Basic Subscription' && user?.free_trial === false && <div className="hidden custom:block flex-grow">
             <div className="custom:w-64 xl:w-340 float-right -mt-10">
                 <Button filled onClick={() => history.push('/subscription')}>
                     <p className="custom:text-15 xl:text-body-large text-center px-5">Get access to all
                         study modules <br className="custom:hidden xl:block"/> for £7.5/month</p>
                 </Button>
             </div>
          </div>} */}
            </div>
            <div className="flex flex-col md:flex-row md:space-x-7">
              {/*<div className='md:w-56 lg:w-80'>*/}
              {/*    <Select*/}
              {/*        placeholder="University"*/}
              {/*        values={universitiesList}*/}
              {/*    />*/}
              {/*</div>*/}
              <div className="md:w-56 lg:w-80">
                <Select
                  placeholder="Study Module"
                  values={module}
                  handleSelected={handleSelectedModule}
                />
              </div>
            </div>
            <div className="py-5">
              <p className="text-darkGrey text-14">
                Neither our company, nor our products are officially affiliated
                <br className="hidden md:block" /> with any of the universities
                listed{" "}
              </p>
              <br />
              {paidModules.length > 0 && (
                <p className="text-darkGrey text-14">
                  You can access the study modules you are enrolled in at{" "}
                  <span
                    className="text-primary cursor-pointer"
                    onClick={() => history.push("/library")}
                  >
                    Your Library
                  </span>
                </p>
              )}
            </div>
          </div>
          {userIsLaw === true && userCurrency === "UK" && (
            <div className="flex-1" style={{ flex: "70%", flexBasis: "70%" }}>
              <div
                className="flex flex-1 mt-14 bg-white p-6 rounded-2xl"
                style={{ flex: "50%", flexBasis: "50%" }}
              >
                <div
                  className="flex-1 "
                  style={{ flex: "30%", flexBasis: "30%" }}
                >
                  <img src={findImg} />
                </div>
                <p className="text-primary fw-semibold mb-2">
                  Can't find the study materials you're looking for here?
                </p>
                <ul className=" list-disc">
                  <li>
                    If you can't locate the notes or other study materials you
                    need on our platform, or if you prefer them in a different
                    style, don't worry! We've got you covered.{" "}
                  </li>
                  <li>
                    Simply send us all your relevant lecture and reading
                    materials, and we'll craft personalized study materials just
                    for you, exactly how you want them, tailored to your needs.
                  </li>
                </ul>
                <div className="w-full">
                  <button
                    onClick={() => history.push("/grab-detail")}
                    className="
              flex items-center fw-semibold
              text-primary text-14 text-center m-auto p-2 mt-2 border-primary border-2 hover:text-white hover:bg-primary"
                  >
                    Grab your tailor-made study materials now! <FaArrowRight />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="pb-5 custom:pb-14">
          <p className="font-bold text-body-large custom:text-h4">
            Recommended study materials:
          </p>
        </div>

        <div
          className="materialSlider pb-4 md:pb-14 xl:pb-20"
          style={{ cursor: "pointer" }}
        >
          {sortedData.length > 0 ? (
            <Slider
              responsive={{
                superLargeDesktop: {
                  autoPlay: true,
                  // the naming can be any, depends on you.
                  breakpoint: { max: 4000, min: 1920 },
                  items: 4,
                },
                desktop: {
                  breakpoint: { max: 1919, min: 1500 },
                  items: 3,
                },
                ipadPro: {
                  breakpoint: { max: 1501, min: 1201 },
                  items: 3,
                },
                tablet: {
                  breakpoint: { max: 993, min: 1199 },
                  items: 2,
                },
                tabletBelow: {
                  breakpoint: { max: 991, min: 768 },
                  items: 1,
                },
                mobile: {
                  breakpoint: { max: 767, min: 575 },
                  items: 1,
                },
              }}
            >
              {sortedData?.map((item, index) => {
                return (
                  <div class="flip-card" onClick={() => slugName(item)}>
                    <div class="flip-card-inner">
                      <div class="flip-card-front">
                        <StudyMaterials
                          key={index}
                          image={item?.value.image}
                          cardTitle={item?.label}
                          slug={item}
                          text={item?.value?.short_description}
                          handleClick={slugName}
                          price={`${
                            !paidModules.includes(item?.value?.slug) && item.value.currency === 'usd'
                              ? "$" : '£'
                          } ${
                            paidModules.includes(item?.value?.slug) &&
                            item?.value?.price
                              ? "Enrolled"
                              : !paidModules.includes(item?.value?.slug) &&
                                item?.value?.price && item?.value?.price >= 1000
                              ? item?.value?.price / 100
                              : item?.value?.price
                          }`}
                        />
                      </div>
                      <div class="flip-card-back p-4 flex flex-col">
                        <p
                          className="font-bold text-lg"
                          style={{ fontSize: "22px" }}
                        >
                          {item.label}
                        </p>
                        <div className={`flex my-5 flex-wrap gap-3 flex-grow`}>
                          {item?.value?.notes !== 0 ? (
                            <p
                              className="py-1 px-2 text-greenGradient font-normal text-08 border-2 border-green-500"
                              style={{ borderRadius: "43px" }}
                            >
                              Revision Notes
                            </p>
                          ) : null}
                          {item?.value?.essays !== 0 ? (
                            <p
                              className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                              style={{ borderRadius: "43px" }}
                            >
                              Essays
                            </p>
                          ) : null}
                          {item?.value?.summaries !== 0 ? (
                            <p
                              className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                              style={{ borderRadius: "43px" }}
                            >
                              Case Summaries
                            </p>
                          ) : null}
                          {item?.value?.quiz !== 0 ? (
                            <p
                              className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                              style={{ borderRadius: "43px" }}
                            >
                              Quiz
                            </p>
                          ) : null}
                          {item?.value?.flash_cards !== 0 ? (
                            <p
                              className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                              style={{ borderRadius: "43px" }}
                            >
                              Flash Cards
                            </p>
                          ) : null}
                          {item?.value?.tutorial_videos !== 0 ? (
                            <p
                              className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                              style={{ borderRadius: "43px" }}
                            >
                              Tutorial Videos
                            </p>
                          ) : null}
                        </div>
                        <div className="m-3 mt-auto flex-shrink-0">
                          <Button
                            filled
                            text={
                              userCurrency === "UK"
                                ? "Add to Basket"
                                : "Add to Cart"
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              addToBasket(item);
                            }}
                          ></Button>
                        </div>
                        <div
                          className="contentColor flex-grow mb-7"
                          dangerouslySetInnerHTML={{
                            __html: item?.value?.short_description,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          ) : (
            <>
              <p>
                Unfortunately we don't have content for your degree yet. You can
                upload your study notes if you got good grades in any of your
                subjects. We'll sell your study notes for you and pay you to
                supplement your income.
              </p>
              <div className="items-center justify-center w-96">
                <Button
                  className={"mt-20"}
                  filled
                  text={"SELL YOUR STUDY NOTES NOW"}
                  onClick={(e) => {
                    history.push("/content-creator/step-zero");
                  }}
                ></Button>
              </div>
            </>
          )}
        </div>
        {/* <div>
          <p className="text-body-large font-bold pb-5 custom:pb-12 md:text-h4">
            Buy study materials from your university students who got a{" "}
            <span className="text-greenGradient">1st Class:</span>
          </p>
        </div>
        <div
          className="materialSlider pb-4 md:pb-14 xl:pb-20"
          style={{ cursor: "pointer" }}
        >
          <Slider
            responsive={{
              superLargeDesktop: {
                autoPlay: false,
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 1919 },
                items: 4,
              },
              desktop: {
                breakpoint: { max: 1919, min: 1500 },
                items: 4,
              },
              ipadPro: {
                breakpoint: { max: 1500, min: 1024 },
                items: 3,
              },
              tablet: {
                breakpoint: { max: 1023, min: 768 },
                items: 3,
              },
              tabletBelow: {
                breakpoint: { max: 767, min: 576 },
                items: 2,
              },
              mobile: {
                breakpoint: { max: 575, min: 320 },
                items: 1,
              },
            }}
          >
            {sortedData?.map((item, index) => {
              return (
                <div class="flip-card">
                  <StudyCard
                    image={item?.value?.image}
                    cardTitle={item?.label}
                    cardDescription={item?.value?.degree?.university?.name}
                    price={
                      item?.value?.price !== null ? item?.value?.price : "£ 99.99"
                    }
                    classNum="2.1 Class"
                  />
                </div>
              );
            })}
          </Slider>
        </div>
        <div className=" xl:mt-24">
          <p className="text-body-large font-bold pb-5 custom:pb-12 md:text-h4">
            Buy study materials from your university students who got a{" "}
            <span className="text-greenGradient">2:1 Class:</span>
          </p>
        </div>
        <div
          className="materialSlider pb-4 md:pb-14 xl:pb-20"
          style={{ cursor: "pointer" }}
        >
          <Slider
            responsive={{
              superLargeDesktop: {
                autoPlay: false,
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 1919 },
                items: 4,
              },
              desktop: {
                breakpoint: { max: 1919, min: 1500 },
                items: 4,
              },
              ipadPro: {
                breakpoint: { max: 1500, min: 1024 },
                items: 3,
              },
              tablet: {
                breakpoint: { max: 1023, min: 768 },
                items: 3,
              },
              tabletBelow: {
                breakpoint: { max: 767, min: 576 },
                items: 2,
              },
              mobile: {
                breakpoint: { max: 575, min: 320 },
                items: 1,
              },
            }}
          >
            {sortedData?.map((item, index) => {
              return (
                <div class="flip-card">
                  <StudyCard
                    image={item?.value?.image}
                    cardTitle={item?.label}
                    cardDescription={item?.value?.degree?.university?.name}
                    price={
                      item?.value?.price !== null ? item?.value?.price : "£ 99.99"
                    }
                    classNum="2.1 Class"
                  />
                </div>
              );
            })}
          </Slider>
        </div> */}
        <div className="mt-20" id="reviewsio-carousel-widget"></div>
      </Container>

      <div className="md:pb-12 bg-white  bg-subscription-bottom bg-no-repeat bg-bottom px-">
        <Container>
          {user?.subscription_name === "Basic Subscription" &&
            user?.free_trial === false && user?.is_law === true && userCurrency === "UK" && (
              <div className="flex flex-col items-center text-center pt-16 ">
                <p className="font-bold text-h3">
                  Get access to all the study <br /> modules
                </p>
                <div className="w-96 mt-12 lg:pb-20 pb-10 px-10 md:px-0">
                  <Button filled onClick={() => history.push("/subscription")}>
                    <p>BECOME A PREMIUM MEMBER</p>
                  </Button>
                </div>
              </div>
            )}
        </Container>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  studyModulesData: studyModulesData(state),
  dataCatalogue: catalogueData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getStudyModules: (degree) => dispatch(getSpecificStudyModules(degree)),
  getCatalogue: (id) => dispatch(getCatalogue(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MainAdminPage);
