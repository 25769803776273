import React, { useContext, useEffect } from "react";
import nini from "../../assets/images/nini.jpg";
import mariam from "../../assets/images/mariam.png";
import defaultImg from "../../assets/images/defaultDp.png";
import Context from "../../services/Context";
const TutorCard = ({ dataItem, isSelected, onSelect }) => {
  const { value, updateValue } = useContext(Context);

  useEffect(() => {}, [dataItem]);

  const handleSelectTutor = () => {
    onSelect((prev) => ({
      ...prev,
      selectedTutor: dataItem,
    }))
    updateValue((prev) => ({...prev, dataItem: dataItem, selectedSlots: []}))
  }
  return (
    <div
      className={`flex lg:w-47% w-full border-0 p-2 gap-3 mb-3 bg-white cursor-pointer ${
        isSelected ? "border-4 border-borderGreen" : ""
      }`}
      style={{
        borderRadius: "25px",
        boxShadow: "rgba(2, 23, 54, 0.1) 4px 8px 20px",
      }}
      onClick={() =>
        handleSelectTutor()
      }
    >
      <div className=" flex flex-col justify-center items-center text-center mr-8 ">
        <div style={{ width: "150px", height: "150px", padding: "30px" }}>
          <img
            src={dataItem?.image ? dataItem?.image.replace('https://backend.simplestudying.com', 'https://backend.studycrowd.ai') : defaultImg}
            className="w-full h-full"
            style={{ borderRadius: "50%" }}
          />
        </div>
        <h3 className="fw-bold" style={{ fontSize: "22px" }}>
          {dataItem?.name.split(".")[0]}
        </h3>
        <p className="w-fit text-14">{dataItem?.designation}</p>
        <p className="w-36 text-14">{dataItem?.education}</p>
      </div>
      <div className="flex flex-col text-left margin-auto justify-center">
        {dataItem?.work_experience !== "" && (
          <div>
            <h5 className="text-primary text-body-large">Work Experience:</h5>
            <p>{dataItem?.work_experience}</p>
          </div>
        )}
        {dataItem?.description !== "" && (
          <div>
            <h5 className="text-primary text-body-large">Description:</h5>
            <p>{dataItem?.description}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TutorCard;
