import React, {useEffect, useState} from 'react'
import {Helmet} from "react-helmet";
import {
    privacyLoading,
    privacyError,
    privacyData,
  } from "../../redux/selectors";
  import { getPrivacy } from "../../redux/actions";
  import { connect } from "react-redux";
  import {GetPrivacyPolicy} from '../../services'
import FullLoader from '../../components/Loader/FullLoader';


const PrivacyPolicy = () => {

    const [resp, setRes] = useState([])
    const [lastUpdate, setLastUpdate] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        window.scrollTo({top: 0, left: 0});
    },[])

    useEffect(() => {
        GetPrivacyPolicy().then((res) => {
            setRes(res.data)
            setLoading(false)
            let updateArray = []
            for(let i=0; i<res.data.length; i++) {
                let date = new Date(res.data[i].updated_at).toISOString().split('T')[0];
                updateArray.push(date)
            }
            const currentDate = new Date();
            const differences = updateArray.map(date => {
            const pastDate = new Date(date);
            return Math.abs(currentDate - pastDate);
            });

            const minDifferenceIndex = differences.indexOf(Math.min(...differences));
            const nearestPastDate = updateArray[minDifferenceIndex];

            setLastUpdate(nearestPastDate)

        })
    },[loading])

    return (
        <>
        {loading ? <FullLoader /> : <div className="mt-10 bg-white" style={{scrollBehavior: 'smooth'}}>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Simple Studying | Privacy Policy</title>
                    <meta name="description" content="Get High Grades with the support of top graduates who took the same courses as you"/>
                    <link rel="canonical" href="https://simplestudying.com/privacy-policy"/>
                    <meta name="robots" content="index, follow"/>
                    <meta property="og:locale" content="en_US"/>
                    <meta property="og:locale:alternate" content="en_gb"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Simple Studying | Privacy Policy"/>
                    <meta property="og:description" content="Get High Grades with the support of top graduates who took the same courses as you"/>
                    <meta property="og:url" content="https://simplestudying.com/privacy-policy"/>
                    <meta property="og:site_name" content="Simple Studying"/>
                </Helmet>
                <div className=" px-4 md:px-6 lg:px-8 bg-offWhite custom2:px-56 custom:px-56 ">
                    <div className="termCondText text-large-small font-bold text-darkest md:text-large-medium">
                        <h2>Privacy Policy</h2>
                    </div>
                    <div className='cookiesPolicy'>
                        <p className='policyPara'>Last Updated {new Date(lastUpdate).getDate()} {new Date(lastUpdate).toLocaleString('en-US', { month: 'short' })}, {new Date(lastUpdate).getFullYear()}</p>
                    </div>
                    <div className='privacyTableContent'>
                        <p>Table of Contents</p>
                    </div>
                    <div  className='termCondOlSec'>
                        <ol  style={{cursor: 'pointer', fontSize: '20px', marginTop: '10px'}}>
                            {resp?.length > 0 ? resp?.map((item) => {
                                return(
                                    <li><a href={`#${item.slug}`}>{item.title}</a></li>
                                )
                            }) : null}
                        </ol>
                    </div>
                    {resp?.length > 0 ? resp.map((item) => {
                        return(
                            <section id={item.slug}>
                                <p className='introText' style={{fontSize: '24px', fontWeight: 'bold', marginTop: '40px'}}>{item.title}</p>
                                <p className='policyPara' dangerouslySetInnerHTML={{__html: item?.content}}></p>
                            </section>
                        )
                    }): null}
                </div>
            </div>}
            
        </>
    )

};

const mapStateToProps = (state) => ({
    loading: privacyLoading(state),
    error: privacyError(state),
    data: privacyData(state),
  });
  const mapDispatchToProps = {
    getPrivacy,
  };

  export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);