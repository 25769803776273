import React, { useEffect, useState } from "react";

import Container from "../../components/container";
import Stepper from "../../components/ContentCreatorStepper";
import FacebookIcon from "../../assets/images/facebookIcon.png";
import TwitterIcon from "../../assets/images/twitterIcon.png";
import Video1Image from "../../assets/images/video4.webp";
import Video2Image from "../../assets/images/video2.webp";
import Video3Image from "../../assets/images/video3.webp";
import PlayButton from "../../assets/images/playButtonPaid.png";
import LazyImage from "../../components/LazyImage";

import { useHistory, useLocation } from "react-router-dom";
import Button from "../../components/EmptyButton";
import { apiRequest } from "../../helpers";
const StudyMaterials = ({ data }) => {

  const dateConvert = () => {
    if(data.created_at){
        let date = new Date(data.created_at)
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`
    }
  }

  const handleVideoClick = () => {
    window.open(data?.url, "_blank")
  }

  return (
    <div
      className="bg-white md:w-50 lg:w-72 custom2:w-472 cursor-pointer"
      onClick={() => handleVideoClick()}
      style={{
        boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.03)",
        borderRadius: "25px",
      }}
    >
      <div className="relative">
        <LazyImage src={data.image} alt="image" />
        <img
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-70 custom2:w-100"
          src={PlayButton}
          alt="imagefb"
        />
      </div>
      <div className="p-6">
        <p className="font-bold md:text-h5 xl:text-h4 mb-9">{data.title}</p>
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <img className="mr-4 md:mr-6" src={FacebookIcon} alt="imagefb" />
            <img src={TwitterIcon} alt="imagefb" />
          </div>
          <p>{dateConvert()}</p>
        </div>
      </div>
    </div>
  );
};

const StepTwo = () => {
  const history = useHistory();
  const location = useLocation()

  const [video, setVideo] = useState([]);

  useEffect(() => {
    handleVideoApi();
  }, []);

  const handleVideoApi = async () => {
    await apiRequest({
      method: "get",
      url: "content/step_video/",
    }).then((res) => {
      setVideo(res.data);
    });
  };

  return (
    <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat">
      <Stepper step={2} exit={() => history.push('/')} totalSteps={4}/>
      <Container>
        <div className="pt-10 lg:pt-14 flex">
          <div>
            <p className="text-h5 md:text-h4 custom2:text-36 font-bold">
              Welcome to your content creator portal!
            </p>
          </div>
        </div>
        <div className="pt-8">
          <p className="text-20 font-bold">HOW TO CREATE CONTENT AT STUDYCROWD.AI</p>
          <br/>
          <p>As a self-employed content creator, you make the decisions! However, we are going to give you guidelines that we recommend you take into consideration to maximize your earnings. We have designed a system after years of testing and data analysis. Our system works both for content creators to maximize earnings and for students to get high grades. So, you should follow our guidelines as much as possible to achieve success.</p>
          <br />
          <p><span className="text-20 font-bold">Overall Objective</span> - As a successful student or graduate yourself, we would like you to create study materials for students to share your knowledge and experience. Teach students how you got high grades. Students at universities get thousands of pages to read in overcomplicated books. We want to cut that and reduce it to 10 pages per topic, if not less. Summarize all the reading materials and simplify them for students as much as possible.</p>
          <br />
          <p><span className="text-20 font-bold">IMPORTANT NOTICE: </span>All the study materials you provide must be written by you only. Otherwise, you can be liable for breaching the intellectual property rights of the rightful owner of the content, which can lead to you being sued and liable for paying damages. Check out the terms and conditions for more information. <a style={{color: "blue"}} href="https://studycrowd.ai/terms-conditions-content">https://studycrowd.ai/terms-conditions-content</a></p>
        </div>
        <div className="flex md:space-x-3 custom2:space-x-8 flex-col md:flex-row pt-4 md:pt-10 ">
          {video.length > 0 &&
            video.map((item, index) => {
              return (
                <div className="pt-10 md:pt-0 w-full md:w-1/3 flex  justify-center">
                  <StudyMaterials data={item} />
                </div>
              );
            })}
          {/* <div className="pt-10 md:pt-0 w-full md:w-1/3 flex justify-center ">
            <StudyMaterials
              image={Video2Image}
              cardTitle="How to maximise your earnings?"
            />
          </div>
          <div className="pt-10 md:pt-0 w-full md:w-1/3 flex justify-center">
            <StudyMaterials
              image={Video3Image}
              cardTitle="How to do marketing?"
            />
          </div> */}
        </div>
        <div className="py-20">
          <div className="flex flex-col items-center md:flex-row justify-center md:px-20">
            <div className=" flex w-2/3">
              <p className="w-full text-20 font-bold text-right px-1 m-auto ">
                If you watched the videos please{" "}
              </p>
              <Button
                onClick={() => history.push({pathname:"/content-creator/step-three", state:{module: location?.state?.module ? location.state.module : ''}})}
                filled
                className="text-center text-14 xl:text-body-large"
                text={"Proceed with your onboarding"}
              ></Button>
            </div>
          </div>
        </div>
        {/* msg icon sec  */}

        {/* msg icon sec // */}
      </Container>
    </div>
  );
};

export default StepTwo;
