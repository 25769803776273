import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';
import Button from '../../components/EmptyButton';
import Stepper from '../../components/SignUpStepper';
import Select from '../../components/Select';
import Modal from '../../components/SignUpModal/StepThreeModal';
import {createErrorAlert, getDegrees, getSpecificStudyModules, updateUser} from '../../redux/actions';
import {degreesData, studyModulesData, updatedUserLoader} from '../../redux/selectors';
import { apiRequest, getUser } from '../../helpers';

const SignUpStepThree = (props) => {
    const {getDegrees, degreeData, studyModulesData, getSpecificStudyModules, updateUser, updatedUserLoader} = props;
    const history = useHistory();
    const dispatch = useDispatch()
    const user = JSON.parse(getUser())
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDegree, setSelectedDegree] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [degreesList, setDegreesList] = useState([]);
    const [studyModules, setStudyModules] = useState([]);
    const [studyYear, setStudyYear] = useState([{value: 1, label: '1'}, {value: 2, label: '2'}, {
        value: 3,
        label: '3',
    }, {value: 4, label: '4'}, {value: 5, label: '5'}]);
    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflowY = 'hidden';
            document.body.style.position = 'fixed';
        } else {
            document.body.style.overflowY = 'unset';
            document.body.style.position = 'unset';
        }
        
    }, [isModalOpen]);
    useEffect(() => {
        handleGetDegree()
    }, []);

    const handleGetDegree = async () => {
        await apiRequest({
          method: "get",
          url: `studies/degrees/`,
        }).then((res) => {
          let values = [];
          res.data.map((item) => {
            values.push({
              value: item,
              label: item.name,
            });
          });
          setDegreesList(values)
        });
      };

    useEffect(() => {
        if (degreeData.length > 0) {
            let degrees = [];
            degreeData.map((item) => {
                degrees.push({
                    value: item.name, label: item.name, item: item,
                });
            });
            setDegreesList(degrees);
        }
    }, [degreeData, studyModulesData]);
    const handleSelected = async (selectedOption) => {
        await apiRequest({
            method: 'get',
            url: `studies/degrees/`
        }).then((res) => {
            let list = []
            res.data.map((item) => {
                list.push({
                    text: item.title, correct: false, item: item,
                })
            })
            setStudyModules(list)
        })
        setSelectedDegree(selectedOption);
    };
    const handleYearSelected = (selectedOption) => {
        setSelectedYear(selectedOption);
    };
    let newObj = [];
    const handleChange = (value, checked) => {
        for (let i = 0; i < studyModules.length; i++) {
            if (studyModules[i].text === value) {
                studyModules[i].checked = checked;
                if (studyModules[i].checked === true) {
                    newObj.push(studyModules[i].item.id);
                } else {
                    newObj.filter(res => res !== studyModules[i].item.id);
                }
            }
        }
        
        setStudyModules(studyModules);
    };
    const handleUpdate = () => {
        let obj = {
            degrees_ids: [parseInt(selectedDegree.item.id)], 
            study_modules_ids: newObj,
            year: selectedYear.value,
            email: user.email,
            username: user?.username
        };
        updateUser(obj);
        setIsModalOpen(false)
        // history.push('/');
    };

    const handleContinue = () => {
        let obj = {
            degrees_ids: [parseInt(selectedDegree.value.id)], 
            year: selectedYear.value,
            email: user?.email,
            username: user.username,
            is_law: selectedDegree.value.name.includes('Law') ? true : false
        };
        updateUser(obj);
        history.push('/')
    }

    return (<div className="bg-white bg-main-page-back w-full bg-no-repeat bg-cover">
            <Stepper step={3} exit={() => history.push('/')} totalSteps={3}/>
            <Modal
                degrees={studyModules}
                isOpen={isModalOpen}
                buttonOneText={studyModules.length > 0 ? "Select" : null}
                loading={updatedUserLoader}
                handleChange={handleChange}
                handleClick={() => {
                    if(newObj.length === 0 && studyModules.length > 0){
                        dispatch(createErrorAlert(400, '', 'Please select a study module you want to learn', 400))
                    } else{
                        handleUpdate()
                    }
                }}
                onClickFirstButton={() => setIsModalOpen(false)}
                onClickSecondButton={() => setIsModalOpen(false)}
                closeModal={() => setIsModalOpen(false)}
                subtitle={studyModules.length > 0 ? `${studyModules.length} modules` : "No study module against this degree"}
                title="Which study module do you want to learn?"/>
            <div className="px-4 md:px-36 custom2:px-0">
                
                <p className="mt-10 md:mt-28 custom2:mt-36 mb-14 font-bold text-h4 md:text-large-medium custom2:text-36 text-center text-darkest">Which
                    {user?.user_currency === "US" ? " Major" : " Degree"}</p>
                <div className="pb-11 flex flex-col justify-center items-center">
                    {/*<div className="w-full md:w-472">*/}
                    {/*    <p className="mb-8 text-darkest font-body-large">University</p>*/}
                    {/*    <Select*/}
                    {/*        placeholder="Add university"*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className="w-full md:w-472">
                        <p className="mb-8 text-darkest font-body-large">Degree</p>
                        <Select
                            placeholder="Add degree"
                            values={degreesList}
                            value={selectedDegree}
                            handleSelected={handleSelected}
                        />
                    </div>
                    <div className="w-full md:w-472">
                        <p className="mb-8 text-darkest font-body-large">Year of study</p>
                        <Select
                            placeholder="Add year"
                            values={studyYear}
                            handleSelected={handleYearSelected}
                        />
                    </div>
                    <div className="w-full pt-4 flex justify-center mb-20 mt-12">
                        <div className="w-full md:px-0 md:w-40 h-51">
                            <Button onClick={() => handleContinue()} disabled={!(selectedDegree && selectedYear)}
                                    text="Continue" filled/></div>
                    </div>
                </div>
            
            </div>
        </div>
    
    );
};
const mapStateToProps = state => ({
    degreeData: degreesData(state),
    studyModulesData: studyModulesData(state),
    updatedUserLoader: updatedUserLoader(state),
});
const mapDispatchToProps = dispatch => ({
    getDegrees: (university) => dispatch(getDegrees(university)),
    getSpecificStudyModules: (degree) => dispatch(getSpecificStudyModules(degree)),
    updateUser: body => dispatch(updateUser(body)),
    
});
export default connect(mapStateToProps, mapDispatchToProps)(SignUpStepThree);
