import React, { useState, useEffect, Fragment, useContext } from "react";

import Container from "../../components/container";
import Stepper from "../../components/ContentCreatorStepper";
import MoreIcon from "../../assets/images/moreIcon.png";
import AddIcon from "../../assets/images/addIcon.webp";
import Button from "../../components/EmptyButton";
import ModalUpload from "../../components/Modal/UploadDocument";
import InfoImg from "../../assets/icons/information.svg";
import ModalName from "../../components/Modal/DocumentName";
import {
  createErrorAlert,
  createModuleAction,
  createNoteAction,
  deleteContentEssayAction,
  deleteContentModuleAction,
  deleteContentNoteAction,
  getContentNoteAction,
  updateEssayAction,
  updateModuleAction,
  updateNoteAction,
} from "../../redux/actions";
import { useHistory, useLocation } from "react-router-dom";
import {
  createEssayData,
  createEssayError,
  createModuleData,
  createModuleError,
  createNoteData,
  createNoteError,
  createNoteLoading,
  deleteContentEssayData,
  deleteContentEssayError,
  deleteContentModuleError,
  deleteContentNoteData,
  deleteContentNoteError,
  getContentEssayData,
  getContentNoteData,
  submitContentModuleData,
} from "../../redux/selectors";
import { connect, useDispatch } from "react-redux";
import { apiRequest, getUser, slugToText } from "../../helpers";
import Input from "../../components/Input/InputForm";
import Dropzone from "react-dropzone";
import RemoveIcon from "../../assets/images/removeIcon.webp";
import UploadIcon from "../../assets/images/uploadDocumentIcon.png";
import { Modal } from "react-responsive-modal";
import { Menu, Transition } from "@headlessui/react";
import convertTextToSlug from "../../helpers/textToSlug";
import FullLoader from "../../components/Loader/FullLoader";
import { createEssayAction } from "../../redux/actions/createEssayAction";
import { getContentEssayAction } from "../../redux/actions/getContentEssayAction";
import { submitContentModuleAction } from "../../redux/actions/submitContentModuleAction";
import { FaArrowRight } from "react-icons/fa";
import InfoBox from "../../components/InfoBox/SmallInfobox";
import Context from "../../services/Context";

const CustomRadioButton = ({ text, onClick, state, checked }) => {
  const trueKey = Object.keys(state).find((key) => state[key]);

  return (
    <label
      className={`flex justify-between p-3.5 md:px-5 md:py-6 hover:bg-lightGray ${checked ? 'border-2 border-white bg-backgroundPrimary' : ""} cursor-pointer ${trueKey ? (state[trueKey] ? "bg-grey" : "bg-white") : "bg-white"
        }`}
      style={{ borderRadius: "14px", display: "flex", alignItems: "center", }}
    // onClick={() => onClick(text)}
    >
      <input
        type="radio"
        // onChange={(e) => onClick(e.target.value)}
        value={text}
        name="module"
        style={{ opacity: 0, position: "absolute", }}
      />
      <span className="ml-1.5 md:ml-3.5 text-darkest font-normal text-16 md:text-body-large">
        {text} {text === "Pricing" ? <i style={{fontSize: '13px'}}>*mandatory</i> : text === "Pictures" ? 
        <i style={{fontSize: '13px'}}>*optional</i> : text === "Study Module" ? 
        <i style={{fontSize: '13px'}}>*mandatory</i> : text === "Notes" ? 
        <i style={{fontSize: '13px'}}>*mandatory</i> : text === "Example Questions and Answers" ? 
        <i style={{fontSize: '13px'}}>*optional</i> : ''
      }
      </span>
      {checked && <>
        <input id="condBox" className="default:ring-2 text-primary mr-3 stepsCheckbox checkBoxOne" type="checkbox" checked={checked} readOnly />
        <span className="checkmarkSteps"></span>
      </>}
    </label>
  );
};

const StepThree = (props) => {
  const {
    createNote,
    createEssay,
    createNoteError,
    createEssayError,
    createModule,
    createModuleError,
    createNoteLoading,
    getContentEssay,
    getContentEssayData,
    createModuleData,
    getContentNote,
    getContentNoteData,
    deleteContentNote,
    deleteContentNoteError,
    deleteContentEssayError,
    deleteContentEssay,
    deleteContentModule,
    updateNote,
    updateEssay,
    updateModule,
    submitReview,
  } = props;

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { value } = useContext(Context)

  const savedModule = JSON.parse(localStorage.getItem("content-module"));
  const price = savedModule ? savedModule?.price : "";
  const user = JSON.parse(getUser());

  const [state, setState] = useState({
    notes: [],
    essay: [],
    modules: [],
    title: "",
    studyModuleTitle: typeof user?.author !== 'string' ? savedModule?.title : "",
    readonly: false,
    pdf: null,
    noteId: null,
    moduleId: typeof user?.author !== 'string' ? savedModule?.id : null,
    essayId: null,
    editDocument: false,
    isModalOpen: false,
    files: typeof user?.author !== 'string' ? [savedModule?.image] : [],
    loader: false,
    errorStateTitle: false,
    moduleDisable: savedModule.id ? true : false,
    degree: JSON.parse(localStorage.getItem('degree-selected')),
    user: JSON.parse(getUser()),
    tabs: [
      "Pricing",
      "Pictures",
      "Study Module",
      "Notes",
      "Example Questions and Answers",
    ],
    isNameModalOpen: false,
    tabState: {
      notes: false,
      essay: false,
      modules: false,
      pricing: true,
      pictures: false,
    },
    checkedState: {
      notes: false,
      essay: false,
      modules: false,
      pricing: false,
      pictures: false,
    },
    showInfo: false,
    selectedModule: savedModule,
    price: price,
    newCreated: null,
  });

  const [uploadedFiles, setUploadedFiles] = useState([]);


  const userCurrency = value?.userCurrency || state?.user?.user_currency;


  //   const [libraryModal, setLibraryModal] = useState(false);
  //   const [selectedImage, setSelectedImage] = useState(null);
  //   const [items, setItems] = useState([]);

  useEffect(() => {
    if (state.isModalOpen || state.isNameModalOpen) {
      document.body.style.overflowY = "hidden";
      document.body.style.position = "fixed";
    } else if (!state.isModalOpen && !state.isNameModalOpen) {
      document.body.style.overflowY = "unset";
      document.body.style.position = "unset";
    }
  }, [state.isModalOpen, state.isNameModalOpen]);

  useEffect(() => {
    getContentNote(state.selectedModule?.slug);
    handleGetModules();
    getContentEssay(state.selectedModule?.slug);
  }, []);

  useEffect(() => {
    if (
      createNoteError.status === 500 ||
      createEssayError.status === 500 ||
      createModuleError.status === 500 ||
      deleteContentNoteError.status === 500 ||
      deleteContentEssayError.status === 500
    ) {
      if (createModuleError.status === 500 && createModuleError.data.message.includes('price')) {
        dispatch(createErrorAlert(400, "", "Please add price for this study module", 400));
      } else {
        dispatch(createErrorAlert(400, "", "We already have notes or model essays on the topic you entered. You can only upload study materials that we don't have on the platform.", 400));
      }
      setState((prev) => ({
        ...prev,
        readonly: false,
        moduleDisable: false
      }))
    }
  }, [
    createNoteError,
    createEssayError,
    createModuleError,
    deleteContentNoteError,
    deleteContentEssayError,
  ]);

  useEffect(() => {
    if (getContentEssayData.data) {
      setState((prevState) => ({
        ...prevState,
        essay: getContentEssayData.data,
      }));
    }
    if (getContentNoteData.data) {
      setState((prevState) => ({
        ...prevState,
        notes: getContentNoteData.data,
      }));
    }
  }, [getContentEssayData, getContentNoteData]);

  const handleGetModules = async () => {
    let array = [];
    await apiRequest({
      method: 'get',
      url: 'studies/all/studymodules/'
    }).then((res) => {
      res?.data?.map((item) => {
        if (
          item.slug !== "macroeconomics" &&
          item.slug !== "microeconomics" &&
          item.slug !== "business" &&
          item.slug !== "marketing" &&
          item.slug !== "our-company" &&
          item.slug !== "useful-articles" &&
          item.slug !== "uncategorized" &&
          item.slug !== "test" &&
          item.slug !== "partners"
        ) {
          if (item.author === state.user.author) {
            array.push(item);
          }
        }
      });
    })
    setState((prev) => ({
      ...prev,
      modules: array,
      newCreated: parseInt(localStorage.getItem("studyModuleId")),
    }));
  };

  const handleTab = (item) => {
    if (item === "Notes" && state.moduleDisable === false) {
      dispatch(createErrorAlert(400, '', 'Kindly add title for the Study Module', 400))
      return
    }
    setState((prevState) => {
      const currentTab = Object.keys(prevState.tabState).find((key) => prevState.tabState[key]);
      const transformedItem = item === "Study Modules" ? "modules" : item === "Example Questions and Answers" ? "essay" : item.toLowerCase().replace(/\s+/g, '');

      const newCheckedState = { ...prevState.checkedState };
      if (currentTab) {
        newCheckedState[currentTab] = true;
      }

      const newState = {
        ...prevState,
        tabState: {
          notes: false,
          essay: false,
          modules: false,
          pricing: false,
          pictures: false,
          [transformedItem]: true,
        },
        checkedState: newCheckedState,
      };
      return newState;
    });
  };


  const deleteFile = (name) => {
    let uploadedFiles = [...state.files];

    uploadedFiles.forEach((elem, index) => {
      if (elem.name === name) {
        uploadedFiles.splice(index, 1);
      }
    });

    setState((prevState) => ({
      ...prevState,
      files: [...uploadedFiles],
    }));
  };

  const onDrop = (filesNow) => {
    if(filesNow[0].size > 10485760){
      dispatch(createErrorAlert(400, '', 'Upload Failed: The file size exceeds the 10MB limit. Please upload a smaller file or reduce the file size before trying again.', 400))
      return
    }
    setState((prevState) => ({
      ...prevState,
      pdf: filesNow[0],
    }));
    let alreadyUploaded = false;
    let uploadedFiles = [...state.files];
    for (let i = 0; i < filesNow.length; i++) {
      const file = {
        name: filesNow[i]?.name,
        file: filesNow[i],
        preview: URL.createObjectURL(filesNow[i]),
      };
      uploadedFiles.forEach((elem) => {
        if (elem.name === filesNow[i]?.name) {
          alreadyUploaded = true;
        }
      });
      if (!alreadyUploaded) {
        uploadedFiles.push(file);
      }
    }

    setState((prevState) => ({
      ...prevState,
      files: [...uploadedFiles],
    }));
  };

  const onDropModuleImage = (filesNow) => {
    if (filesNow[0].size > 10485760) {
      dispatch(createErrorAlert(400, '', 'Upload Failed: The file size exceeds the 10MB limit. Please upload a smaller file or reduce the file size before trying again.', 400));
      return;
    }  
    // Update the state with the new files
    setState((prevState) => ({
      ...prevState,
      files: filesNow,
    }));
  
    const newUploadedFiles = [...uploadedFiles]; // Create a copy of the uploaded files array
  
    for (let i = 0; i < filesNow.length; i++) {
      const file = {
        name: filesNow[i]?.name,
        file: filesNow[i],
        preview: URL.createObjectURL(filesNow[i]),
      };
  
      // Check if the file is already uploaded
      const alreadyUploaded = newUploadedFiles.some((elem) => elem.name === filesNow[i]?.name);
  
      if (!alreadyUploaded) {
        newUploadedFiles.push(file); // Add the new file to the copied array
      }
    }
  
    // Now set the updated uploadedFiles state
    setUploadedFiles(newUploadedFiles); 
  };
  

  const downloadPdf = async (item, value) => {
    if (value === "notes") {
      try {
        const pdfUrl = item?.pdf_file;
        const response = await fetch(pdfUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${item.title}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } catch (error) {
        console.error("Error downloading PDF:", error);
      }
    } else {
      try {
        const pdfUrl = item?.document;
        const response = await fetch(pdfUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${item.title}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } catch (error) {
        console.error("Error downloading PDF:", error);
      }
    }
  };

  //   const handleLibrary = () => {
  //     setLibraryModal(true);
  //   };

  const handleDelete = async (id) => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    await deleteContentModule(id);
    setState((prev) => ({
      ...prev,
      modules: [],
      loading: false,
    }));
    handleGetModules();
    dispatch(createErrorAlert(200, "", "Study Module Successfully Deleted"));
    setTimeout(() => {
      history.push("/content-creator/step-one");
    }, 2000);
  };

  const handleUpdateDocument = async (key) => {
    setState((prev) => ({
      ...prev,
      loading: true,
      loader: true,
    }));
    if (key === "notes") {
      const formData = new FormData();
      if (state.pdf !== null) {
        formData.append("pdf_file", state.pdf);
      }
      formData.append("title", state.title);
      await updateNote(state.noteId, formData);
      setState((prevState) => ({
        ...prevState,
        editDocument: false,
        notes: [],
        title: "",
        pdf: null,
        files: [],
        price: "",
        loader: false,
      }));
      if (typeof state.selectedModule !== "string") {
        await getContentNote(state.selectedModule.slug);
      } else {
        let foundModule = state.modules.find(
          (item) => item.id === parseInt(localStorage.getItem("studyModuleId"))
        );
        await getContentNote(foundModule.slug);
      }
    } else if (key === "essay") {
      const formData = new FormData();
      if (state.pdf !== null) {
        formData.append("document", state.pdf);
      }
      formData.append("title", state.title);
      await updateEssay(state.essayId, formData);
      setState((prevState) => ({
        ...prevState,
        editDocument: false,
        essay: [],
        title: "",
        pdf: null,
        files: [],
        price: "",
        loader: false,
      }));
      if (typeof state.selectedModule !== "string") {
        await getContentEssay(state.selectedModule.slug);
      } else {
        let foundModule = state.modules.find(
          (item) => item.id === parseInt(localStorage.getItem("studyModuleId"))
        );
        await getContentEssay(foundModule.slug);
      }
    } else if (key === "module") {
      const formData = new FormData();
      formData.append("title", state.studyModuleTitle);
      if (state.price !== "") {
        formData.append("price", state.price);
      }

      await updateModule(state.moduleId, formData);
      setState((prev) => ({
        ...prev,
        editDocument: false,
        title: null,
        moduleId: null,
        files: [],
        loader: false,
      }));
      await handleGetModules();
    }
    setState((prev) => ({
      ...prev,
      loading: false,
    }));
  };

  const handleAdd = async (value) => {
    setState((prev) => ({
      ...prev,
      loading: true,
      loader: true,
      errorStateTitle: false
    }));
    if (value === "notes") {
      const formData = new FormData();
      let dataToAppend;
      if (typeof state.selectedModule !== "string") {
        dataToAppend = {
          pdf_file: state.pdf,
          title: state.title,
          author: state.user.author,
          study_module: state.selectedModule.id,
        };
      } else {
        dataToAppend = {
          pdf_file: state.pdf,
          title: state.title,
          author: state.user.author,
          study_module: parseInt(localStorage.getItem("studyModuleId")),
        };
      }
      Object.entries(dataToAppend).forEach(([key, value]) => {
        formData.append(key, value);
      });
      await createNote(formData);
      setState((prevState) => ({
        ...prevState,
        isModalOpen: false,
        notes: [],
        title: "",
        pdf: null,
        files: [],
        loading: false,
        loader: false,
      }));
      if (typeof state.selectedModule !== "string") {
        await getContentNote(state.selectedModule.slug);
      } else if (state.modules.length > 0) {
        let foundModule = state.modules.find(
          (item) => item.id === parseInt(localStorage.getItem("studyModuleId"))
        );
        await getContentNote(foundModule.slug);
      } else {
        await getContentNote(convertTextToSlug(state.studyModuleTitle));
      }
    } else if (value === "essay") {
      const formData = new FormData();

      let dataToAppendEssay;
      if (typeof state.selectedModule !== "string") {
        dataToAppendEssay = {
          document: state.pdf,
          title: state.title,
          author: state.user.author,
          study_module: state.selectedModule.id,
        };
      } else {
        dataToAppendEssay = {
          document: state.pdf,
          title: state.title,
          author: state.user.author,
          study_module: parseInt(localStorage.getItem("studyModuleId")),
        };
      }
      Object.entries(dataToAppendEssay).forEach(([key, value]) => {
        formData.append(key, value);
      });
      await createEssay(formData);
      setState((prevState) => ({
        ...prevState,
        isModalOpen: false,
        essay: [],
        title: "",
        pdf: null,
        files: [],
        loading: false,
        loader: false,
      }));
      if (typeof state.selectedModule !== "string") {
        await getContentEssay(state.selectedModule.slug);
      } else if (state.modules.length > 0) {
        let foundModule = state.modules.find(
          (item) => item.id === parseInt(localStorage.getItem("studyModuleId"))
        );
        await getContentEssay(foundModule.slug);
      } else {
        await getContentEssay(convertTextToSlug(state.studyModuleTitle));
      }
    } else if (value === "module") {
      if (
        state.price === ""
      ) {
        dispatch(
          createErrorAlert(400, "", "Please Select Price for Study Module", 400)
        );
        setState((prev) => ({
          ...prev,
          isModalOpen: false,
          loading: false,
          loader: false,
        }));
        return;
      } else if (
        state.files.length === 0
        // (location.state === undefined || location.state.module === "")
      ) {
        dispatch(
          createErrorAlert(400, "", "Please Select Image for Study Module", 400)
        );
        setState((prev) => ({
          ...prev,
          isModalOpen: false,
          loading: false,
          loader: false,
        }));
        return;
      }
      const formData = new FormData();
      const dataAppend = {
        title: state.studyModuleTitle,
        slug: convertTextToSlug(state.studyModuleTitle),
        degree: state.degree.id,
        degree_level: "0",
        price: state.price,
        image: uploadedFiles.length ? uploadedFiles[0]?.file : null,
        author: state.user.author,
      };
      Object.entries(dataAppend).forEach(([key, value]) => {
        formData.append(key, value);
      });
      const moduleCreated = await createModule(formData);
      if (moduleCreated === 'err') {
        setState((prev) => ({
          ...prev,
          isModalOpen: false,
          loading: false,
          loader: false,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          isModalOpen: false,
          price: "",
          readonly: true,
          files: [],
          modules: [],
          loading: false,
          loader: false,
          moduleDisable: true,
        }));
        dispatch(createErrorAlert(208, '', '<p>Study Module Created Sucessfully</p><br/>You may now click "Continue" to proceed to the next step.', 208))
      }
      await handleGetModules();
    }
  };

  const handleDeleteNote = async (id) => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    await deleteContentNote(id);
    setState((prev) => ({
      ...prev,
      notes: [],
      loading: false,
    }));
    getContentNote(state.selectedModule.slug);
  };

  const handleDeleteEssay = async (id) => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    await deleteContentEssay(id);
    setState((prev) => ({
      ...prev,
      essay: [],
      loading: false,
    }));
    getContentEssay(state.selectedModule.slug);
  };

  const handleSubmitReview = async () => {
    let obj = {
      study_module: [],
      notes: [],
      essay: [],
    };

    state.modules.forEach((item) => {
      if (item.is_approved === false) {
        obj.study_module.push(item.id);
      }
    });
    state.notes.forEach((item) => {
      if (item.is_approved === false) {
        obj.notes.push(item.id);
      }
    });
    state.essay.forEach((item) => {
      if (item.is_approved === false) {
        obj.essay.push(item.id);
      }
    });

    await submitReview(obj);

    history.push("/content-creator/my-dashboard");
  };

  return (
    <div>
      {state.loading && <FullLoader />}
      <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat">
        <Stepper
          step={3}
          onClick={() => history.push("/content-creator")}
          exit={() => history.push("/")}
        />
        <Modal
          open={state.editDocument}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              editDocument: false,
              title: "",
              price: "",
            }))
          }
          focusTrapped={false}
          center
          classNames={{
            overlay: "custom-overlay-class",
            modal: "react-modal-class2", // Use your own custom class here
          }}
        >
          <p className="font-bold text-center text-h5 mb-10">
            {state.tabState.notes
              ? "Please edit your Note"
              : state.tabState.essay
                ? "Please edit your Essay"
                : "Please edit your Study Module"}
          </p>
          <Input
            placeholder={
              state.tabState.notes
                ? "Enter Note Name"
                : state.tabState.essay
                  ? "Enter Essay Name"
                  : "Enter Study Module Name"
            }
            value={state.studyModuleTitle}
            setValue={(value) => {
              if (state.tabState.notes || state.tabState.essay) {
                setState((prevState) => ({
                  ...prevState,
                  title: value,
                }));
              } else {
                setState((prevState) => ({
                  ...prevState,
                  studyModuleTitle: value,
                }));
              }
            }}
          ></Input>
          {/* {state.tabState.modules && (
            <div className="mt-4">
              <Input
                placeholder={"Price"}
                value={state.price}
                setValue={(value) => {
                  setState((prevState) => ({
                    ...prevState,
                    price: value,
                  }));
                }}
              ></Input>
            </div>
          )} */}
          {!state.tabState.modules && (
            <div>
              {" "}
              <div className="flex flex-row items-center justify-center mt-20 flex-wrap w-full">
                <Dropzone onDrop={onDrop} accept="application/pdf">
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject,
                    isDragAccept,
                  }) => (
                    <div
                      className="dropzoneSection"
                      style={{
                        borderColor: isDragReject
                          ? "red"
                          : isDragAccept
                            ? "green"
                            : "#B4B4B4",
                      }}
                    >
                      <div
                        {...getRootProps()}
                        className="dropzoneInner cursor-pointer flex flex-col justify-center items-center"
                      >
                        <input {...getInputProps()} className="dropzoneInner" />
                        <img alt="upload" src={UploadIcon} />
                        <p className="text-darkGrey2 font-normal text-body-large text-center pt-8">
                          {!isDragActive
                            ? "Drop your files here"
                            : "Drop them like they are hot potatoes"}
                        </p>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
              <div className="mt-10 px-6">
                {state.files.map((item, index) => (
                  <div
                    className="flex flex-row justify-between items-center mt-6"
                    key={index}
                  >
                    <p className="text-darkest font-normal text-16 md:text-body-large">
                      {item?.name}
                    </p>
                    <img
                      className="cursor-pointer"
                      onClick={() => deleteFile(item?.name)}
                      src={RemoveIcon}
                      alt="remove"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="flex flex-col justify-center items-center w-full mt-4 md:mt-12">
            <div className="w-36 mt-5 md:mt-0">
              <Button
                filled
                onClick={() => {
                  if (state.tabState.notes) {
                    handleUpdateDocument("notes");
                  } else if (state.tabState.essay) {
                    handleUpdateDocument("essay");
                  } else if (state.tabState.modules) {
                    handleUpdateDocument("module");
                  }
                }}
                loading={state.loader}
              >
                <p className="text-center text-14 xl:text-body-large">Update</p>
              </Button>
            </div>
          </div>
        </Modal>
        <ModalName
          isOpen={state.isNameModalOpen}
          buttonOneText="OK"
          onClickFirstButton={() =>
            setState((prevState) => ({
              ...prevState,
              isNameModalOpen: false,
            }))
          }
          closeModal={() =>
            setState((prevState) => ({
              ...prevState,
              isNameModalOpen: false,
            }))
          }
          title="Please enter document name"
        />
        <Container>
          <div className="mt-50 md:pl-24 custom2:pl-28 text-body-large flex lg:flex-row sm:flex-col custom2:space-x-7  text-darkest">
            <div className="flex flex-col w-full custom2:w-1/3">
              <p className="font-bold text-h5 md:text-h4 text-darkest md:mb-6">
                Plan your study module
              </p>
              <div className="bg-white">
                {state.tabs.map((item) => {
                  const key = item === "Study Module" ? "modules" : item === "Example Questions and Answers" ? "essay" : item.toLowerCase().replace(/\s+/g, '');
                  return (
                    <CustomRadioButton
                      key={item}
                      text={item}
                      onClick={handleTab}
                      state={state.tabState}
                      checked={state.checkedState[key]}
                    />
                  );
                })}

              </div>

              <div className="flex flex-col items-center md:flex-row justify-center w-full pt-10 pb-10 custom2:pt-16 custom2:pb-0">
                <div className="w-full md:w-2/4 custom2:w-full">
                  <Button onClick={() => handleSubmitReview()} filled>
                    <p className="text-center text-14 xl:text-body-large">
                      Submit for review
                    </p>
                  </Button>
                </div>
              </div>
            </div>
            {state.tabState.notes ? (
              <>
                <div className="flex flex-col w-full custom2:w-2/3 pr-1.5 md:pr-20">
                  <p className="font-bold text-h4 custom2:text-36 custom2:pl-12">
                    Notes
                  </p>
                  <p className="text-15 mt-0.5 text-darkGrey mb-11 custom2:pl-12">
                    You can upload documents in .pdf format
                  </p>
                  <div className="bg-white " style={{ borderRadius: "25px" }}>
                    <div
                      className="space-y-3.5 max-h-573 px-4 custom2:px-12 "
                      id="session-container"
                    >
                      {state.notes.map((item, index) => {
                        return (
                          <div
                            className="pb-8 md:pb-10 flex flex-row justify-between items-center"
                            key={index}
                          >
                            <p className="text-darkest text-16 md:text-body-large custom2:text-h5 font-bold">
                              {slugToText(item?.title)}
                            </p>
                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              <div>
                                <Menu.Button className="inline-flex justify-center w-full rounded-md  lg:px-4 py-2 px-2">
                                  <div className="w-full flex flex-row items-center justify-center">
                                    <div className="headDropSec w-30 md:w-30 abc">
                                      <img
                                        src={MoreIcon}
                                        alt="Profile"
                                        style={{
                                          cursor: "pointer",
                                          borderRadius: "50%",
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="headDropItemSec origin-top-right absolute right-0 mt-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-ligthBorderGrey focus:outline-none z-50">
                                  <div className="py-1">
                                    <Menu.Item>
                                      <button
                                        onClick={() => {
                                          setState((prevState) => ({
                                            ...prevState,
                                            editDocument: true,
                                            title: item.title,
                                            noteId: item.id,
                                          }));
                                        }}
                                        className="block w-full text-left px-4 py-2 text-sm hover:bg-offWhite text-darkGrey flex gap-2 items-center"
                                      >
                                        Edit Document
                                      </button>
                                    </Menu.Item>
                                  </div>
                                  <div className="py-1">
                                    <Menu.Item>
                                      <button
                                        onClick={() => downloadPdf(item, "notes")}
                                        className="block w-full text-left px-4 py-2 text-sm hover:bg-offWhite text-darkGrey flex gap-2 items-center"
                                      >
                                        Download Content
                                      </button>
                                    </Menu.Item>
                                  </div>
                                  <div className="py-1">
                                    <Menu.Item>
                                      <button
                                        onClick={() => {
                                          handleDeleteNote(item.id);
                                        }}
                                        className="block w-full text-left px-4 py-2 text-sm hover:bg-offWhite text-darkGrey flex gap-2 items-center"
                                      >
                                        Delete
                                      </button>
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        );
                      })}
                    </div>
                    <div
                      onClick={(e) => {
                        e.preventDefault()
                        if (
                          state.newCreated !== null ||
                          state.selectedModule.slug !== undefined
                        ) {
                          setState((prevState) => ({
                            ...prevState,
                            isModalOpen: true,
                          }));
                        } else {
                          dispatch(
                            createErrorAlert(
                              400,
                              "",
                              "You have to Add Study Module First",
                              400
                            )
                          );
                        }
                      }}
                      className="px-4 custom2:px-12 flex flex-row pb-16 items-center cursor-pointer"
                    >
                      <img className="w-3.5 h-auto" src={AddIcon} alt="add" />{" "}
                      <span className="font-bold text-16 md:text-body-large text-primary ml-16">
                        Add more
                      </span>
                      <div className="ml-auto mt-24 flex cursor-pointer text-primary font-bold items-center gap-4" onClick={(e) => {
                        e.stopPropagation()
                        handleTab("Example Questions and Answers")
                      }}>
                        <p>Continue</p>
                        <span><FaArrowRight /></span>
                      </div>
                    </div>

                    <div className="w-full mt-24">
                      <p>
                        The key content is creating notes for each topic. This is essentially a starting point for all students to prepare for their assessments. For example, in contract law, one of the key topics covered in the lecture is the contractual offer. A content creator for contract law would make notes for the contractual offer as well as all the other topics covered in the course. We recommend condensing the notes as much as possible. Only write exactly what is necessary for students to know to get a high grade on the exam based on your experience.
                      </p>
                      <br />
                      <p style={{ color: "red" }}>
                        It can be anything from 2 to 10 pages per topic. We do not recommend more than 10 pages per topic.
                      </p>
                      <br />
                      <p>Notes should: </p>
                      <br />
                      <ul style={{ listStyle: "disc" }}>
                        <li>Cover all the <span style={{ color: "red" }}>essential information</span> necessary to get a high grade, ideally a First Class.</li>
                        <li>Be <span style={{ color: "red" }}>simple, concise, and condensed</span> into just a few pages. Students can get books if they want to read thousands of pages. They come to StudyCrowd.AI for <span style={{ color: "red" }}>short, condensed, reliable, and simple notes</span> that give them just what they need for high grades, without the extra, unnecessary information.</li>
                        <li>Be full of <span style={{ color: "red" }}>exam tips</span> – you should give advice to students in the notes on how to get high grades on the exams or coursework based on your experience.</li>
                        <li>Use <span style={{ color: "red" }}>simple language</span>. Remember, students are just starting their degrees. They need oversimplified study materials without too much technical language. They should learn the required terminology, but try to explain everything in very simple words.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            ) : state.tabState.essay ? (
              <div className="flex flex-col w-full custom2:w-2/3 pr-1.5 md:pr-20">
                <p className="font-bold text-h4 custom2:text-36 custom2:pl-12">
                  Model Essays
                </p>
                <p className="text-15 mt-0.5 text-darkGrey mb-11 custom2:pl-12">
                  You can upload documents in .pdf format
                </p>
                <div className="bg-white " style={{ borderRadius: "25px" }}>
                  <div
                    className="space-y-3.5 max-h-573 px-4 custom2:px-12 "
                    id="session-container"
                  >
                    {state.essay.map((item, index) => (
                      <div
                        className="pb-8 md:pb-10 flex flex-row justify-between items-center"
                        key={index}
                      >
                        <p className="text-darkest text-16 md:text-body-large custom2:text-h5 font-bold">
                          {slugToText(item?.title)}
                        </p>
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="inline-flex justify-center w-full rounded-md  lg:px-4 py-2 px-2">
                              <div className="w-full flex flex-row items-center justify-center">
                                <div className="headDropSec w-30 md:w-30 abc">
                                  <img
                                    src={MoreIcon}
                                    alt="Profile"
                                    style={{
                                      cursor: "pointer",
                                      borderRadius: "50%",
                                      width: "24px",
                                      height: "24px",
                                    }}
                                  />
                                </div>
                              </div>
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="headDropItemSec origin-top-right absolute right-0 mt-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-ligthBorderGrey focus:outline-none z-50">
                              <div className="py-1">
                                <Menu.Item>
                                  <button
                                    onClick={() => {
                                      setState((prevState) => ({
                                        ...prevState,
                                        editDocument: true,
                                        title: item.title,
                                        essayId: item.id,
                                      }));
                                    }}
                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-offWhite text-darkGrey flex gap-2 items-center"
                                  >
                                    Edit Document
                                  </button>
                                </Menu.Item>
                              </div>
                              <div className="py-1">
                                <Menu.Item>
                                  <button
                                    onClick={() => downloadPdf(item, "essay")}
                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-offWhite text-darkGrey flex gap-2 items-center"
                                  >
                                    Download Content
                                  </button>
                                </Menu.Item>
                              </div>
                              <div className="py-1">
                                <Menu.Item>
                                  <button
                                    onClick={() => {
                                      handleDeleteEssay(item.id);
                                    }}
                                    className="block w-full text-left px-4 py-2 text-sm hover:bg-offWhite text-darkGrey flex gap-2 items-center"
                                  >
                                    Delete
                                  </button>
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    ))}
                  </div>
                  <div
                    onClick={(e) => {
                      e.preventDefault()
                      if (
                        state.newCreated !== null ||
                        state.selectedModule.slug !== undefined
                      ) {
                        setState((prevState) => ({
                          ...prevState,
                          isModalOpen: true,
                        }));
                      } else {
                        dispatch(
                          createErrorAlert(
                            400,
                            "",
                            "You have to Add Study Module First",
                            400
                          )
                        );
                      }
                    }}
                    className="px-4 custom2:px-12 flex flex-row pb-16 items-center cursor-pointer"
                  >
                    <img className="w-3.5 h-auto" src={AddIcon} alt="add" />{" "}
                    <span className="font-bold text-16 md:text-body-large text-primary ml-16">
                      Add more
                    </span>
                    <div className="ml-auto mt-24 flex cursor-pointer text-primary font-bold items-center gap-4" onClick={(e) => {
                      e.stopPropagation();
                      handleTab("Example Questions and Answers")
                    }}>
                      <p>Finish</p>
                      {/* <span><FaArrowRight /></span> */}
                    </div>
                  </div>
                </div>
                <div className="w-full mt-24">
                  <p className="text-20 font-bold">EXAMPLE QUESTIONS AND ANSWERS</p>
                  <br />
                  <p>This is the second content type that we have on StudyCrowd.AI to show students what kind of questions they can expect on exams or coursework and how they are supposed to answer them. Please be mindful of intellectual property rights. Do not use the questions provided by professors at your university. You should write similar questions yourself instead.</p>
                  <br />
                  <p>After you write similar questions to the ones that appear on exams and coursework, provide answers too. These should be model answers, meaning that they must be of a very high level, particularly {user?.user_currency === "UK" ? 'high 2.1 or First Class' : "A or B"}. If your example questions and answers are very good and students who buy them get very high grades, that will most likely reflect significantly on your sales. Hence, to maximize your earnings, try your best to provide high-quality example questions and answers, as many as possible.</p>
                </div>

              </div>
            ) : state.tabState.pricing ? (
              <div className="flex flex-col w-full custom2:w-2/3 pr-1.5 md:pr-20">
                <p className="text-18 font-bold mt-2 text-black custom2:pl-12">
                  Our recommendation:
                </p>
                {user?.user_currency === "UK" ? <p className="text-15 mt-0.5 text-darkGrey mb-11 custom2:pl-12">
                  Recommended price for First Class notes and model essays;
                  £15-£30 depending on how many model essays are offered. Adding
                  Tutorial Videos can increase the price. <br />
                  Maximum price should not be more than £35
                </p> : <p className="text-15 mt-0.5 text-darkGrey mb-11 custom2:pl-12">You choose the price. However, we provide a recommendation for what the average price is.
                  For universities in the United States, the average price for a study module is anywhere from $40 to $70</p>}

                <div className="flex ml-16 mt-24">
                  <div className="w-36">
                    <Input
                      placeholder={user?.user_currency === "UK" ? '£' : '$'}
                      value={user?.user_currency === "UK" ? '£' : '$'}
                      readonly={true}
                    ></Input>
                  </div>
                  <div className="w-64 ml-8">
                    <Input
                      type={"number"}
                      value={state.price && state.price >= 1000 ? state.price / 100 : state.price}
                      setValue={(value) => {
                        setState((prevState) => ({
                          ...prevState,
                          price: value,
                        }));
                      }}
                    ></Input>
                  </div>
                  {typeof state.selectedModule !== "string" && (
                    <div className="w-36 ml-8">
                      <Button
                        text={"Update Price"}
                        onClick={() => {
                          handleUpdateDocument("module");
                        }}
                      ></Button>
                    </div>
                  )}
                </div>
                {state.price !== undefined && state.price !== "" ? <div className="ml-auto mt-10 flex cursor-pointer text-primary font-bold items-center gap-4" onClick={() => handleTab("Pictures")}>
                  <p>Continue</p>
                  <span><FaArrowRight /></span>
                </div> : null}
              </div>
            ) : state.tabState.pictures ? (
              <div className="flex flex-col w-full custom2:w-2/3 pr-1.5 md:pr-20">
                <p className="text-15 mt-0.5 text-darkGrey mb-11 custom2:pl-12 pl-12">
                  Please upload a picture to be assigned to the study module.
                  <br />
                  We recommend a picture related to the study module you are
                  uploading
                </p>
                <p className="mt-[2px] mb-4 pl-12 text-15" style={{color: 'red'}}>
                  Uploading a picture is optional. If you want to upload a picture, 
                  please follow the intellectual property rules explained below. 
                  If you don’t upload a picture, our team will apply one of our images for you. 
                  You can simply continue to the next step by clicking on "Continue" below.
                </p>
                <div className="ml-24">
                  <Dropzone onDrop={onDropModuleImage} accept="image/*">
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject,
                      isDragAccept,
                    }) => (
                      <div
                        className="dropzoneSection"
                        style={{
                          borderColor: isDragReject
                            ? "red"
                            : isDragAccept
                              ? "green"
                              : "#B4B4B4",
                        }}
                      >
                        <div
                          {...getRootProps()}
                          className="dropzoneInner cursor-pointer flex flex-col justify-center items-center"
                        >
                          <input
                            {...getInputProps()}
                            className="dropzoneInner"
                          />
                          <img alt="upload" src={UploadIcon} />
                          {!isDragActive ? (
                            <div>
                              <p className="text-darkGrey2 font-normal text-body-large text-center pt-8">
                                Drop your files here
                              </p>
                              <p className="text-darkGrey justify-center items-center text-center pt-4">
                                or
                              </p>
                              <p className="text-primary font-normal text-body-large text-center pt-4">
                                Select a picture from library
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
                {state.files.map((item, index) => (
                  <div
                    className="flex flex-row justify-between items-center mt-6"
                    key={index}
                  >
                    <p className="text-darkest font-normal text-16 md:text-body-large">
                      {item?.name}
                    </p>
                    <img
                      className="cursor-pointer"
                      onClick={() => deleteFile(item?.name)}
                      src={RemoveIcon}
                      alt="remove"
                    />
                  </div>
                ))}
                {state.files.length !== 0 && <div className="ml-auto mt-10 flex cursor-pointer text-primary font-bold items-center gap-4" onClick={() => handleTab("Study Modules")}>
                  <p>Continue</p>
                  <span><FaArrowRight /></span>
                </div>}
                {/* <div className="w-48 mt-10 ml-48">
                  <Button text="Library"></Button>
                </div> */}
                <div className="w-full mt-24">
                  <p style={{ color: "red" }}>
                    IMPORTANT NOTICE: Please make sure that you upload royalty
                    free images only or get a relevant license for using the
                    image for commercial purposes. You can download royalty free
                    or licensed images for commercial use from the following
                    platforms amongst others:
                  </p>
                  <a
                    href="https://unsplash.com/"
                    target="_blank"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    https://unsplash.com/
                  </a>
                  <br />
                  <a
                    href="https://pixabay.com/"
                    target="_blank"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    https://pixabay.com/
                  </a>
                  <br />
                  <a
                    href="https://www.pexels.com/"
                    target="_blank"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    https://www.pexels.com/
                  </a>
                  <br />
                  <a
                    href="https://stock.adobe.com/"
                    target="_blank"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    https://stock.adobe.com/
                  </a>
                </div>
              </div>
            ) : state.tabState.modules ? (
              <div className="flex flex-col w-full custom2:w-2/3 pr-1.5 md:pr-20">
                <p className="font-bold text-h4 custom2:text-36 custom2:pl-12">
                  Study Module
                </p>
                <p className="custom2:pl-12">Please give your study module a relevant name. We highly recommend naming the module the same as it was called at your university, as that will make it easily discoverable for students who are studying that module.</p>
                <div className="bg-white mt-4" style={{ borderRadius: "25px" }}>
                  <div
                    className="space-y-3.5 max-h-573 px-4 custom2:px-12 "
                    id="session-container"
                  >
                    {state.selectedModule.slug !== undefined &&
                      state.modules.length > 0 ? (
                      state.modules.map((item) => {
                        if (item.slug === state.selectedModule.slug) {
                          return (
                            <div className="pb-8 md:pb-10 flex flex-row justify-between items-center">
                              <p className="text-darkest text-16 md:text-body-large custom2:text-h5 font-bold">
                                {item?.title}
                              </p>
                              <Menu
                                as="div"
                                className="relative inline-block text-left"
                              >
                                <div>
                                  <Menu.Button className="inline-flex justify-center w-full rounded-md  lg:px-4 py-2 px-2">
                                    <div className="w-full flex flex-row items-center justify-center">
                                      <div className="headDropSec w-30 md:w-30 abc">
                                        <img
                                          src={MoreIcon}
                                          alt="Profile"
                                          style={{
                                            cursor: "pointer",
                                            borderRadius: "50%",
                                            width: "24px",
                                            height: "24px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="headDropItemSec origin-top-right absolute right-0 mt-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-ligthBorderGrey focus:outline-none z-50">
                                    <div className="py-1">
                                      <Menu.Item>
                                        <button
                                          onClick={() => {
                                            setState((prevState) => ({
                                              ...prevState,
                                              editDocument: true,
                                              title: item.title,
                                              moduleId: item.id,
                                              price: item.price,
                                            }));
                                          }}
                                          className="block w-full text-left px-4 py-2 text-sm hover:bg-offWhite text-darkGrey flex gap-2 items-center"
                                        >
                                          Edit Module
                                        </button>
                                      </Menu.Item>
                                    </div>
                                    <div className="py-1">
                                      <Menu.Item>
                                        <button
                                          onClick={() => {
                                            handleDelete(item.id);
                                          }}
                                          className="block w-full text-left px-4 py-2 text-sm hover:bg-offWhite text-darkGrey flex gap-2 items-center"
                                        >
                                          Delete
                                        </button>
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                          );
                        }
                      })
                    ) : (
                      <div className="pb-8 md:pb-10 space-x-4 flex flex-row justify-between items-center">
                        <div className="w-full">
                          <Input
                            placeholder={"Enter Study Module Name"}
                            value={state.studyModuleTitle}
                            setValue={(value) => {
                              setState((prevState) => ({
                                ...prevState,
                                studyModuleTitle: value,
                              }));
                            }}
                            readonly={state.readonly}
                          ></Input>
                        </div>
                        <div className="w-100">
                          <Button
                            onClick={() => handleAdd("module")}
                            loading={state.loader}
                            disabled={state.moduleDisable}
                          >
                            <p className="text-center text-14 xl:text-body-large">
                              Add
                            </p>
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        isModalOpen: true,
                      }))
                    }
                    className="px-4 custom2:px-12 flex flex-row pb-16 items-center cursor-pointer"
                  >
                    <img className="w-3.5 h-auto" src={AddIcon} alt="add" />{" "}
                    <span className="font-bold text-16 md:text-body-large text-primary ml-16">
                      Add more
                    </span>
                  </div> */}
                </div>
                {state.studyModuleTitle !== undefined && state.studyModuleTitle !== "" && <div className="ml-auto mt-10 flex cursor-pointer text-primary font-bold items-center gap-4" onClick={() => handleTab("Notes")}>
                  <p>Continue</p>
                  <span><FaArrowRight /></span>
                </div>}
              </div>
            ) : null}
          </div>
          <div className="mt-24">
                To avoid rejection of your study module, please ensure you have achieved a grade of {userCurrency === 'UK' ? '2.1 or First Class' : 'A or B'} in the specific module you are uploading, not just overall, as evidenced by your transcript. 
                <br/>
                <br/>
                If you have not achieved the required grade, your module will not be published. 
                Additionally, please ensure your content is original, high quality, and free of grammatical errors.
              </div>
          {/* <Modal
          open={libraryModal}
          onClose={() => setLibraryModal(false)}
          focusTrapped={false}
          center
        >
          <div className="flex space-x-4">
            <div className="mediaImages">
              {items?.map((item, index) => {
                console.log(items.length, "items");
                return (
                  <div
                    key={index}
                    className={`image-item ${
                      selectedImage === index ? "selected" : ""
                    }`}
                    onClick={() => toggleSelectAndCheckbox(index)}
                    style={{ position: "relative" }}
                  >
                    <img
                      src={item}
                      alt=""
                      style={{
                        height: "200px",
                        width: "200px",
                        border:
                          selectedImage === index
                            ? "5px solid #CC6828"
                            : "none",
                      }}
                    />
                    {selectedImage === index && (
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          className="image-checkbox"
                          checked={selectedImage === index}
                          onChange={() => toggleSelectAndCheckbox(index)}
                        />
                      </label>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="pt-14 flex justify-center">
            <PaginatedItems onPageChange={handlePageChange} disabledPrevious={disablePrevious} disabledNext={disableNext} />
          </div>
        </Modal> */}
        </Container>        
        <Modal
          open={state.isModalOpen}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              isModalOpen: false,
              price: "",
            }))
          }
          focusTrapped={false}
          center
          classNames={{
            overlay: "custom-overlay-class",
            modal: "react-modal-class2", // Use your own custom class here
          }}
        >
          <p className="font-bold text-center text-h5 mb-10">
            {state.tabState.notes
              ? "Add Note"
              : state.tabState.essay
                ? "Add Essay"
                : "Add Study Module"}
          </p>
          <Input
            placeholder={
              state.tabState.notes
                ? "Enter Note Name"
                : state.tabState.essay
                  ? "Enter Essay Name"
                  : "Enter Study Module Name"
            }
            value={state.title}
            setValue={(value) => {
              setState((prevState) => ({
                ...prevState,
                title: value,
              }));
            }}
          ></Input>
          {state.title === "" && state.errorStateTitle ? <p style={{color: 'red'}}>*  This field is required. Please enter a title for your {state.tabState.notes ? 'study notes' : 'essay'}</p> : null}
          {!state.tabState.modules && (
            <div className="flex flex-row items-center justify-center mt-20 flex-wrap w-full">
              <Dropzone onDrop={onDrop} accept="application/pdf">
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragReject,
                  isDragAccept,
                }) => (
                  <div
                    className="dropzoneSection"
                    style={{
                      borderColor: isDragReject
                        ? "red"
                        : isDragAccept
                          ? "green"
                          : "#B4B4B4",
                    }}
                  >
                    <div
                      {...getRootProps()}
                      className="dropzoneInner cursor-pointer flex flex-col justify-center items-center"
                    >
                      <input {...getInputProps()} className="dropzoneInner" />
                      <img alt="upload" src={UploadIcon} />
                      <p className="text-darkGrey2 font-normal text-body-large text-center pt-8">
                        {!isDragActive
                          ? "Drop your files here"
                          : "Drop them like they are hot potatoes"}
                      </p>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className="mt-10 px-6">
                {state.files.map((item, index) => (
                  <div
                    className="flex flex-row justify-between items-center mt-6"
                    key={index}
                  >
                    <p className="text-darkest font-normal text-16 md:text-body-large">
                      {item?.name}
                    </p>
                    <img
                      className="cursor-pointer"
                      onClick={() => deleteFile(item?.name)}
                      src={RemoveIcon}
                      alt="remove"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="flex flex-col justify-center items-center w-full mt-4 md:mt-12">
            <div className="w-36 mt-5 md:mt-0">
              <Button
                loading={state.loader}
                filled
                onClick={() => {
                  if (state.tabState.notes) {
                    if(state.title === ""){
                      setState((prev) => ({
                        ...prev,
                        errorStateTitle: true
                      }))
                    } else{
                      handleAdd("notes");
                    }
                  } else if (state.tabState.essay) {
                    if(state.title === ""){
                      setState((prev) => ({
                        ...prev,
                        errorStateTitle: true
                      }))
                    } else{
                      handleAdd("essay");
                    }
                  } else if (state.tabState.modules) {
                    if (state.files.length === 0) {
                      dispatch(
                        createErrorAlert(
                          400,
                          "",
                          "Please Select Image for your Study Module",
                          400
                        )
                      );
                    } else if (state.price === "") {
                      dispatch(
                        createErrorAlert(
                          400,
                          "",
                          "Please Select Price for your Study Module",
                          400
                        )
                      );
                    } else {
                      handleAdd("module");
                    }
                  }
                }}
              >
                <p className="text-center text-14 xl:text-body-large">Add</p>
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  createNoteData: createNoteData(state),
  createEssayData: createEssayData(state),
  createNoteError: createNoteError(state),
  createEssayError: createEssayError(state),
  createModuleError: createModuleError(state),
  createNoteLoading: createNoteLoading(state),
  createModuleData: createModuleData(state),
  getContentEssayData: getContentEssayData(state),
  getContentNoteData: getContentNoteData(state),
  deleteContentNoteData: deleteContentNoteData(state),
  deleteContentNoteError: deleteContentNoteError(state),
  deleteContentEssayError: deleteContentEssayError(state),
  deleteContentEssayData: deleteContentEssayData(state),
  deleteContentModuleError: deleteContentModuleError(state),
  submitContentModuleData: submitContentModuleData(state),
});
const mapDispatchToProps = (dispatch) => ({
  createNote: (formData) => dispatch(createNoteAction(formData)),
  createEssay: (formData) => dispatch(createEssayAction(formData)),
  createModule: (formData) => dispatch(createModuleAction(formData)),
  getContentEssay: (slug) => dispatch(getContentEssayAction(slug)),
  getContentNote: (slug) => dispatch(getContentNoteAction(slug)),
  deleteContentNote: (id) => dispatch(deleteContentNoteAction(id)),
  deleteContentEssay: (id) => dispatch(deleteContentEssayAction(id)),
  deleteContentModule: (id) => dispatch(deleteContentModuleAction(id)),
  updateNote: (id, formData) => dispatch(updateNoteAction(id, formData)),
  updateEssay: (id, formData) => dispatch(updateEssayAction(id, formData)),
  updateModule: (id, formData) => dispatch(updateModuleAction(id, formData)),
  submitReview: (data) => dispatch(submitContentModuleAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StepThree);
