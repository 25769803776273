import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  checkListAction,
  createErrorAlert,
  getCatalogue,
  userLogin,
  userSignUp,
} from "../../redux/actions";
import { postMailChimpAction } from "../../redux/actions/postMailChimpAction";
import {
  catalogueData,
  checkListData,
  notesData,
  notesError,
  notesLoading,
} from "../../redux/selectors";
import GoogleAuthentication from "../../components/SocialAuthentication/GoogleSocialAuth";
import getNotes from "../../services/Studies/getNotes";
import * as Yup from "yup";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { apiRequest, getApiKey, getUser, slugToText } from "../../helpers";
import fetchNotes from "../../helpers/fetchNotes";
import FullLoader from "../../components/Loader/FullLoader";
import { Helmet } from "react-helmet";
import Container from "../../components/container";
import Button from "../../components/EmptyButton";
import { saveAs } from "file-saver";
import { FaStar } from "react-icons/fa";
import StudyMaterialCard from "../../components/StudyMaterialCard";
import InfoImg from "../../assets/icons/information.svg";
import GreenCardImage from "../../assets/images/green-card-image.svg";
import InfoBox from "../../components/InfoBox";
import Slider from "../../components/MainAdminSlider";
import Modal from "react-responsive-modal";
import { Field, Form, Formik } from "formik";
import Input from "../../components/Input/InputComponent";
import { Document, Page, pdfjs } from "react-pdf";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import convertTextToSlug from "../../helpers/textToSlug";
import img1 from "../../assets/newImages/Group 1399.png";
import img3 from "../../assets/newImages/Group 1403.png";
import img2 from "../../assets/newImages/Illustration.png";
import { BsZoomOut, BsZoomIn } from "react-icons/bs";
import { RiFullscreenFill } from "react-icons/ri";
import Loader from "../../components/PDFLoader/Loader";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { IoMdArrowRoundForward, IoMdArrowRoundBack } from "react-icons/io";
import { getDocument } from "pdfjs-dist";
import Context from "../../services/Context";
import { param } from "jquery";
import CallToAction from "../../components/CallToAction";
import LockPreview from "../../components/LockPreview/LockPreview";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PaidUserNotes = (props) => {
  const {
    data,
    getCheckList,
    studymodule,
    checkListData,
    postMailChimp,
    userSignUp,
    userLogin,
    dataCatalogue,
    getCatalogue,
  } = props;

  const history = useHistory();
  const params = useParams();
  const locationHook = useLocation();
  const dispatch = useDispatch();
  const { value } = useContext(Context);
  const previousLengthRef = useRef(0);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [state, setState] = useState({
    firstTimeCase: "",
    firstTimeTitle: null,
    ogDescription: null,
    ogUrl: null,
    description: null,
    canonical: null,
    keywords: null,
    robots: null,
    loader: false,
    checkbox: false,
    price: null,
    moduleId: null,
    pdf: null,
    loadingState: true,
    documentFull: false,
    documentSingle: false,
    showConfirmPassword: false,
    audioState: null,
    totalPages: 0,
    currency: null,
    pageNumber: 1,
    pagePreviews: [],
    pageScale:
      window.innerWidth >= 992 && window.innerWidth <= 1200
        ? 0.7
        : window.innerWidth >= 768 && window.innerWidth <= 991
        ? 0.4
        : window.innerWidth >= 400 && window.innerWidth <= 767
        ? 0.2
        : 1.5,
    loading: true,
    searchQuery: "",
    isFullScreen: false,
    highlightedText: [],
    hide: true,
    content: null,
    showInfo: false,
    showInfo2: false,
    showInfo3: false,
    loginModal: false,
    signUpModal: false,
    showPassword: false,
    pageNumber: 1,
    author: null,
    pageIndex: 0,
    numPage: null,
    emailError: false,
    checkListDisplay: false,
    emailCheckList: "",
    studyModuleState: params?.slug1 ? params?.slug1 : studymodule,
  });

  const viewerRef = useRef(null);

  const user = JSON.parse(getUser());

  const [testimonials, setTestimonials] = useState([
    {
      text: "The service was efficient and professional. The general feedback in the one-on-one sessions and each tutorial was constructive, detailed, meaningful and generally effective in realising my goals. As a result, I successfully obtained a 1st and three 2.1 in my final assessments. Highly recommended.",
      name: "Ann",
    },
    {
      text: "Nino was very helpful with my studies. I got 1st because of her help! I couldn’t recommend StudyCrowd.AI enough. Thank you so much!",
      name: "Amie",
    },
    {
      text: "I can confirm that since I discovered simple studying, life has almost become perfect for me. I find law module very easy to understand with the help of their tutoring sessions and study materials. I scored over 70 % in my Client Practice module last term.",
      name: "Akinyemi",
    },
  ]);

  const [modules, setModules] = useState([
    "Principles of Operations Management",
    "Organisational Behaviour",
    "Business Economics",
    "Principles Of Operations Management",
    "Business Tax",
    "Internation financial accounting",
    "Applied Management Accounting",
    "International Financial Reporting",
    "Financial Management",
    "Macroeconomics",
    "Marketing",
    "Business",
  ]);

  const [paidModules, setPaidModules] = useState([]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const validateSchemaSignup = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    first_name: Yup.string()
      .required("First Name is required")
      .min(5, "Minimum 5 characters")
      .max(26, "Maximum 26 characters"),
    password2: Yup.string().oneOf(
      [Yup.ref("password1"), null],
      "Passwords must match"
    ),
    password1: Yup.string()
      .required("Password is required.")
      .min(7, "Password is too short - should be 6+ chars minimum.")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        "Password should have at least one capital letter & one digit."
      ),
  });

  const validateSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  useEffect(() => {
    for (let i = 0; i < value?.dataCatalogue?.length; i++) {
      if (value?.dataCatalogue[i]?.is_paid === true) {
        if (
          !paidModules.includes(value?.dataCatalogue[i]?.study_module?.slug)
        ) {
          paidModules.push(value?.dataCatalogue[i]?.study_module?.slug);
        }
      }
    }
  }, [value?.dataCatalogue]);
  useEffect(() => {
    fetchPagePreviews();
  }, [state.totalPages]);

  useEffect(() => {
    if (params?.slug1 && state.studyModuleState) {
      if (checkListData.length > 0) {
        let isModuleInList;
          if (Array.isArray(checkListData)) {
            isModuleInList = checkListData?.some((item) =>
              item?.title
                ?.toLowerCase()
                .includes(state.studyModuleState?.toLowerCase())
            );
          }
          if (isModuleInList) {
            setState((prevState) => ({
              ...prevState,
              checkListDisplay: isModuleInList,
            }));
          } else if (
            modules?.includes(state.studyModuleState) &&
            (user?.subscription_name === "Basic Subscription" || !getApiKey())
          ) {
            setState((prevState) => ({ ...prevState, checkListDisplay: true }));
          } else {
            setState((prevState) => ({
              ...prevState,
              checkListDisplay: false,
            }));
          }
      }
      setState((prev) => ({
        ...prev,
        documentFull: false,
      }));
    }
  }, [params, state.studyModuleState]);


  const onDocumentLoadSuccess = ({ numPages }) => {
    setState((prev) => ({
      ...prev,
      totalPages: numPages,
    }));
  };

  const handleZoom = (zoomType) => {
    const { pageScale } = state;
    const newScale =
      zoomType === "in"
        ? Math.min(pageScale + 0.2, 3)
        : Math.max(pageScale - 0.2, 0.3);
    setState((prevData) => ({
      ...prevData,
      pageScale: newScale,
    }));
  };

  const handleThumbnailClick = (pageIndex) => {
    setState((prevData) => ({
      ...prevData,
      pageNumber: pageIndex + 1,
    }));
  };

  const fetchPagePreviews = async () => {
    try {
      setState((prevData) => ({
        ...prevData,
        loading: true,
      }));
      const { totalPages } = state;
      if (totalPages > 0) {
        const previews = [];
        for (let i = 1; i <= totalPages; i++) {
          const page = await pdfjs
            .getDocument(state.pdf)
            .promise.then((doc) => doc.getPage(i));
          const scale = 0.5;
          const viewport = page.getViewport({ scale });
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
            renderTextLayer: false,
          };
          const renderTask = page.render(renderContext);
          await renderTask.promise;
          previews.push(canvas.toDataURL("image/png"));
          page.cleanup();
        }
        setState((prevData) => ({
          ...prevData,
          pagePreviews: previews,
        }));
      }
    } catch (error) {
      console.error("Error fetching page previews:", error);
    } finally {
      setState((prevData) => ({
        ...prevData,
        loading: false,
      }));
    }
  };

  const handleFullScreen = () => {
    if (!window.document.fullscreenElement) {
      viewerRef.current.requestFullscreen().catch((err) => {
        console.error("Error attempting to enable full-screen mode:", err);
      });
    } else {
      if (window.document.exitFullscreen) {
        window.document.exitFullscreen();
      }
    }
    setState((prev) => ({
      ...prev,
      isFullScreen: !prev.isFullScreen,
    }));
  };

  const getAllStudyModules = async () => {
    await apiRequest({
      method: "get",
      url: "studies/all/studymodules",
    }).then((res) => {
      siteSearch(res.data);
    });
  };

  const siteSearch = async (response) => {
    fetchNotes(params.slug2, response, setState).then((res) => {
      setState((prev) => ({
        ...prev,
        loadingState: false,
      }));
      for (let i = 0; i < response.length; i++) {
        if (
          parseInt(res?.data[0]?.study_module.id) === parseInt(response[i].id)
        ) {
          localStorage.setItem("studyModule", response[i].slug);
          if (res?.data[0]?.slug === params?.slug2) {
            setState((prevState) => ({
              ...prevState,
              studyModuleState: response[i].title,
              firstTimeTitle: res?.data[0]?.title
                ? res?.data[0]?.title
                : res?.title,
              description: res?.data[0]?.seo_fields?.seo_description
                ? res?.data[0]?.seo_fields?.seo_description
                : res?.seo_fields?.seo_description,
              canonical: res?.data[0]?.seo_fields?.seo_canonical_data
                ? res?.data[0]?.seo_fields?.seo_canonical_data
                : res?.seo_fields?.seo_canonical_data,
              ogUrl: res?.data[0]?.seo_fields?.og_url
                ? res?.data[0]?.seo_fields?.og_url
                : res?.seo_fields?.og_url,
              ogDescription: res?.data[0]?.seo_fields?.og_description
                ? res?.data[0]?.seo_fields?.og_description
                : res?.seo_fields?.og_description,
              keywords: res?.data[0]?.seo_fields?.keywords
                ? res?.data[0]?.seo_fields?.keywords
                : res?.seo_fields?.keywords,
              author: res?.data[0]?.author ? res?.data[0]?.author : res?.author,
              robots:
                res?.data[0]?.seo_fields?.robots === true
                  ? "index, follow"
                  : "noindex, nofollow",
              content:
                res?.data[0]?.content !== undefined
                  ? res?.data[0]?.content
                  : res?.content !== undefined
                  ? res?.content
                  : "",
              pdf: res?.data[0]?.pdf_file
                ? res?.data[0]?.pdf_file
                : res?.pdf_file,
              price: res?.data[0]?.study_module?.price
              ? res?.data[0]?.study_module?.price
              : res?.study_module?.price,
              currency: res?.data[0]?.study_module?.currency
              ? res?.data[0]?.study_module?.currency
              : res?.study_module?.currency,
              moduleId: res?.data[0]?.study_module?.id
              ? res?.data[0]?.study_module?.id
              : res?.study_module?.id,
              audioState:
                res?.data[0]?.pdf_file &&
                !res?.data[0]?.pdf_file.includes(".pdf")
                  ? res?.data[0]?.pdf_file
                  : res?.pdf_file && !res?.data[0]?.includes(".pdf")
                  ? res?.pdf_file
                  : "",
            }));
          }
        }
      }
    });
  };

  useEffect(() => {
    getCheckList()
    getAllStudyModules();

  }, [params, value?.allModules]);

  const viewDocument = () => {
    let studyModuleSlug = convertTextToSlug(state.studyModuleState);

    if (
      user?.subscription_name === "Basic Subscription" ||
      user?.subscription_name.includes("STARTER")
    ) {
      if (paidModules.includes(studyModuleSlug) || user?.free_trial === true) {
        setState((prevState) => ({ ...prevState, documentFull: true }));
      } else if (!paidModules.includes(studyModuleSlug) && user?.free_trial === false && user?.is_law === true && user?.free_trial_end === false) {
        dispatch(
          createErrorAlert(
            800,
            "You discovered a Premium feature!",
            "Upgrading to StudyCrowd.AI Premium will unlock it. With Premium, you can maximise your chances of getting a First Class law degree.",
            800
          )
        );
      } else if(!paidModules.includes(studyModuleSlug) && user?.free_trial === false && user?.is_law === false && user?.free_trial_end === true){
        dispatch(
          createErrorAlert(
            801,
            "You discovered a Premium feature!",
            "This is premium content that is not available with a free subscription. It is designed to help you achieve high grades. Unlock it now!",
            801
          )
        );
      }
      else if(!paidModules.includes(studyModuleSlug) && user?.free_trial === false && user?.is_law === false && user?.free_trial_end === true){
        dispatch(
          createErrorAlert(
            802,
            "You discovered a Premium feature!",
            "This is premium content that is not available with a free subscription. It is designed to help you achieve high grades. Start your free trial to unlock it.",
            802
          )
        );
      }
      else if(!paidModules.includes(studyModuleSlug) && user?.free_trial === false && user?.is_law === false && user?.free_trial_end === false){
        dispatch(
          createErrorAlert(
            803,
            "You discovered a Premium feature!",
            "This is premium content that is not available with a free subscription. It is designed to help you achieve high grades. Start your free trial to unlock it.",
            803
          )
        );
      }
      else if(!paidModules.includes(studyModuleSlug) && user?.free_trial === false && user?.is_law === true && user?.free_trial_end === true){
        dispatch(
          createErrorAlert(
            802,
            "You discovered a Premium feature!",
            "This is premium content that is not available with a free subscription. It is designed to help you achieve high grades. Start your free trial to unlock it.",
            802
          )
        );
      }
    } else {
      setState((prevState) => ({ ...prevState, documentFull: true }));
    }
  };

  const addToBasket = async () => {
    let obj = {
      study_module: state.moduleId,
    };
    await apiRequest({
      method: "post",
      url: "content/basket/",
      data: obj,
    })
      .then((res) => {
        if (res.status === 201) {
          window.location.replace('/shopping-basket')
        }
      })
      .catch((err) => {
        dispatch(createErrorAlert(400, "", err.response.data[0], 400));
      });
  }

  const readNotesCondition = () => {
    let studyModuleSlug = convertTextToSlug(state.studyModuleState);
    if (getApiKey()) {
      if (user?.user_type === "STUDENT") {
        if (
          (user?.subscription_name === "Basic Subscription" ||
            user?.subscription_name?.includes("STARTER")) &&
          paidModules.includes(studyModuleSlug)
        ) {
          return true;
        }
        if (
          (user?.subscription_name === "Basic Subscription" &&
            user?.free_trial === false) ||
          (user?.subscription_name !== "Basic Subscription" &&
            user?.subscription_name?.includes("STARTER"))
        ) {
          return false;
        } else {
          if (
            user?.subscription_name === "Basic Subscription" &&
            user?.free_trial === true
          ) {
            return true;
          } else if (
            user?.subscription_name !== "Basic Subscription" &&
            !user?.subscription_name?.includes("STARTER")
          ) {
            if (paidModules.includes(studyModuleSlug) && state.author !== 1) {
              return true;
            } else if (state.author === 1) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      } else if (user?.user_type !== "STUDENT") {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    readNotesCondition();
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (document.fullscreenElement === viewerRef.current) {
        if (event.key === "ArrowLeft") {
          event.preventDefault();
          setState((prevState) => {
            return {
              ...prevState,
              pageNumber: prevState.pageNumber - 1,
            };
          });
        } else if (event.key === "ArrowRight") {
          event.preventDefault();
          setState((prevState) => {
            return {
              ...prevState,
              pageNumber: prevState.pageNumber + 1,
            };
          });
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const moduleCheck = () => {
    if (
      state.studyModuleState === "International Criminal Law" ||
      state.studyModuleState === "Media Law" ||
      state.studyModuleState === "Aviation Law" ||
      state.studyModuleState === "Criminology" ||
      state.studyModuleState === "Money Laundering" ||
      state.studyModuleState === "International Banking Law"
    ) {
      return "firstCheck";
    } else if (modules?.includes(state.studyModuleState)) {
      return "secondCheck";
    }
  };

  const handleDownloadCheckList = () => {
    let conditionSatisfied = false;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (state.emailCheckList.length === 0) {
      setState((prevState) => ({ ...prevState, emailError: true }));
      return;
    }
    if (emailRegex.test(state.emailCheckList)) {
      for (let i = 0; i < checkListData.length; i++) {
        if (
          checkListData[i].title
            .toLowerCase()
            ?.includes(state.studyModuleState.toLowerCase())
        ) {
          if (!conditionSatisfied) {
            conditionSatisfied = true;
            window.open(checkListData[i].pdf, "_blank");
            saveAs(
              checkListData[i].pdf,
              `${state.studyModuleState.toLowerCase().replace(" ", "-")}.pdf`
            );
          }
        }
      }
      postMailChimp(state.emailCheckList, state.studyModuleState).then(
        (res) => {
          dispatch(createErrorAlert(200, "", res.data.message, 200));
        }
      );
      setState((prevState) => ({
        ...prevState,
        emailError: false,
        emailCheckList: "",
      }));
      if (moduleCheck() === "secondCheck") {
        dispatch(
          createErrorAlert(400, "", "You have successfully signed up", 400)
        );
      }
    } else {
      setState((prevState) => ({ ...prevState, emailError: true }));
    }
  };

  const idCheck = () => {
    let studyModuleSlug = convertTextToSlug(state.studyModuleState);
    if (
      (user?.subscription_name === "Basic Subscription" &&
        user?.free_trial === false &&
        !paidModules.includes(studyModuleSlug)) ||
      !getApiKey()
    ) {
      return "";
    } else {
      return "summary";
    }
  };

  const loginRoute = () => {
    if (locationHook.pathname === "/") {
      history.push("/login");
    } else {
      setState((prev) => ({
        ...prev,
        loginModal: true,
      }));
    }
  };

  const signUpRoute = () => {
    if (locationHook.pathname === "/") {
      history.push("/signup");
    } else {
      setState((prev) => ({
        ...prev,
        signUpModal: true,
      }));
    }
  };

  const handleUserLogin = async (body) => {
    setState((prev) => ({
      ...prev,
      loader: true,
    }));
    userLogin(body, history);
  };

  const onSignUp = async (values) => {
    setState((prev) => ({
      ...prev,
      loader: true,
    }));
    if (state.checkBox === true) {
      userSignUp(values, history);
    } else {
      dispatch(
        createErrorAlert(
          201,
          "Error",
          "Please agree to the terms and Conditions",
          201
        )
      );
    }
  };

  const checked = (e) => {
    if (e === true) {
      setState((prev) => ({
        ...prev,
        checkbox: true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        checkbox: false,
      }));
    }
  };

  const extractTextFromPDF = async (pdfUrl) => {
    const loadingTask = getDocument(pdfUrl);
    const pdf = await loadingTask.promise;
    const numPages = pdf.numPages;
    let text = "";

    for (let pageNum = 1; pageNum <= numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const textContent = await page.getTextContent();
      text += textContent.items.map((item) => item.str).join(" ") + " ";
    }

    return text;
  };

  const handleSearch = async (term) => {
    setSearchTerm(term);
    if (term) {
      const regex = new RegExp(`(${term})`, "gi");
      const results = term.split(/\s+/).filter((word) => word.match(regex));
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const highlightPattern = (textItem) => {
    if (!searchTerm) return textItem.str;
    const regex = new RegExp(`(${searchTerm})`, "gi");
    const splitText = textItem.str.split(regex);
    return splitText.map((part, index) =>
      regex.test(part) ? (
        <mark
          key={index}
          style={{ backgroundColor: "yellow", padding: 0, margin: 0 }}
        >
          {part}
        </mark>
      ) : (
        part
      )
    );
  };

  const contentCheck = () => {
    if (
      state.content !== undefined &&
      state.content !== "" &&
      state.content !== null
    ) {
      return "content";
    } else {
      if (state.documentSingle === undefined && state.content === "") {
        setState((prev) => ({
          ...prev,
          documentSingle: true,
        }));
      }
      return "shortDocument";
    }
  };

  const handleRightPage = () => {
    setState((prevState) => {
      return {
        ...prevState,
        pageNumber: prevState.pageNumber + 1,
      };
    });
  };

  console.log(state.content)

  const handleJoinClick = () => {
    window.open("https://linktr.ee/SimpleStudying_studygroups", "_blank");
  };

  const handleLeftPage = () => {
    setState((prevState) => {
      return {
        ...prevState,
        pageNumber: prevState.pageNumber - 1,
      };
    });
  };

  const userDetail = () => {
    history.push("/subscription");
  };

  console.log(state.content, "content")

  const { totalPages, pageNumber, pagePreviews, pageScale, loading } = state;

  return (
    <div>
      {state.loadingState ? <FullLoader /> : null}
      <div className="bg-white bg-primary-bottom-bg bg-no-repeat bg-bottom">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{slugToText(params?.slug2)}</title>
          <meta name="description" content={state.description} />
          {state.canonical ? (
            <link rel="canonical" href={state.canonical} />
          ) : null}
          <meta property="og:locale" content="en_US" />
          <meta property="og:locale:alternate" content="en_gb" />
          <meta property="og:type" content="website" />
          <meta name="title" content={state.firstTimeTitle} />
          <meta name="keywords" content={state.keywords} />
          <meta property="og:title" content={state.firstTimeTitle} />
          <meta property="og:description" content={state.ogDescription} />
          <meta property="og:url" content={state.ogUrl} />
        </Helmet>
        {/* <Container> */}
        {state.documentFull === false && !state.audioState && getApiKey() ? (
          <div
            className="notesReadSec w-full flex flex-col md:flex-row justify-center items-center mt-4 md:mt-8 lg:mt-14 rounded-3xl"
            style={{
              border: "1px solid #D8D8D8",
              boxShadow: "4px 8px 2px rgba(2, 23, 54, 0.03)",
              marginBottom: "40px",
            }}
          >
            <p className="font-bold text-14 text-center px-4 md:px-0 pt-2 md:pt-0 md:text-16 md:mr-2 lg:mr-0 lg:text-h5">
              This is the brief summary of the topic. To achieve high grades,
              you should
            </p>
            <div className="px-4 md:px-0 w-full md:w-48 py-4 lg:ml-10">
              <Button filled onClick={viewDocument}>
                <p className="text-16 md:text-body-large font-normal">
                  Read our notes
                </p>
              </Button>
            </div>
          </div>
        ) : !getApiKey() ? <div className="p-4 border border-2 border-primary rounded-lg" style={{backgroundColor: '#ffe3dd'}}>
          <p className="font-bold text-darker">Important Notice:</p>
        <p className="pt-8">Our study materials are created by top students and graduates who have excelled in the same courses you're taking. 
          These expertly crafted notes are designed to save you countless hours of studying while supercharging your grades.
          <br/>
          <br/>
        Why sift through thousands of pages of complicated textbooks when you can rely on our concise, straightforward, 
        and laser-focused study notes to achieve your academic goals?</p>
        <br/>
        <p className="flex"><p className="text-primary underline cursor-pointer" onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    signUpModal: true
                  }))
                }}>Sign up</p>
                <span className="mx-2">or</span> {/* Add some margin here */}
                <p className="mr-2 text-primary cursor-pointer underline" onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    loginModal: true
                  }))
                }}>Login</p> today to unlock access and start improving your grades now!</p>
      </div>: null}
        <div className="flex md:flex-row  flex-col gap-2 bg-white px-2">
          {state.checkListDisplay === true &&  (user?.subscription_name === "Basic Subscription" || !getApiKey()) && (
            <>
              <div
                className="flex w-full md:w-1/4 justify-center pt-16 flex-wrap "
                style={{ userSelect: "none" }}
              >
                <div className="w-full  md:block   checkListNowSec">
                  <div className="w-full checkListNowSubSec">
                    <h2 className="mb-3.5 text-center">
                      <span>
                        {moduleCheck() === "firstCheck" ? (
                          <strong>
                            Aiming for a First Class?
                            <br />
                            <br /> We have created a well-tested method to help
                            students maximise their chances of getting high
                            grades. Our study technique guide contains important
                            information about how to prepare for your
                            assessments.
                          </strong>
                        ) : moduleCheck() === "secondCheck" ? (
                          <strong>
                            Do you want to improve your grades?
                            <br />
                            <br /> Our study materials are designed specifically
                            for maximising your chances of getting a First
                            Class. Our materials are  created by top graduates
                            from UK universities.{" "}
                          </strong>
                        ) : (
                          <strong>
                            Want to secure high grades in {state.studyModuleState}?
                          </strong>
                        )}
                      </span>
                    </h2>
                    <p className="mb-3.5 text-center">
                      {moduleCheck() === "firstCheck" ||
                      moduleCheck() === "secondCheck" ? (
                        ""
                      ) : (
                        <span>
                          We’ve created a FREE checklist for you to maximise
                          your chances of getting high grades!
                        </span>
                      )}
                    </p>
                  </div>

                  <form>
                    <div class="relative z-0 w-full mb-6 group">
                      <input
                        type="email"
                        name="emailTwo"
                        style={
                          state.emailError === true
                            ? { border: "1px solid red" }
                            : { border: "1px solid green" }
                        }
                        class="emailTwo block py-2.5 px-0 w-full text-sm "
                        placeholder="Enter your email here "
                        required
                        value={state.emailCheckList}
                        onChange={(e) =>
                          setState((prevState) => ({
                            ...prevState,
                            emailCheckList: e.target.value,
                          }))
                        }
                      />
                    </div>

                    <div className="mt-5 mb-7 w-full xl:mt-9 xl:mb-14 mx-auto">
                      <Button
                        type="submit"
                        className="buttonClass"
                        onClick={handleDownloadCheckList}
                      >
                        <p className="font-normal text-15 md:text-small-text xl:text-body-large">
                          {moduleCheck() !== "secondCheck"
                            ? "Download The Checklist Now"
                            : "Submit"}
                        </p>
                      </Button>
                    </div>
                  </form>
                  <div className="mt-24">
                    {(user?.subscription_name === "Basic Subscription" || !getApiKey()) ? (
                      <>
                        <p>
                          Join our law study groups to get free study resources
                          and tips on how to get high grades in your exams and
                          courseworks
                        </p>
                        <div className="h-12">
                          <Button
                            className={"mt-10"}
                            filled
                            text={"Join"}
                            onClick={handleJoinClick}
                          ></Button>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}
          <div
            className="flex  lg:justify-center w-full  sm:gap-1  pt-16  lg:flex-row sm:flex-col "
            style={{
              userSelect: "none",
              width: "78%",
            }}
          >
            <div className={!getApiKey() && "w-full  lg:w-65%"} style={{}}>
              {contentCheck() === "content" &&
                !state.documentFull &&
                !state.audioState && (
                  <div
                    id={idCheck()}
                    dangerouslySetInnerHTML={{ __html: state.content }}
                  ></div>
                )}
              {user?.subscription_name === "Basic Subscription" &&
              user?.free_trial === false &&
              user?.user_type !== "TEACHER" &&
              !paidModules?.includes(
                convertTextToSlug(state.studyModuleState)
              ) ? (
                <div className="px-4 md:px-0 w-full md:w-full py-4 lg:ml-10">
                  {/* <div className="border-2 rounded-lg bg-white py-4 w-full px-4">
                    <p className="text-16 md:text-body-large font-normal mb-3 text-center">
                      You currently have a free subscription that only gives you
                      access to limited content. You can unlock for just
                      £1.99/month to improve your grades and save thousands of
                      hours in reading
                    </p>
                    <Button filled onClick={() => userDetail()}>
                      <p
                        className="text-16 md:text-body-large font-normal"
                        style={{ textAlign: "center" }}
                      >
                        Upgrade your membership to view the full Note
                      </p>
                    </Button>
                  </div> */}
                </div>
              ) : null}
              {contentCheck() === "shortDocument" &&
                !state.documentFull &&
                !state.audioState && (
                  <Document
                    file={state.pdf}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      pageNumber={1}
                      renderTextLayer={true}
                      customTextRenderer={highlightPattern}
                    />
                  </Document>
                )}

              {state.audioState && (
                <AudioPlayer audioState={state.audioState} />
              )}
              {!getApiKey() && (
                <div>
                  {/* <div className="px-4 md:px-0 w-full md:w-1/2 py-4 m-auto">
                    <div className="border-2 rounded-lg bg-white py-4 w-full">
                      <p className="text-16 md:text-body-large font-normal mb-3 text-center">
                        One moment before you access the full content..
                      </p>
                      <p className="text-16 md:text-body-large font-normal mb-3 text-center">
                        Please{" "}
                        <span
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={signUpRoute}
                        >
                          sign up
                        </span>{" "}
                        or{" "}
                        <span
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={loginRoute}
                        >
                          log in
                        </span>{" "}
                        to read full Notes
                      </p>
                    </div>
                  </div> */}
                </div>
              )}
            </div>
            {getApiKey() &&
            contentCheck() !== "shortDocument" &&
            state.documentFull === false &&
            user?.subscription_name !== "Basic Subscription" ? (
              <div className={`m:w-56 md:${state.content === null ? 'ml-36' : 'ml-0'} lg:${state.content === (null || "") ? 'ml-96' : 'ml-0'} mt-16 xl:${state.content === (null || "") ? 'ml-96' : 'ml-0'} mt-16 2xl:${state.content === (null || "") ? 'ml-96' : 'ml-0'} mt-16 w-full`} style={{width: '100%'}}>
                <StudyMaterialCard
                        img={"https://simplestudying.com/static/media/purple-card-image.733abc6c.webp"}
                        imageClass="w-32 h-32 xl:w-36 xl:h-36 pt-6 md:pt-10 lg:pt-14"
                        alt="Purple card image"
                        color="bg-purple"
                        data={data?.content}
                        text={
                          <p className="text-center font-bold text-16 xl:text-h5 xlfont-bold text-white pt-14 xl:pt-10">
                           Sell your Study Materials to Generate Value from your Knowledge
                          </p>
                        }
                        description={
                          <p className="text-small-text xl:text-body-large text-white">
                            <div style={{width: '185px'}}>
                              Publish your notes and essays and get recurring monthly revenues
                            </div>
                          </p>
                        }
                      />
                <div className=" testiSLiderWrapper bg-white bg-contain bg-no-repeat pt-10 lg:pt-20 px-4 md:px-6 xl:px-12">
                  <p className="mb-12 text-h5 text-center md:text-h4 font-bold text-darkest xl:text-h3">
                    Testimonials
                  </p>

                  <div className="testiSliderMain">
                    {!getApiKey() && contentCheck() !== "shortDocument" && (
                      <Slider
                        responsive={{
                          superLargeDesktop: {
                            // the naming can be any, depends on you.
                            breakpoint: { max: 4000, min: 1919 },
                            items: 1,
                          },
                          desktop: {
                            breakpoint: { max: 1919, min: 1500 },
                            items: 1,
                          },
                          ipadPro: {
                            breakpoint: { max: 1500, min: 1024 },
                            items: 1,
                          },
                          tablet: {
                            breakpoint: { max: 1023, min: 768 },
                            items: 1,
                          },
                          tabletBelow: {
                            breakpoint: { max: 767, min: 465 },
                            items: 1,
                          },
                          mobile: {
                            breakpoint: { max: 464, min: 0 },
                            items: 1,
                          },
                        }}
                      >
                        {testimonials?.map((item, index) => {
                          return (
                            <div className="slide-content sm:w-4/4 md:w-2/2 mx-auto px-8">
                              <div class="rating-stars gap-3 flex justify-center">
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                              </div>
                              <p
                                className="text-center my-2 testiContentP"
                                dangerouslySetInnerHTML={{
                                  __html: item?.text,
                                }}
                              ></p>
                              <h5 className="text-center my-3">{item?.name}</h5>
                            </div>
                          );
                        })}
                      </Slider>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              state?.documentFull === false && 
              <>
                <div className="w-full lg:w-35% h-2/3">
                <div className={`m:w-56 md:${state.content === null ? 'ml-36' : 'ml-0'} lg:${state.content === (null || "") ? 'ml-96' : 'ml-0'} mt-16 xl:${state.content === (null || "") ? 'ml-96' : 'ml-0'} mt-16 2xl:${state.content === (null || "") ? 'ml-96' : 'ml-0'} mt-16 w-full`} style={{width: '100%'}}>
                <StudyMaterialCard
                        img={"https://simplestudying.com/static/media/purple-card-image.733abc6c.webp"}
                        imageClass=" w-32 h-32 xl:w-36 xl:h-36 pt-6 md:pt-10 lg:pt-14"
                        alt="Purple card image"
                        color="bg-purple"
                        data={data?.content}
                        text={
                          <p className="text-center font-bold text-16 xl:text-h5 xlfont-bold text-white pt-14 xl:pt-10">
                           Sell your Study Materials to Generate Value from your Knowledge
                          </p>
                        }
                        description={
                          <p className="text-small-text xl:text-body-large text-white">
                            <div className="sm:w-84 lg:w-84 xl:w-96 2xl:w-84 w-full">
                              Publish your notes and essays and get recurring monthly revenues
                            </div>
                          </p>
                        }
                      />
                </div>
                
                      {user?.is_law === true && user.user_currency === "UK" && 
                      <div className={`sm:w-56 md:${state.content === null ? 'ml-36' : 'ml-0'} lg:${state.content === (null || "") ? 'ml-96' : 'ml-0'} mt-16 xl:${state.content === (null || "") ? 'ml-96' : 'ml-0'} mt-16 2xl:${state.content === (null || "") ? 'ml-96' : 'ml-0'} mt-16 w-full`} style={{width: '100%'}}>
                      <StudyMaterialCard
                        img={
                          "https://simplestudying.com/static/media/green-card-image.5f3b47f3.webp"
                        }
                        imageClass="w-32 h-32 xl:w-36 xl:h-36 pt-6 md:pt-10 lg:pt-14"
                        alt="Green card image"
                        color="bg-offGreen"
                        data={data?.content}
                        text={
                          <p className="text-center text-16 xl:text-h5 xlfont-bold text-white pt-14 xl:pt-10">
                            Get Study Materials and Tutoring <br /> to Improve
                            your Grades
                          </p>
                        }
                        description={
                          <p className="text-small-text xl:text-body-large text-white">
                            <div className="flex relative caseSumGreenMain">
                              <img
                                src={InfoImg}
                                alt="info-img"
                                className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                                onMouseEnter={() => {
                                  setState((prev) => ({
                                    ...prev,
                                    showInfo: true,
                                  }));
                                }}
                                onMouseLeave={() => {
                                  setState((prev) => ({
                                    ...prev,
                                    showInfo: false,
                                  }));
                                }}
                              />
                              {state.showInfo && (
                                <div className=" caseSumTTSubSec tooltipSSec absolute z-10 top-12  -left-6 h-auto">
                                  <InfoBox
                                    text={
                                      "<p>At StudyCrowd.AI, we built a team of successful law graduates who recently were in your position and achieved 2.1 or First Class in their respective law degrees. We summarised and simplified the overcomplicated information for you. We created simple notes with exam tips, case summaries, sample essays, tutorial videos, quizzes and flashcards <b>all specifically designed for you to get a First Class in the simplest way possible.</b></p>"
                                    }
                                  />
                                </div>
                              )}
                              Studying Materials and pre-tested tools helping you
                              to get high grades
                            </div>
                            <div className="flex relative caseSumGreenMain">
                              <img
                                src={InfoImg}
                                alt="info-img"
                                className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                                onMouseEnter={() => {
                                  setState((prev) => ({
                                    ...prev,
                                    showInfo2: true,
                                  }));
                                }}
                                onMouseLeave={() => {
                                  setState((prev) => ({
                                    ...prev,
                                    showInfo2: false,
                                  }));
                                }}
                              />
                              {state.showInfo2 && (
                                <div className=" caseSumTTSubSec tooltipSSec absolute z-10 top-12  -left-6 h-auto">
                                  <InfoBox
                                    text={
                                      "<p>Save time, study more efficiently. On average, StudyCrowd.AI will save you 738 hours of reading time per year compared to the course textbooks. That's 2213 LESS pages to read per year!</p>"
                                    }
                                  />
                                </div>
                              )}
                              Save 738 hours of reading per year compared to
                              textbooks
                            </div>
                            <div className="flex relative caseSumGreenMain">
                              <img
                                src={InfoImg}
                                alt="info-img"
                                className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                                onMouseEnter={() => {
                                  setState((prev) => ({
                                    ...prev,
                                    showInfo3: true,
                                  }));
                                }}
                                onMouseLeave={() => {
                                  setState((prev) => ({
                                    ...prev,
                                    showInfo3: false,
                                  }));
                                }}
                              />
                              {state.showInfo3 && (
                                <div className=" caseSumTTSubSec tooltipSSec absolute z-10 top-12  -left-6 h-auto">
                                  <InfoBox
                                    text={
                                      "<p>6 tutoring sessions + our study materials = 90% chance of a First Class </p>"
                                    }
                                  />
                                </div>
                              )}
                              Maximise your chances of a First Class with our
                              personalised support
                            </div>
                          </p>
                        }
                      />
                    </div>}
                  
                  <div className=" testiSLiderWrapper bg-white bg-contain bg-no-repeat pt-10 lg:pt-20 px-4 md:px-6 xl:px-12">
                    {/* <p className="mb-12 text-h5 text-center md:text-h4 font-bold text-darkest xl:text-h3">
                      Testimonials
                    </p> */}

                    <div className="testiSliderMain">
                      {!getApiKey() && contentCheck() !== "shortDocument" && (
                        <Slider
                          responsive={{
                            superLargeDesktop: {
                              // the naming can be any, depends on you.
                              breakpoint: { max: 4000, min: 1919 },
                              items: 1,
                            },
                            desktop: {
                              breakpoint: { max: 1919, min: 1500 },
                              items: 1,
                            },
                            ipadPro: {
                              breakpoint: { max: 1500, min: 1024 },
                              items: 1,
                            },
                            tablet: {
                              breakpoint: { max: 1023, min: 768 },
                              items: 1,
                            },
                            tabletBelow: {
                              breakpoint: { max: 767, min: 465 },
                              items: 1,
                            },
                            mobile: {
                              breakpoint: { max: 464, min: 0 },
                              items: 1,
                            },
                          }}
                        >
                          {testimonials?.map((item, index) => {
                            return (
                              <div className="slide-content sm:w-4/4 md:w-2/2 mx-auto px-8">
                                <div class="rating-stars gap-3 flex justify-center">
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                </div>
                                <p
                                  className="text-center my-2 testiContentP"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.text,
                                  }}
                                ></p>
                                <h5 className="text-center my-3">
                                  {item?.name}
                                </h5>
                              </div>
                            );
                          })}
                        </Slider>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {state.documentFull && (
            <div className="relative-div">
              {state.hide === true ? (
                <button
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      hide: false,
                    }))
                  }
                  className="show-btn"
                >
                  <FaEye />
                </button>
              ) : (
                <button
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      hide: true,
                    }))
                  }
                  className="show-btn"
                >
                  <FaEyeSlash />
                </button>
              )}
              <div className="mainDiv" ref={viewerRef}>
                {state.hide === false && (
                  <div className="left-side">
                    <div className="leftSideHeader">
                      <h3>Table of Contents</h3>
                    </div>
                    <div className="leftSideContent">
                      {loading ? (
                        <Loader />
                      ) : (
                        pagePreviews.map((previewUrl, index) => (
                          <div
                            key={index}
                            className="boxOne"
                            // onClick={() => {
                            //   handleThumbnailClick(index);
                            // }}
                          >
                            <img
                              src={previewUrl}
                              alt={`Page ${index + 1}`}
                              style={{ width: "100%" }}
                            />
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                )}

                <div className="right-side relative">
                  <div className="button-container mr-auto flex justify-between items-center px-4">
                    <input
                      type={"text"}
                      onChange={(e) => handleSearch(e.target.value)}
                      className="inputField"
                      placeholder="Search"
                    />
                    <div className="flex gap-2">
                      {/* <button
                          className="button-back"
                          onClick={() => handleLeftPage()}
                          disabled={pageNumber === 1}
                        >
                          <IoMdArrowRoundBack
                            style={{
                              heigh: "20px",
                              width: "20px",
                              marginTop: "-4px",
                              color: "#fff",
                            }}
                          />
                        </button>
                        <button
                          className="button-back"
                          onClick={() => handleRightPage()}
                          disabled={pageNumber === totalPages}
                        >
                          <IoMdArrowRoundForward
                            style={{
                              heigh: "20px",
                              width: "20px",
                              marginTop: "-4px",
                              color: "#fff",
                            }}
                          />
                        </button> */}
                      <button
                        className="button-back"
                        onClick={() => handleZoom("out")}
                        disabled={pageScale <= 0.3}
                      >
                        <BsZoomOut
                          onClick={() => handleZoom("out")}
                          style={{
                            heigh: "20px",
                            width: "20px",
                            marginTop: "-4px",
                            color: "#fff",
                          }}
                        />
                      </button>
                      <button
                        className="button-back"
                        onClick={() => handleZoom("in")}
                        disabled={pageScale >= 3}
                      >
                        <BsZoomIn
                          onClick={() => handleZoom("in")}
                          style={{
                            heigh: "20px",
                            width: "20px",
                            marginTop: "-4px",
                            color: "#fff",
                          }}
                        />
                      </button>
                      <button
                        onClick={handleFullScreen}
                        className="button-back"
                      >
                        <RiFullscreenFill
                          className="button-icon"
                          style={{
                            heigh: "20px",
                            width: "20px",
                            marginTop: "-4px",
                            color: "#fff",
                          }}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="mt-20">
                    <Document
                      file={state.pdf}
                      onLoadSuccess={onDocumentLoadSuccess}
                      // loading={<Loader />}
                    >
                      {Array.from(new Array(totalPages), (el, index) => (
                        <Page
                          pageNumber={index + 1}
                          scale={pageScale}
                          renderTextLayer={true}
                          customTextRenderer={highlightPattern}
                        />
                      ))}
                    </Document>
                  </div>
                </div>
              </div>
              {/* <Document
                    file={state.pdf}
                    onLoadSuccess={onDocumentLoadSuccess}
                  // loading={<Loader />}
                  >
                    {Array.from(new Array(totalPages), (el, index) => (
                      <Page pageNumber={index + 1} scale={pageScale} renderTextLayer={true} customTextRenderer={highlightPattern} />
                    ))}
                  </Document> */}
            </div>
            // <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            //   <div
            //     style={{
            //       height: "720px",
            //       userSelect: "none",
            //       width: "900px",
            //       marginLeft: "-244px",
            //     }}
            //     className={"printPage"}
            //     id={"worker"}
            //   >
            //     <Viewer
            //       fileUrl={state.pdf !== null && state.pdf}
            //       plugins={[defaultLayoutPluginInstance]}
            //       defaultScale={1.5}
            //     />
            //   </div>
            // </Worker>
          )}
        </div>
        <Modal
          open={state.loginModal}
          onClose={() => setState((prev) => ({ ...prev, loginModal: false }))}
          focusTrapped={false}
          center
          classNames={{
            overlay: "custom-overlay-class",
            modal: "react-modal-class1",
          }}
        >
          <div className="flex gap-6">
            <div className="  flex-basis-60 w-full hidden md:block">
              <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
                Join the movement of{" "}
                <span className="text-yellowish">
                  26K savvy StudyCrowd.AI members
                </span>{" "}
                already empowered - and you're next
              </p>

              <p className="pr-2 text-body-small text-left pt-6  md:text-h5 xl:text-36 ">
                Discover the secret to success as you become part of our growing
                community.
              </p>
              <p className="text-body-small text-left pr-2 pt-6 md:text-h5 xl:text-36 ">
                Find simple and concise study resources created by top graduates
                who took the same courses as you at US and UK universities
              </p>
              <div className="flex justify-around mt-10 items-center flex-col lg:flex-row">
                {/* <div className=" flex-1 flex flex-col justify-center items-center text-center">
                  <div className="h-120">
                    <div className="w-116 h-100">
                      <img src={img1} className="m-auto mt-3 w-full h-full" />
                    </div>
                  </div>

                  <p className="  fw-bold text-42 text-yellowish mt-4">35+</p>
                  <p className="h-51">
                    Study Modules
                    <br />
                    All main areas of English law
                  </p>
                </div> */}
                <div className="flex-1 flex flex-col justify-center items-center text-center">
                  <div className="h-120">
                    <div className="w-100 h-100">
                      <img src={img2} className="m-auto mt-3 w-full h-full" />
                    </div>
                  </div>

                  <p className="fw-bold text-42  text-yellowish mt-4">75K</p>
                  <p>
                    Active Users
                    <br />
                    in 2024
                  </p>
                </div>
                <div className="flex-1 flex flex-col justify-center items-center text-center">
                  <div className="h-120">
                    <div className="w-100 h-100">
                      <img src={img3} className="m-auto mt-3 w-full h-full" />
                    </div>
                  </div>

                  <p className="fw-bold text-42  text-yellowish mt-4">1600+</p>
                  <p className="h-51">
                    Tutoring Hours <br />
                    Delivered
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-basis-40">
              <Formik
                initialValues={{
                  password: "",
                  email: "",
                }}
                validationSchema={validateSchema}
                onSubmit={(values) => handleUserLogin(values)}
              >
                {({ isValid, handleSubmit, values }) => (
                  <Form>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div className="loginInputMain flex flex-col text-darkest items-center">
                        <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                          Sign in to{" "}
                          <span className="text-yellowish">StudyCrowd.AI</span>
                        </p>
                        <div className="w-full mb-3 custom2::mb-9 ">
                          <GoogleAuthentication />
                          <p className="mb-8 mt-16">Username or Email</p>
                          <Field
                            name="email"
                            as={Input}
                            autoComplete="off"
                            placeholder="Enter your email"
                            type="text"
                          />
                        </div>
                        <div className="w-full  mb-3 custom2:mb-9">
                          <p className="mb-8 ">Password</p>
                          <Field
                            name="password"
                            as={Input}
                            placeholder="Enter your password"
                            type={state.showPassword ? "text" : "password"}
                            setShowPassword={setState}
                            showPassword={state.showPassword}
                            inputType="password"
                            autoComplete="off"
                          />
                        </div>
                        <Link
                          className="mb-8 forgetText "
                          onClick={() => {
                            setState((prev) => ({
                              ...prev,
                              loginModal: false,
                            }));
                            history.push("/forget-password");
                          }}
                        >
                          Forget Password?
                        </Link>
                      </div>
                      <div className="pt-4 flex justify-center mb-10">
                        <div className="w-full h:34 md:h-42 lg:h-51">
                          <Button
                            onClick={handleSubmit}
                            text="Log in"
                            filled
                            className="buttonClass"
                            disabled={
                              values.email === "" ||
                              values.password === "" ||
                              !isValid
                            }
                            loading={state.loader}
                          />
                        </div>
                      </div>
                    </form>
                  </Form>
                )}
              </Formik>
              <p className="loginBottomText font-normal text-darkest font-body-large text-center">
                You don't have an account yet?{" "}
                <a
                  className="font-bold text-primary cursor-pointer"
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      loginModal: false,
                      signUpModal: true,
                    }));
                  }}
                >
                  {"  "}Sign up
                </a>
              </p>
            </div>
          </div>
        </Modal>

        {/* Sign Up Modal */}
        <Modal
          open={state.signUpModal}
          onClose={() =>
            setState((prev) => ({
              ...prev,
              signUpModal: false,
            }))
          }
          focusTrapped={false}
          center
          classNames={{
            overlay: "custom-overlay-class",
            modal: "react-modal-class1",
          }}
        >
          <div className="flex gap-6">
            <div className="  flex-basis-60 w-full hidden md:block">
              <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
                Join the movement of{" "}
                <span className="text-yellowish">
                  26K savvy StudyCrowd.AI members
                </span>{" "}
                already empowered - and you're next
              </p>

              <p className=" text-body-small pr-2 text-left pt-6  md:text-h5 xl:text-36 ">
                Discover the secret to success as you become part of our growing
                community.
              </p>
              <p className="text-body-small pr-2 text-left pt-6 md:text-h5 xl:text-36 ">
                Find simple and concise study resources created by top graduates
                who took the same courses as you at US and UK universities
              </p>
              <div className="flex justify-around mt-10 items-center flex-col lg:flex-row">
                {/* <div className=" flex-1 flex flex-col justify-center items-center text-center">
                  <div className="h-120">
                    <div className="w-116 h-100">
                      <img src={img1} className="m-auto mt-3 w-full h-full" />
                    </div>
                  </div>

                  <p className="  fw-bold text-42 text-yellowish mt-4">35+</p>
                  <p className="h-51">
                    Study Modules
                    <br />
                    All main areas of English law
                  </p>
                </div> */}
                <div className="flex-1 flex flex-col justify-center items-center text-center">
                  <div className="h-120">
                    <div className="w-100 h-100">
                      <img src={img2} className="m-auto mt-3 w-full h-full" />
                    </div>
                  </div>

                  <p className="fw-bold text-42  text-yellowish mt-4">75K</p>
                  <p>
                    Active Users
                    <br />
                    in 2024
                  </p>
                </div>
                <div className="flex-1 flex flex-col justify-center items-center text-center">
                  <div className="h-120">
                    <div className="w-100 h-100">
                      <img src={img3} className="m-auto mt-3 w-full h-full" />
                    </div>
                  </div>

                  <p className="fw-bold text-42  text-yellowish mt-4">1600+</p>
                  <p className="h-51">
                    Tutoring Hours <br />
                    Delivered
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-basis-40">
              <Formik
                initialValues={{
                  password1: "",
                  first_name: "",
                  username: "",
                  email: "",
                  password2: "",
                }}
                validationSchema={validateSchemaSignup}
                onSubmit={(values) => onSignUp(values)}
              >
                {({ isValid, handleSubmit, values, setFieldValue }) => (
                  <Form>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="flex flex-col text-darkest items-center">
                        <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                          Sign up to{" "}
                          <span className="text-yellowish">StudyCrowd.AI</span>
                        </p>
                        <div className="w-full mb-3 md:mb-9 ">
                          <GoogleAuthentication />
                          <p className="mb-8 mt-16">Email</p>
                          <Field
                            name="email"
                            as={Input}
                            placeholder="Enter your email"
                            type="text"
                            onChange={(e) => {
                              const email = e.target.value;
                              const username = email.split("@")[0]; // Take the part before @ as the username
                              setFieldValue("email", email);
                              setFieldValue("username", username);
                            }}
                          />
                        </div>

                        <div className="w-full mb-3 md:mb-9 ">
                          <p className="mb-8">First Name</p>
                          <Field
                            name="first_name"
                            as={Input}
                            placeholder="Enter your First Name"
                            type="text"
                          />
                        </div>

                        <div className="w-full ">
                          <p className="mb-8">Password</p>
                          <Field
                            name="password1"
                            as={Input}
                            placeholder="Enter your password"
                            type={state.showPassword ? "text" : "password"}
                            setShowPassword={setState}
                            showPassword={state.showPassword}
                            inputType="password"
                          />
                          <p className="text-small text-darkGrey2 my-8">
                            6+ characters, 1 capital letter{" "}
                          </p>
                        </div>
                        <div className="w-full mb-10 md:mb-14">
                          <p className="mb-8">Confirm password</p>
                          <Field
                            name="password2"
                            as={Input}
                            placeholder="Enter your confirm password"
                            type={
                              state.showConfirmPassword ? "text" : "password"
                            }
                            setShowPassword={setState}
                            showPassword={state.showConfirmPassword}
                            inputType="password"
                          />
                        </div>
                        <div
                          className="w-full mb-10 md:mb-14"
                          style={{ display: "flex" }}
                        >
                          <label className="condContainer">
                            {" "}
                            I agree to{" "}
                            <a
                              style={{ color: "#cc6828", cursor: "pointer" }}
                              onClick={() => {
                                history.push("/terms-conditions");
                              }}
                            >
                              Terms and Conditions
                            </a>
                            ,{" "}
                            <a
                              style={{ color: "#cc6828", cursor: "pointer" }}
                              onClick={() => {
                                history.push("/privacy-policy");
                              }}
                            >
                              Privacy Policy
                            </a>{" "}
                            and{" "}
                            <a
                              style={{ color: "#cc6828", cursor: "pointer" }}
                              onClick={() => {
                                history.push("/cookies-policy");
                              }}
                            >
                              Cookies Policy
                            </a>{" "}
                            of Simple Studying{" "}
                            <input
                              id="condBox"
                              onClick={(e) => checked(e.target.checked)}
                              type="checkbox"
                              className="default:ring-2 text-primary mr-3 checkBoxOne"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="pt-4 flex justify-center mb-10">
                        <div className="w-full h-51">
                          <Button
                            onClick={handleSubmit}
                            text="Sign up for free"
                            filled
                            className="buttonClass"
                            disabled={
                              values.first_name === "" ||
                              values.password1 === "" ||
                              values.password2 === "" ||
                              values.email === "" ||
                              !isValid ||
                              values.checked === "false"
                            }
                            loading={state.loader}
                          />
                        </div>
                      </div>
                    </form>
                  </Form>
                )}
              </Formik>
              <p className="font-normal text-darkest font-body-large text-center">
                Already have an account?{" "}
                <a
                  className="font-bold text-primary cursor-pointer"
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      loginModal: true,
                      signUpModal: false,
                    }));
                  }}
                >
                  {"  "}Log in
                </a>
              </p>
            </div>
          </div>
        </Modal>
        {!params?.slug1.includes('law') ? <LockPreview onClick={() => {
          if(!getApiKey()){
            setState((prev) => ({
              ...prev,
              loginModal: true
            }))
          } else{
            addToBasket()
          }
        }} module_name={slugToText(params?.slug2)} price={`${state.currency === 'usd' ? '$' : '£'}${state.price / 100}`} /> : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: notesLoading(state),
  error: notesError(state),
  relevant: notesData(state),
  checkListData: checkListData(state),
  dataCatalogue: catalogueData(state),
});
const mapDispatchToProps = (dispatch) => ({
  getNotes: (page, title, slug) => dispatch(getNotes()),
  getCheckList: () => dispatch(checkListAction()),
  postMailChimp: (email, study) => dispatch(postMailChimpAction(email, study)),
  userSignUp: (body, history) => dispatch(userSignUp(body, history)),
  userLogin: (body, history) => dispatch(userLogin(body, history)),
  getCatalogue: (id) => dispatch(getCatalogue(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaidUserNotes);
