import React from "react";
import lockImage from '../../assets/images/Object.png'
import Button from "../../components/EmptyButton";


const LockPreview = ({module_name, price, onClick}) => {
    return(
        <div className="flex justify-center items-center">
            <div className="bg-black w-96 pt-8 pb-8 text-white rounded-2xl mt-4">
                <div className="flex justify-center items-center">
                    <img src={lockImage} width={20} />
                    <p className="ml-8">This is a preview</p>
                </div>
                <div className="text-center text-small-text mt-16">
                    <p>If you want to unlock the document, go premium</p>
                </div>
                <div className="pl-8 pr-8 mt-4">
                    <Button filled onClick={onClick}>
                        <p>Get All {module_name} for only {price} </p>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default LockPreview