import React, { useEffect, useState, useContext } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../components/container";
import Stepper from "../../components/SignUpStepper";
import {
  packageData,
  packageError,
  packageLoading,
  studyModulesData,
  tutorData,
} from "../../redux/selectors";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Input from "../../components/Input/InputComponent";
import Button from "../../components/EmptyButton";
import img1 from "../../assets/newImages/Group 1399.png";
import img3 from "../../assets/newImages/Group 1403.png";
import img2 from "../../assets/newImages/Illustration.png";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";
import {
  createErrorAlert,
  getPackage,
  getSpecificStudyModules,
  getTutor,
  userLogin,
} from "../../redux/actions";
import GoogleAuthentication from "../../components/SocialAuthentication/GoogleSocialAuth";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import checkmark from "../../assets/images/Checkmark.svg";
import TutorCard from "./TutorCard";
import pkgImg from "../../assets/images/packageImg.png";
import Vector from "../../assets/images/Vector.png";
import InfoImg from "../../assets/icons/information.svg";
import FullLoader from "../../components/Loader/FullLoader";
import Context from "../../services/Context";
import { getApiKey, getUser } from "../../helpers";
import Select from "../../components/Select";
import convertTextToSlug from "../../helpers/textToSlug";

const TutorSelect = (props) => {
  const { getPackage, getTutor, data, tutor, userLogin, getStudyModules, studyModulesData } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const param = useParams();

  const { value, updateValue } = useContext(Context) || {};

  const { selectedModule } = value || {}

  const [state, setState] = useState({
    packages: null,
    selectedTutor: null,
    showInfo: false,
    loading: false,
    loginModal: false,
    signUpModal: false,
    loader: false,
    modules: [],
    sortedData: [],
    selectedModule: {},
    user: JSON.parse(getUser())
  });

  const userCurrency = value?.userCurrency || state.user?.user_currency

  const validateSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  useEffect(() => {
    getPackage(param.slug);
  }, []);
  useEffect(() => {
    if (value?.studyModulesData) {
      if (value?.studyModulesData.length > 0) {
        let recom = [];
        value?.studyModulesData.map((item) => {
          if (
            item.slug !== "macroeconomics" &&
            item.slug !== "microeconomics" &&
            item.slug !== "business" &&
            item.slug !== "marketing" &&
            item.slug !== "our-company" &&
            item.slug !== "useful-articles" &&
            item.slug !== "uncategorized" &&
            item.slug !== "test" &&
            item.slug !== "partners"
          ) {
            recom.push({
              value: item,
              label: item.title,
            });
          }
        });
        // eslint-disable-next-line no-undef
        let sortedd = _.sortBy(recom, "label");
        setState((prev) => ({
          ...prev,
          modules: sortedd
        }))
        let arr = [];
        let filter = recom.slice(0, 10);
        filter.forEach((e) => {
          if (
            e.value.slug !== "macroeconomics" &&
            e.value.slug !== "microeconomics" &&
            e.value.slug !== "business" &&
            e.value.slug !== "marketing" &&
            e.value.slug !== "our-company" &&
            e.value.slug !== "useful-articles" &&
            e.value.slug !== "uncategorized" &&
            e.value.slug !== "test" &&
            e.value.slug !== "partners"
          ) {
            arr.push(e);
            arr.sort();
          }
        });
        // eslint-disable-next-line no-undef
        let sortedTen = _.sortBy(arr, "label");
        for (let i = 0; i < sortedTen.length; i++) {
          state.sortedData.push(sortedTen[i]);
        }
      }
    }
  }, [value?.studyModulesData]);

  const bookTutor = (selectedPackages) => {
    let selectedModule = state.selectedModule;
    updateValue({ selectedPackages, selectedModule });
    history.push({
      pathname: `/tutoring/tutor-selection/${convertTextToSlug(selectedPackages.title)}`,
      state: {selectedPackage: selectedPackages.slug}
    });
  };

  const handleUserLogin = async (body) => {
    setState((prev) => ({
      ...prev,
      loader: true,
    }));
    userLogin(body, history);
  };

  const packageSelect = (item) => {
    if (!getApiKey()) {
      setState((prev) => ({
        ...prev,
        loginModal: true,
      }));
      return;
    }
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    let best = [...data];
    best.map((res) => {
      if (res.description === item.description) {
        res.selected = true;
      } else {
        res.selected = false;
      }
    });
    setState((prev) => ({
      ...prev,
      packages: item,
    }));
    if (item.slug) {
      getTutor(item.slug);
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
    bookTutor(item)
    localStorage.removeItem("dates");
  };

  // useEffect(() => {
  //   if (data.length > 0) {
  //     data.map((item) => {
  //       packageSelect(item)
  //     })
  //   }
  // }, [data])

  const handleModuleSelect = (value) => {
    setState((prev) => ({
      ...prev,
      selectedModule: value,
    }))
    updateValue((prev) => ({
      ...prev,
      selectedModule: value
  }))
  }

  const extractAndReplaceCustomBullet = (htmlString, item) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const ulElements = doc.querySelectorAll("ul");

    ulElements.forEach((ulElement) => {
      ulElement.style.listStyle = "none";

      const liElements = ulElement.querySelectorAll("li");
      liElements.forEach((liElement) => {
        const bulletImage = document.createElement("span");
        bulletImage.classList.add("custom-bullet");
        liElement.classList.add("packageSpan");
        liElement.insertBefore(bulletImage, liElement.firstChild);

        const liTextSpan = document.createElement("span");
        liTextSpan.textContent = liElement.textContent;

        liElement.innerHTML = "";

        if (liTextSpan.textContent === "Tutoring Session") {
          let anchore = document.createElement("a");
          anchore.href = "#";
          anchore.textContent = `x${item?.package_recurence}`;
          anchore.classList.add("themeClr");
          liTextSpan.style.width = "100%";
          liTextSpan.style.display = "flex";
          liTextSpan.style.justifyContent = "space-between";
          liTextSpan.appendChild(anchore);
        }

        liElement.appendChild(bulletImage);
        liElement.appendChild(liTextSpan);
        liElement.style.marginBottom = "10px";
      });
    });

    const modifiedHtmlString = new XMLSerializer().serializeToString(doc);

    return modifiedHtmlString;
  };

  return (
    <>
      <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat">
        {state.loading && <FullLoader />}
        <Stepper step={1} exit={() => history.push("/profile")} totalSteps={4} />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Simple Studying | Tutor Select</title>
          <meta name="description" content="Get High Grades with the support of top graduates who took the same courses as you" />
          <link
            rel="canonical"
            href="https://simplestudying.com/tutoring/tutor-select/one-2-one"
          />
          <meta name="robots" content="index, follow" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:locale:alternate" content="en_gb" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Simple Studying | Tutor Select" />
          <meta property="og:description" content="Get High Grades with the support of top graduates who took the same courses as you" />
          <meta
            property="og:url"
            content="https://simplestudying.com/tutoring/tutor-select/one-2-one"
          />
          <meta property="og:site_name" content="Simple Studying" />
        </Helmet>
        <Container>
          <div className="pt-10 lg:pt-14 flex">
            <div className="text-darkest">
              <p className="tutorialVidText text-36 custom2:text-36 font-bold mb-2 md:mb-4 lg:mb-10">
              Plan your tutoring Session
              </p>
            </div>
          </div>
          <section className="text-gray-600 body-font">
          <div className="flex flex-col md:flex-row md:space-x-7">
                <div className="md:w-56 lg:w-80 mb-5">
                  <p className="text-14 mb-4">Please choose the study module</p>
                  <Select
                    placeholder="Select Study Module"
                    values={state.modules}
                    isClearable={false}
                    value={
                      state.selectedModule && Object.keys(state.selectedModule).length !== 0
                        ? state.selectedModule
                        : (
                          selectedModule && 
                          Object.keys(selectedModule).length !== 0 &&
                          value && 
                          Object.keys(value).length !== 0
                        )
                        ? selectedModule
                        : ""
                    } 
                    handleSelected={handleModuleSelect}
                  />
                </div>
              </div>
              <p className="text-19 mb-11">
                For best results, in one hour, you should discuss with the tutor
                only one question in one study module e.g. problem question on
                negligence
              </p>
              <h1 className="text-center text-42">
                Please select the tutoring package
              </h1>
            <div className="container mx-auto pckgContainer">
              <div className="flex flex-wrap mt-2 md-mt-8 lg:mt-12 gap-2 mb-6 lg:mb-2 justify-center">
                {data.length > 0
                  ? data?.map((item, index) => {
                    // if(item.title.includes('One hour')){
                      return (
                        <div
                          className={`p-2 md:p-4 lg:w-1/3 forBoxBorder rounded-lg ${
                            item.selected === true &&
                            "border-4 boderClrbrown"
                          }`}
                          onClick={() => packageSelect(item)}
                        >
                          <div className="relative h-full bg-gray-100 bg-opacity-75 pt-2 rounded-lg overflow-hidden text-center relative">
                            <h1 className=" text-black text-center font-bold text-h3 pkg-title">
                              {item?.title}
                            </h1>
                            <div className="flex justify-center my-3">
                              <img
                                src={item?.price === 210 ? Vector : pkgImg}
                              />
                            </div>
                            <div className="flex justify-center">
                              <p className="pckgPriceText mt-2 themeClr bookApHeadText text-36 text-gray-400 mb-1 text-center fw-bold">
                                {userCurrency === "UK" ? "£" : "$"} {item?.price}
                              </p>
                              <p className=" line-through text-darkGrey text-20 pt-5 pl-2">
                                {item?.price === 210
                                  ? userCurrency === "UK" ? "£ " + 240 : "$ " + 240
                                  : item?.price === 105
                                    ? userCurrency === "UK" ? "£ " + 120 : "$ " + 120
                                    : null}
                              </p>
                            </div>
                            <p className=" relative text-center fw-bold text-black">
                              Price
                            </p>
                            <hr className=" opacity-20 mt-4" />
                            <div>
                              <p
                                className="mb-3 text-left mb-20 mt-4"
                                dangerouslySetInnerHTML={{
                                  __html: extractAndReplaceCustomBullet(
                                    item?.description,
                                    item
                                  ),
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      ); 
                    // }
                    })
                  : null}
              </div>
              {state.packages && (
                <>
                  <div className="p-5 mb-10">
                    <p>
                      <strong className="text-primary">Note</strong> all
                      materials and tutoring is geared to give you the best
                      chance of achieving the desired grades, with 90% of our
                      regular premium users having achieved 1st class grades.
                      However we cannot guarantee this and aren't liable.
                    </p>
                  </div>
                  <div>
                    {/* <div className=" flex items-center relative">
                      <p className="font-bold text-h3  mr-2 sm:text-14 md:text-h4 custom:text-h3 ">
                        Select Tutor
                      </p>
                      <img
                        src={InfoImg}
                        alt="info-img"
                        className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                        onMouseEnter={() =>
                          setState((prev) => ({
                            ...prev,
                            showInfo: true,
                          }))
                        }
                        onMouseLeave={() =>
                          setState((prev) => ({
                            ...prev,
                            showInfo: false,
                          }))
                        }
                      />
                      {state.showInfo && (
                        <p
                          className="tutorcard-info border-1 p-2 border-primary rounded-md fw-bold"
                          style={{
                            position: "absolute",
                            top: "-50px",
                            left: "30px",
                            boxShadow: "rgba(2, 23, 54, 0.1) 4px 8px 20px",
                          }}
                        >
                          ‘Click any tutor if you don’t have a preference’
                        </p>
                      )}
                    </div> */}
                    {/* <p className="mb-7">
                      All our tutors are more than qualified and experienced to
                      prepare you for any assignment, exam, dissertation or
                      mooting you may have. 90% of our regular users get a First
                      Class.
                    </p> */}
                  </div>

                  <div className="flex flex-wrap justify-between cursor-pointer">
                    {/* {tutor.length &&
                      tutor.map((item, index) =>
                        item.tutor.map((subItem) => (
                          <TutorCard
                            key={subItem.id}
                            dataItem={subItem}
                            isSelected={state.selectedTutor === subItem}
                            onSelect={setState}
                          />
                        ))
                      )} */}
                  </div>

                  {/* <div className="text-center mt-10">
                    <button
                      type="btn"
                      value="submit"
                      className="bookBtn"
                      onClick={() => bookTutor(state.packages)}
                    >
                      Book
                    </button>
                  </div> */}
                </>
              )}
            </div>
          </section>
        </Container>
      </div>
      <Modal
        open={state.loginModal}
        onClose={() => setState((prev) => ({ ...prev, loginModal: false }))}
        focusTrapped={false}
        center
        classNames={{
          overlay: "custom-overlay-class",
          modal: "react-modal-class1",
        }}
      >
        <div className="flex gap-6">
          <div className="  flex-basis-60 w-full hidden md:block">
            <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
              Join the movement of{" "}
              <span className="text-yellowish">26K savvy StudyCrowd.AI members</span> already
              empowered - and you're next
            </p>

            <p className="pr-2 text-body-small text-left pt-6  md:text-h5 xl:text-36 ">
              Discover the secret to success as you become part of our growing
              community.
            </p>
            <p className="text-body-small pr-2 text-left pt-6 md:text-h5 xl:text-36 "
            >
              Find simple and concise study resources created by top graduates who took the same courses as you at US and UK universities</p>
            <div className="flex justify-around mt-10 items-center flex-col lg:flex-row">
              {/* <div className=" flex-1 flex flex-col justify-center items-center text-center">
                <div className="h-120">
                  <div className="w-116 h-100">
                    <img src={img1} className="m-auto mt-3 w-full h-full" />
                  </div>
                </div>

                <p className="  fw-bold text-42 text-yellowish mt-4">35+</p>
                <p className="h-51">
                  Study Modules
                  <br />
                  All main areas of English law
                </p>
              </div> */}
              <div className="flex-1 flex flex-col justify-center items-center text-center">
                <div className="h-120">
                  <div className="w-100 h-100">
                    <img src={img2} className="m-auto mt-3 w-full h-full" />
                  </div>
                </div>

                <p className="fw-bold text-42  text-yellowish mt-4">75K</p>
                <p>
                  Active Users
                  <br />
                  in 2024
                </p>
              </div>
              <div className="flex-1 flex flex-col justify-center items-center text-center">
                <div className="h-120">
                  <div className="w-100 h-100">
                    <img src={img3} className="m-auto mt-3 w-full h-full" />
                  </div>
                </div>

                <p className="fw-bold text-42  text-yellowish mt-4">1600+</p>
                <p className="h-51">
                  Tutoring Hours <br />
                  Delivered
                </p>
              </div>
            </div>
          </div>
          <div className="flex-basis-40">
            <Formik
              initialValues={{
                password: "",
                email: "",
              }}
              validationSchema={validateSchema}
              onSubmit={(values) => handleUserLogin(values)}
            >
              {({ isValid, handleSubmit, values }) => (
                <Form>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="loginInputMain flex flex-col text-darkest items-center">
                      <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                        Sign in to{" "}
                        <span className="text-yellowish">StudyCrowd.AI</span>
                      </p>
                      <div className="w-full mb-3 custom2::mb-9 ">
                        <GoogleAuthentication />
                        <p className="mb-8 mt-16">Email</p>
                        <Field
                          name="email"
                          as={Input}
                          autoComplete="off"
                          placeholder="Enter your username or email"
                          type="text"
                        />
                      </div>
                      <div className="w-full  mb-3 custom2:mb-9">
                        <p className="mb-8 ">Password</p>
                        <Field
                          name="password"
                          as={Input}
                          placeholder="Enter your password"
                          type={state.showPassword ? "text" : "password"}
                          setShowPassword={setState}
                          showPassword={state.showPassword}
                          inputType="password"
                          autoComplete="off"
                        />
                      </div>
                      <Link
                        className="mb-8 forgetText "
                        onClick={() => {
                          setState((prev) => ({
                            ...prev,
                            loginModal: false,
                          }));
                          history.push("/forget-password");
                        }}
                      >
                        Forget Password?
                      </Link>
                    </div>
                    <div className="pt-4 flex justify-center mb-10">
                      <div className="w-full h:34 md:h-42 lg:h-51">
                        <Button
                          onClick={handleSubmit}
                          text="Log in"
                          filled
                          className="buttonClass"
                          disabled={
                            values.email === "" ||
                            values.password === "" ||
                            !isValid
                          }
                          loading={state.loader}
                        />
                      </div>
                    </div>
                  </form>
                </Form>
              )}
            </Formik>
            <p className="loginBottomText font-normal text-darkest font-body-large text-center">
              You don't have an account yet?{" "}
              <a
                className="font-bold text-primary cursor-pointer"
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    loginModal: false,
                    signUpModal: true,
                  }));
                }}
              >
                {"  "}Sign up
              </a>
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: packageLoading(state),
  error: packageError(state),
  data: packageData(state),
  tutor: tutorData(state),
  studyModulesData: studyModulesData(state),
});
const mapDispatchToProps = (dispatch) => ({
  getPackage: (slug) => dispatch(getPackage(slug)),
  getTutor: (tutorSlug) => dispatch(getTutor(tutorSlug)),
  userLogin: (body, history) => dispatch(userLogin(body, history)),
  getStudyModules: () => dispatch(getSpecificStudyModules()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TutorSelect);
