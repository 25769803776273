import React, {useEffect, useState} from 'react';
import Button from '../../components/EmptyButton';
import SignupImage3 from '../../assets/images/signupImage3.png';
import Input from '../../components/Input/InputComponent';
import {ForgetPass} from '../../services';
import * as Yup from 'yup';
import {Field, Form, Formik} from 'formik';
import ErrorMsg from '../../components/ErrorMsg';
import {createErrorAlert} from '../../redux/actions';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Helmet} from "react-helmet";

function ForgetPassword() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [status, setStatus] = useState('');
    const [variant, setVariant] = useState({
        color: 'white',
        background: 'black',
        status: false,
    });
    const validateSchema = Yup.object({
        email: Yup.string().email('Invalid email address.').required('Email is required.'),
    });

    useEffect(()=>{
        document.getElementById('loginDiv').style.display = 'none'
    },[])

    const handleSubmitPassword = (values, setSubmitting) => {
        setStatus('');
        setVariant({
            color: '',
            background: '',
            status: false,
        });
        ForgetPass(values.email).then((response) => {
            setStatus(response.data.detail+" Check your inbox to reset your password.");
            setTimeout(()=>{
                history.push('/login')
            },8000)
            setVariant({
                 color: 'black',
            background: 'lightgreen',
                status: true,
            });
            setSubmitting(false);
        }).catch(error => {
            setSubmitting(false);
            setVariant({
                 color: 'black',
            background: 'pink',
                status: false,
            });
            
            if (error.response?.data?.email?.length) {
                dispatch(createErrorAlert(400, 'Error', error.response.data.email[0], 400));
            } else {
                dispatch(createErrorAlert(400, 'Error', error.response.data.detail, 400));
            }
        });
        
    };
    return (
        <div className="bg-white bg-main-page-back w-full bg-no-repeat bg-cover">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Simple Studying | Forget Password</title>
                <meta name="description" content="Get High Grades with the support of top graduates who took the same courses as you"/>
                <link rel="canonical" href="https://simplestudying.com/forget-password"/>
                <meta name="robots" content="index, follow"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:locale:alternate" content="en_gb"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Simple Studying | Profile"/>
                <meta property="og:description" content="Get High Grades with the support of top graduates who took the same courses as you"/>
                <meta property="og:url" content="https://simplestudying.com/forget-password"/>
                <meta property="og:site_name" content="Simple Studying"/>
            </Helmet>
            <div className="px-4 md:px-36 custom2:px-0">
                <p className="mt-10 md:mt-28 custom2:mt-36 mb-14 font-bold text-h4 md:text-large-medium custom2:text-36 text-center text-darkest">
                    Forgot Password
                </p>
                
                <div className="pb-11 flex flex-col justify-center items-center">
                    <Formik
                        initialValues={{
                            password: '',
                        }}
                        validationSchema={validateSchema}
                        onSubmit={(values, {setSubmitting}) => handleSubmitPassword(values, setSubmitting)}
                    >
                        {({isValid, handleSubmit, values, isSubmitting}) => (
                            <Form>
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    {variant.status ? null : <>
                                        <div className="w-full md:w-472">
                                            <Field
                                                name="email"
                                                as={Input}
                                                placeholder="Enter your email"
                                                type="email"
                                            />
                                        </div>
                                    </>}
                                    {status ?
                                        <ErrorMsg error={status} color={variant.color}
                                                  background={variant.background}/>
                                        : null}
                                    <div className="w-full pt-4 flex justify-center mb-12 mt-8">
                                        <div className="w-full md:px-0 md:w-40 h-51">
                                            
                                            {variant.status ? <Button text="Login" filled
                                                                      onClick={() => history.push('/login')}/> :
                                                <Button onClick={handleSubmit} text="Send Email" filled
                                                        disabled={values.email === '' || !isValid}
                                                        loading={isSubmitting}/>}
                                        </div>
                                    </div>
                                    <p className="loginBottomText font-normal text-darkest font-body-large text-center mb-20 mt-2">
                                        Go Back to {" "}
                                        <a
                                            className="font-bold text-primary cursor-pointer"
                                            onClick={() => history.push("/login")}
                                        >
                                            {"  "}Login
                                        </a>
                                    </p>
                                </form>
                            </Form>)}
                    </Formik>
                    <img className="hidden md:block" src={SignupImage3} alt="image"/>
                </div>
            </div>
        </div>
    );
}

export default ForgetPassword;