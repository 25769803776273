import React, {useState} from 'react';
import LazyImage from '../LazyImage';
import InfoBox from "../InfoBox";

const StudyMaterials = ({image, cardTitle, price, handleClick, slug, contentDash, text, short_description, is_approved}) => {
    const [showInfo, setShowInfo] = useState(false);
    console.log(is_approved, 'aaa')

    return (

        <div className=" mx-auto bg-white w-11/12 md:w-11/12 lg:w-11/12 custom:w-11/12 xl:w-full xl:h-80"
        style={{
          boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.03)",
          borderRadius: "25px",
          cursor: "pointer",
          width: "100%",
        }} onClick={() => {
            if(slug?.value){
                handleClick(slug.value)
            }
            else{
                handleClick(slug)
            }
        }}
             onMouseEnter={() => setShowInfo(true)}
             onMouseLeave={() => setShowInfo(true)}>
            <div className="xl:h-60">
                <LazyImage src={image} alt="image"/>
            </div>
            <div className={contentDash ? " text-center flex items-center justify-between px-4" : " para-hid cardTitleSec text-center  py-2 px-2 flex items-center justify-between custom:px-6 custom:pt-6 md:pt-4 pb-2 md:px-4"}>
                <p className={contentDash ? "font-bold md:text-h6  text-center block w-3/5" : " font-bold md:text-h5  text-center block w-3/5	"}>{cardTitle}</p>
                <p className={`font-bold ${price.trim() !== "Purchased" ? 'md:text-h5 xl:text-h5 text-primary w-3/12' : 'text-primary text-xs'}	`} style={{flexShrink: 0}}>{price}</p>
            </div>
            {is_approved === false && <p className='para-hid cardTitleSec text-center px-2 flex items-center justify-between text-primary text-xs text-h6 font-bold custom:px-6 pb-8 md:px-4'>Pending review</p>}
            {/* {showInfo && (
                <div className='absolute z-10 top-14 md:top-20'>
                    {slug?.value ? <InfoBox
                        text={slug?.value?.short_description}/> : <InfoBox
                        text={short_description}/>}

                </div>)} */}
        </div>
    );
};
export default StudyMaterials;
